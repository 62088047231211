import { CardMedia, Link, Typography, colors, useTheme } from '@mui/material';
import { Box } from '../../Box';
import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { useEffect, useMemo, useState } from 'react';
import { getImage } from '../../../util/data-utils';
import Image from '../components/ImageWithFallback';

export const ItemSummaryTemplate = ({
  title,
  subTitle,
  information,
  onClick,
  tags,
  imageId,
}: {
  title: string;
  subTitle?: string;
  tags?: string[];
  information?: string;
  onClick?: () => void;
  imageId?: string;
}) => {
  const theme = useTheme();
  const [businessImage, setBusinessImage] = useState('');
  useEffect(() => {
    (async () => {
      setBusinessImage(await getImage(imageId));
    })();
  }, []);
  const MyImage = useMemo(
    () =>
      businessImage != '' ? (
        <Image src={businessImage} />
      ) : (
        <DocumentHeaderFooter24Filled />
      ),
    [businessImage]
  );
  return (
    <Box direction='column' gap='small' background='none'>
      <Box
        alignItems='center'
        direction='row'
        gap='small'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
      >
        {!!imageId && businessImage !== '' ? (
          MyImage
        ) : (
          <DocumentHeaderFooter24Filled />
        )}
        <Link
          sx={{
            textDecoration: 'none',
            cursor: 'pointer',
            '&>p': { color: `${theme.palette.info.dark} !important` },
            '&:hover': {
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              color: `${theme.palette.primary.main} !important`,
              '&>p': {
                cursor: 'pointer',
                color: `${theme.palette.primary.main} !important`,
                textDecoration: 'underline',
              },
              '&::after': {
                textDecoration: 'none',
                content: '" \\2192"',
              },
            },
          }}
          onClick={() => onClick && onClick()}
        >
          <Typography variant='body1' noWrap>
            {title}
          </Typography>
        </Link>
      </Box>
      <Box
        alignItems='center'
        direction='row'
        gap='small'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
      >
        <DocumentHeaderFooter24Filled opacity={0} />
        <Box gap='small' background='none' direction='row'>
          {!!tags &&
            tags.map((x, i) => (
              <Typography
                key={i}
                sx={{
                  paddingX: 1,
                  borderRadius: 1,
                  background: `${theme.palette.background.paper}`,
                }}
                variant='body1'
                fontSize={9}
              >
                {x}
              </Typography>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
