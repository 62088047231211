import WebViewer from '@pdftron/webviewer';
import { Divider, styled } from '@mui/material';
import {
  Box,
  TabPanel,
  ItemNavigationBar,
  ItemSummaryTitle,
  ItemSummaryPanel,
  ItemPropertiesPanel,
} from '@encompaas/common/components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import {
  useItemDetails,
  useIcon,
  useBusinessType,
} from '@encompaas/common/hooks';
import { useEffect, useState } from 'react';

const StyledImage = styled('img')``;

const ItemPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { itemDetails } = useItemDetails({ id: id ?? '' });

  const [queryParams, setQueryParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState('summary');

  const businessTypeImageId = itemDetails?.BusinessType?._ImageId;
  const typeDefImageId = itemDetails?.TypeDef?._ImageId;
  const itemImageId = itemDetails?._ImageId;

  const {
    businessTypeProperties,
    businessTypePropertyValues,
    businessTypeIconDetails,
  } = useBusinessType({ item: itemDetails });

  const { iconDetails } = useIcon({
    imageId: businessTypeImageId ?? typeDefImageId ?? itemImageId ?? '',
    skip: !businessTypeImageId && !typeDefImageId,
    fallbackObj: itemDetails,
  });

  useEffect(() => {
    const _selectedTab = queryParams.get('tab') ?? 'summary';
    if (_selectedTab !== selectedTab) {
      setSelectedTab(_selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const requestId = queryParams.get('requestId') ?? undefined;

  const handleSelectTab = (tab: string) => {
    setQueryParams({ tab, ...(requestId && { requestId }) });
  };

  const handleHome = () => navigate('/');

  const handleParentNavigation = () => {
    if (!requestId) return;
    navigate(`/request/${requestId}?tab=items`);
  };

  return (
    <PageWithAppBar
      data-testid={TEST_CONSTANTS.ITEM_PAGE}
      className='ecs-item-detail-page'
    >
      <Box background='light' rounded='top' blur style={{ overflow: 'auto' }}>
        <ItemNavigationBar
          onHome={handleHome}
          onParent={requestId ? handleParentNavigation : undefined}
        />
        <Divider style={{ width: '100%' }} />
        <Box
          background='none'
          padding='none'
          direction='row'
          gap='large'
          height={2.8}
          style={{ padding: '1rem 1.625rem 1.25rem' }}
        >
          <ItemSummaryTitle
            icon={
              iconDetails ? (
                <StyledImage
                  src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                  alt={iconDetails?.AltText}
                  style={{ height: '1.5rem' }}
                />
              ) : null
            }
            displayName={itemDetails?.DisplayName}
            businessType={itemDetails?.BusinessType?._Display}
            itemType={itemDetails?.TypeDef?._Display}
          />
        </Box>
        <TabPanel
          selected={selectedTab}
          onSelect={handleSelectTab}
          inset='3.5rem'
          tabs={[
            {
              title: 'Summary',
              value: 'summary',
              children: (
                <ItemSummaryPanel
                  item={itemDetails}
                  WebViewer={WebViewer} // TODO: update this implementation, this library should be imported inside common library.
                  businessTypeProperties={businessTypeProperties}
                  businessTypePropertyValues={businessTypePropertyValues}
                  businessTypeIconDetails={businessTypeIconDetails}
                />
              ),
            },
            {
              title: 'Properties',
              value: 'properties',
              children: <ItemPropertiesPanel />,
            },
          ]}
        />
      </Box>
    </PageWithAppBar>
  );
};

export default ItemPage;
