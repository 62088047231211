import { useGetRequestDetailsQuery } from '../services';

export const useDisposalRequest = ({ id }: { id?: string }) => {
  const { data, isSuccess } = useGetRequestDetailsQuery(
    {
      requestId: id!,
    },
    //don't call this unless the id is set
    { skip: !id }
  );

  return { data, isSuccess };
};
