import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../auth';
import {
  ApproversList,
  DisposalRequest,
  DisposalRequestCount,
  DisposalRequestList,
  DisposalRequestStatus,
} from '../types';

import { config } from '../config';

type RequestCountResponse = {
  $count: number;
  ['GroupBy_ApproverResponses.ApproverIdToStatus']: string;
  ['GroupBy_Status']: string;
};

const ENDPOINT = 'DisposalRequest';

export const requestApi = createApi({
  reducerPath: 'requestApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ['DisposalRequest'],
  endpoints: (builder) => ({
    getRequestsByApprovalStatus: builder.query<
      DisposalRequestList,
      { userId: string; status: string }
    >({
      query: ({ userId, status }) => {
        return {
          url: `${ENDPOINT}?$select=*&$expand=requestedby&$expand=ApproverResponses($select=*;$expand=Approver,DelegatedTo)&$filter=ApproverResponses/ApproverIdToStatus eq '${userId}_${status}'`,
          method: 'GET',
        };
      },
    }),
    getRequestsByStatus: builder.query<DisposalRequestList, { status: string }>(
      {
        query: ({ status }) => {
          return {
            url: `${ENDPOINT}?$select=*&$expand=requestedby&$expand=ApproverResponses($select=*;$expand=Approver,DelegatedTo)&$filter=status eq '${status}'`,
            method: 'GET',
          };
        },
      }
    ),
    getRequestCountsByApprovalStatus: builder.query<
      DisposalRequestCount[],
      { userId: string }
    >({
      query: ({ userId }) => {
        return {
          url: `${ENDPOINT}?$expand=ApproverResponses&$filter=ApproverResponses/ApproverId eq ${userId}&$apply=groupby((ApproverResponses/ApproverIdToStatus), aggregate($count as 'DRs'))`,
          method: 'GET',
        };
      },
      transformResponse: (
        {
          value: requestCountList,
        }: {
          value: RequestCountResponse[];
        },
        meta,
        args
      ) => {
        const counts: DisposalRequestCount[] = [];
        requestCountList?.forEach((count) => {
          const [_userId, _status] = count[
            'GroupBy_ApproverResponses.ApproverIdToStatus'
          ]?.split('_') ?? ['unkown', 'unknown'];
          if (_userId === args.userId) {
            counts.push({
              Count: count.$count,
              UserId: _userId,
              Status: _status,
            } as DisposalRequestCount);
          }
        });
        return counts;
      },
    }),
    getRequestCountsByStatus: builder.query<DisposalRequestCount[], {}>({
      query: () => {
        return {
          url: `${ENDPOINT}?$apply=groupby((Status), aggregate($count as 'DRs'))`,
          method: 'GET',
        };
      },
      transformResponse: (
        {
          value: requestCountList,
        }: {
          value: RequestCountResponse[];
        },
        meta,
        args
      ) => {
        const counts: DisposalRequestCount[] = [];
        requestCountList?.forEach((count) => {
          counts.push({
            Count: count.$count,
            Status: count['GroupBy_Status'],
          } as DisposalRequestCount);
        });
        return counts;
      },
    }),
    getRequestDetails: builder.query<DisposalRequest, { requestId: string }>({
      query: ({ requestId }) => {
        return {
          url: `${ENDPOINT}('${requestId}')/?$select=*&$expand=requestedby,ApproverResponses($select=*;$properties=FullDetails)&$expand=retentionClass($select=*)`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalRequest'],
    }),
    getApproversList: builder.query<ApproversList, void>({
      query: () => {
        return {
          // TODO: replace hardcoded params with query when it is ready
          url: `User?$count=true&$select=ID,_Display,Department&$filter=contains(Displayname, 'Andrew Kitchen') or contains(DisplayName, 'Chelsea Slack') or ((contains(DisplayName, 'Tiffany Pycroft') or contains(Mail, 'tiffany@encompaastest1')) and Mail ne 'tiffany4586@encompaastest1.onmicrosoft.com') or contains(DisplayName, 'Melvin Villegas') or (contains(DisplayName, 'Cindy') or contains(DisplayName, 'Ankeny Reiber'))`,
          method: 'GET',
        };
      },
    }),
    approveDisposalRequest: builder.mutation<
      DisposalRequest,
      { comment: string; requestId: string | undefined }
    >({
      query: ({ comment, requestId }) => ({
        url: `/${ENDPOINT}('${requestId}')/Approve?`,
        method: 'POST',
        body: { comments: comment },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
    updateDisposalRequest: builder.mutation<
      DisposalRequest,
      {
        comment: string;
        requestId?: string;
        // TODO: create type for api approversList when update the query
        approversList?: {
          Approver: { ID: string };
          Status: string;
          Order: number;
        }[];
        requestedBy?: string;
      }
    >({
      query: ({ comment, requestId, approversList, requestedBy }) => ({
        url: `/DisposalRequest('${requestId}')`,
        method: 'PATCH',
        body: {
          Status: DisposalRequestStatus.InApproval,
          RequestedBy: { ID: requestedBy },
          RequestComments: comment,
          ApproverResponses: {
            value: approversList,
          },
        },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
  }),
});

export const {
  useGetRequestsByApprovalStatusQuery,
  useGetRequestsByStatusQuery,
  useGetRequestCountsByApprovalStatusQuery,
  useGetRequestCountsByStatusQuery,
  useGetRequestDetailsQuery,
  useApproveDisposalRequestMutation,
  useGetApproversListQuery,
  useUpdateDisposalRequestMutation,
} = requestApi;
