import { RefObject, useEffect, useRef, useState } from 'react';
import { config } from '../config';
import { FileContent, Item } from '../types';
import { acquireToken } from '../util';

// https://docs.apryse.com/documentation/web/guides/file-format-support/
export const APRYSE_WEBVIEWER_SUPPORTED_FILES = [
  '.pdf',
  '.fdf',
  '.xfdf', // PDF FORMATS
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx', // MICROSOFT OFFICE FORMATS
  '.rtf',
  '.odt',
  '.ods',
  '.odp',
  '.wpf', // DOCUMENT FORMATS
  '.jpg',
  '.jfif',
  '.png',
  '.tif', // IMAGE FORMATS
  '.mp4',
  '.ogg',
  '.webm', // VIDEO FORMATS
  '.mp3',
  '.wab',
  '.flac', // AUDIO FORMATS
];

export type UseFileContentProps = {
  item: Item;
  viewer: RefObject<HTMLSelectElement>;
  WebViewer: any;
};

export const useFileDetails = ({
  item,
  viewer,
  WebViewer,
}: UseFileContentProps) => {
  const [fileDetails, setFileDetails] = useState<FileContent>();
  const [hasContent, setHasContent] = useState(true);

  const isWebViewerRenderedRef = useRef(false);

  const typeDefId = item?.TypeDefId;
  const itemId = item?.ID;

  //TODO can replace this now with RTKQuery call to use cacheing
  useEffect(() => {
    if (!typeDefId || !itemId || !WebViewer) return;

    (async () => {
      const token = await acquireToken();
      const API_URL: string = `${config.API_BASE_URL}/${typeDefId}('${itemId}')/GetContent?ds=5`;

      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      const data = await response.json();
      setFileDetails(data?.FriendlyMessage ? null : data);
    })();
  }, [typeDefId, itemId]);

  useEffect(() => {
    if (
      !viewer.current ||
      !fileDetails?.ContentUrl ||
      isWebViewerRenderedRef.current
    )
      return;
    isWebViewerRenderedRef.current = true;

    const { FileExtension, Url } = fileDetails.ContentUrl;

    if (!APRYSE_WEBVIEWER_SUPPORTED_FILES.includes(`.${FileExtension}`)) {
      setHasContent(false);
      return;
    }

    /** IMPORTANT: The embedded license key for Apryse technologies is the property of EncompaaS.  Use of this technology via EncompaaS does not imply any right to reverse engineer the key, use it for any other purpose or to distribute it.  
    /* These activities will constitute a violation of EncompaaS licensing and will result in prosecution and likely result in compensation being soiught for this license breach.  Do not copy or distribute this license key. */
    /* licenseKey: 'EncompaaS Software Ltd (encompaas.cloud):OEM:Encompaas::B+:AMS(20240509):D7A59CCD0497C60A0360B13AC982537860612F9DE740EDA8DD047B8A9D242E8E22CAB6F5C7', /** IMPORTANT 2: THIS KEY IS ENCOMPAAS PROPERTY, DO NOT COPY OR DISTRIBUTE. */
    const licenseKey =
      'EncompaaS Software Ltd (encompaas.cloud):OEM:Encompaas::B+:AMS(20240509):D7A59CCD0497C60A0360B13AC982537860612F9DE740EDA8DD047B8A9D242E8E22CAB6F5C7';

    WebViewer(
      {
        path: '/webviewer/lib',
        licenseKey,
        extension: FileExtension,
        enableAnnotations: false,
        disableLogs: true,
        disabledElements: [
          'printButton',
          'downloadButton',
          'saveAsButton',
          'toggleCompareModeButton',
        ],
        initialDoc: Url,
      },
      viewer.current
    ).then((instance) => {
      const Feature = instance.UI.Feature;
      instance.UI.disableFeatures([Feature.Copy, Feature.Print]);
    });
  }, [fileDetails, viewer]);

  return { fileDetails: hasContent ? fileDetails : null };
};
