import { Typography } from '@mui/material';
import { Grid, PageSettingsModel } from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';
import { Box } from '../../Box';
import { PageSelector, PageSizeDropdown } from '../components';

// Todo: check why totalPages does not appear in PageSettingsModel
export type PagerData = PageSettingsModel & {
  totalPages: number;
};

export const PagerTemplate = ({
  pagerData,
  grid,
  pageSizes,
}: {
  pagerData: PagerData;
  grid: Grid;
  pageSizes: number[];
}) => {
  if (pagerData.totalRecordsCount == 0) return <></>;

  const [pageSettings, setPageSettings] = useState({
    current: 1,
    total: 1,
    size: 30,
    start: 1,
    end: 1,
  });

  useEffect(() => {
    const _current = pagerData.currentPage ?? 1;
    const _total = pagerData.totalPages ?? 1;
    const _size = pagerData.pageSize ?? 30;
    setPageSettings({
      current: _current,
      total: _total,
      size: _size,
      start: _current * _size - _size + 1,
      end:
        _current === _total
          ? pagerData.totalRecordsCount ?? 0
          : _current * _size,
    });
  }, [pagerData]);

  const moveToPage = (page: number) => {
    grid.pageSettings.currentPage = page;
    setPageSettings({
      ...pageSettings,
      current: page,
    });
    grid.refresh();
  };

  const changePageSize = (size: number) => {
    grid.pageSettings.pageSize = size;
  };

  return (
    <Box
      background='none'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      padding='large'
      height={3}
      style={{ flex: 1 }}
    >
      <Typography variant='body1'>
        Showing {pageSettings.start} - {pageSettings.end} of{' '}
        {pagerData.totalRecordsCount} items
      </Typography>
      <PageSelector
        currentPage={pageSettings.current}
        totalPages={pageSettings.total}
        onSelect={moveToPage}
      />
      <PageSizeDropdown
        options={pageSizes}
        onChange={changePageSize}
        pageSize={grid.pageSettings.pageSize}
      />
    </Box>
  );
};
