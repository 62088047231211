import { Person20Regular } from '@fluentui/react-icons';
import { Button as MuiButton, Typography, useTheme } from '@mui/material';
import { User } from '../types';

export type UserChipProps = {
  user: User;
  border?: boolean;
  onClick?: (user: User) => void;
};

export const UserChip = ({ user, border = false, onClick }: UserChipProps) => {
  const theme = useTheme();
  return (
    <MuiButton
      disabled={!onClick}
      startIcon={<Person20Regular data-testid='user-icon-svg' />}
      variant='outlined'
      sx={{
        background: 'none',
        color: theme.palette.info.main,
        padding: border ? '0 0.75rem' : 0,
        height: border ? '2rem' : '1rem',
        fontSize: '0.875rem !important',
        borderColor: border ? theme.palette.info.light : 'transparent',
        '&.Mui-disabled': {
          color: theme.palette.info.main,
          borderColor: border ? theme.palette.info.light : 'transparent',
        },
        '&>span.MuiButton-startIcon': {
          marginRight: '0.375rem',
        },
        '&:hover': {
          borderColor: border ? theme.palette.info.main : 'transparent',
        },
        borderRadius: '0.25rem !important',
      }}
      size='small'
      onClick={() => onClick && onClick(user)}
      data-testid='user-chip'
    >
      <Typography variant='body2' sx={{ color: theme.palette.info.main }}>
        {user.name}
      </Typography>
    </MuiButton>
  );
};
