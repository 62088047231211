import { TabContext as MUITabContext, TabPanel as MUITabPanel } from '@mui/lab';
import { Divider, Tab as MUITab, Tabs as MUITabs } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Box } from './Box';

export type TabPanelTabProps = {
  name: string;
  title?: string;
  children?: ReactNode;
};

export type TabPanelProps = {
  selected?: string;
  tabs?: { value: string; title?: string; children?: ReactNode }[];
  inset?: string | number;
  onSelect?: (tab: string) => void;
  height?: string;
};

export const TabPanel = ({
  selected,
  tabs,
  inset = 0,
  onSelect,
  height,
}: TabPanelProps) => {
  const [_selected, setSelected] = useState<string | undefined>(
    tabs?.[0].value
  );

  //selected can be controlled by parent
  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect && onSelect(value);
  };

  return (
    <Box background='none'>
      <Box
        background='none'
        height={2}
        justifyContent='end'
        style={{ paddingLeft: inset }}
      >
        <MUITabs value={_selected} onChange={(e, value) => handleSelect(value)}>
          {tabs?.map((tab, index) => (
            <MUITab
              key={index}
              label={tab.title ?? tab.value}
              value={tab.value}
            />
          ))}
        </MUITabs>
      </Box>
      <Divider style={{ width: '100%' }} />
      <Box background='light' height={height}>
        <MUITabContext value={_selected || ''}>
          {tabs?.map((tab, index) => (
            <MUITabPanel
              key={index}
              value={tab.value}
              sx={{
                padding: 0,
                display: 'flex',
                overflow: 'hidden',
              }}
            >
              {tab.children}
            </MUITabPanel>
          ))}
        </MUITabContext>
      </Box>
    </Box>
  );
};
