import {
  ArrowSync20Filled,
  Checkmark24Filled,
  Dismiss24Filled,
  Warning24Filled,
} from '@fluentui/react-icons';
import { Typography, useTheme } from '@mui/material';
import { ProgressIndicatorArrow40 } from '../icons';
import { palette } from '../themes';
import { Box } from './Box';

export enum DisposalRequestProgressStatus {
  BLOCKED = 'Blocked',
  READY = 'Ready',
  IN_PROGRESS = 'In Progress',
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  REJECTED = 'Rejected',
}

export type DisposalRequestProgressIndicatorStatusProps = {
  progressStatus: DisposalRequestProgressStatus;
  sequenceNumber?: string;
  label?: string;
  withWarning?: boolean;
  isBlocked?: boolean;
  isLast?: boolean;
};

const getStepIndicator = (
  status,
  withWarning,
  sequenceNumber,
  isBlocked,
  theme
) => {
  switch (status) {
    case DisposalRequestProgressStatus.PENDING:
      return {
        display: sequenceNumber,
        backgroundColor: theme.palette.info[600],
        progressIndicatorArrowColor: theme.palette.info.light,
      };

    case DisposalRequestProgressStatus.READY:
      return {
        display: sequenceNumber,
        backgroundColor: withWarning
          ? theme.palette.warning[400]
          : theme.palette.primary[700],
        progressIndicatorArrowColor: theme.palette.info[600],
      };

    case DisposalRequestProgressStatus.IN_PROGRESS:
      return {
        display: <ArrowSync20Filled />,
        backgroundColor: isBlocked
          ? theme.palette.warning[900]
          : theme.palette.primary[700],
        progressIndicatorArrowColor: theme.palette.info.light,
      };

    case DisposalRequestProgressStatus.COMPLETE:
      return {
        display: <Checkmark24Filled />,
        backgroundColor: withWarning
          ? theme.palette.warning[400]
          : theme.palette.primary[700],
        progressIndicatorArrowColor: theme.palette.info[600],
      };

    case DisposalRequestProgressStatus.REJECTED:
      return {
        display: <Dismiss24Filled />,
        backgroundColor: theme.palette.warning[900],
        progressIndicatorArrowColor: theme.palette.info.light,
      };

    case DisposalRequestProgressStatus.BLOCKED:
      return {
        display: <Warning24Filled />,
        backgroundColor: theme.palette.warning[900],
        progressIndicatorArrowColor: theme.palette.info.light,
      };
    default:
      return null;
  }
};

export const DisposalRequestProgressIndicatorStatus = ({
  progressStatus,
  sequenceNumber,
  label,
  withWarning,
  isBlocked,
  isLast = false,
  ...rest
}: DisposalRequestProgressIndicatorStatusProps) => {
  const theme = useTheme();

  const { display, backgroundColor, progressIndicatorArrowColor } =
    getStepIndicator(
      progressStatus,
      withWarning,
      sequenceNumber,
      isBlocked,
      theme
    );

  return (
    <Box
      direction='row'
      height='3rem'
      alignItems='center'
      gap='medium'
      background='none'
      className='progress-indicator-status'
    >
      <Box
        style={{
          backgroundColor,
          color: palette.purewhite,
          borderRadius: 60,
        }}
        width='3rem'
        height='3rem'
        justifyContent='center'
        alignItems='center'
        {...rest}
      >
        {display}
      </Box>
      {label && (
        <Typography
          variant='body2'
          sx={{ color: theme.palette.info.dark }}
          width={isLast ? '4rem' : '3.375rem'}
        >
          {label}
        </Typography>
      )}
      {!isLast && (
        <ProgressIndicatorArrow40
          style={{ color: progressIndicatorArrowColor }}
        />
      )}
    </Box>
  );
};
