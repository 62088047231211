import { Typography } from '@mui/material';
import { Box } from '../../Box';
import { RetentionClass24 } from '../../../icons/RetentionClass24';
import { ItemClassificationsValue } from '../../../types';

export const RetentionClassTemplate = ({
  title,
  information,
  tags,
}: {
  title: string;
  tags?: ItemClassificationsValue[];
  information?: string;
}) => {
  return (
    <div style={{ height: '4.5rem', overflowY: 'scroll' }}>
      {(tags ?? []).map((x, i) => {
        return (
          <Box
            alignItems='center'
            direction='row'
            gap='small'
            background='none'
            key={i}
          >
            <RetentionClass24
              style={{
                minWidth: '2rem',
                alignSelf: 'flex-start',
                marginTop: '2px',
              }}
              color={!!x.RootParentColor ? x.RootParentColor : undefined}
            />
            <Box background='none' style={{ textOverflow: 'ellipsis' }}>
              <Typography noWrap variant='body1'>
                {x.FullPath}
              </Typography>
              <Typography
                variant='body1'
                fontSize={12}
                sx={{ lineHeight: 1.2 }}
              >
                {x.DisplayName}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </div>
  );
};
