import Logo from '../images/EncompaasLogo';

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  backgroundImage?: boolean;
  background?: 'default' | 'light' | 'dark' | 'alt' | 'none';
  padding?: 'none' | 'small' | 'medium' | 'large';
}

//create super component for Box with background image
export const Page = ({
  children,
  backgroundImage = false,
  background = 'default',
  padding = 'none',
  className,
  ...props
}: PageProps) => {
  let _className = `ecs-page ecs-background-${background} ecs-padding-${padding} `;

  //can apply custom className
  _className = _className + (className ?? '');

  return (
    <div {...props} className={_className} style={props.style}>
      {children}
      {backgroundImage && <Logo className='ecs-page-background-image' />}
    </div>
  );
};
