import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../auth';

import {
  BusinessTypeProperties,
  BusinessTypePropertyValues,
  Item,
  ItemIcon,
} from '../types';
import { config } from '../config';

const ENDPOINT = 'Item';

export const itemApi = createApi({
  reducerPath: 'itemApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getItemDetailsFullDetails: builder.query<Item, { itemId: string }>({
      query: ({ itemId }) => {
        return {
          url: `${ENDPOINT}('${itemId}')?$properties=FullDetails`,
          method: 'GET',
        };
      },
    }),
    getItemDetailsMemberOf: builder.query<Item, { itemId: string }>({
      query: ({ itemId }) => {
        return {
          url: `${ENDPOINT}('${itemId}')/?$expand=MemberOf($select=ID)`,
          method: 'GET',
        };
      },
    }),
    getBusinessTypeProperties: builder.query<
      BusinessTypeProperties,
      { businessTypeId: string }
    >({
      query: ({ businessTypeId }) => {
        return {
          url: `${businessTypeId}/GetEditablePropDefs?$properties=FullDetails`,
          method: 'POST',
        };
      },
    }),
    getBusinessTypePropertyValues: builder.query<
      BusinessTypePropertyValues,
      { businessTypeId: string; businessObjectId: string }
    >({
      query: ({ businessTypeId, businessObjectId }) => {
        return {
          url: `${businessTypeId}('${businessObjectId}')?$properties=FullDetails`,
          method: 'GET',
        };
      },
    }),
    getIcon: builder.query<ItemIcon, { imageId: string }>({
      query: ({ imageId }) => {
        return {
          url: `Enc.Image?$filter=ID eq '${imageId}'`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetItemDetailsFullDetailsQuery,
  useGetItemDetailsMemberOfQuery,
  useGetBusinessTypePropertiesQuery,
  useGetBusinessTypePropertyValuesQuery,
  useGetIconQuery,
} = itemApi;
