import { CardMedia, Typography } from '@mui/material';
import { Box } from '../../Box';
import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { useEffect, useMemo, useState } from 'react';
import { getImage } from '../../../util/data-utils';
import { RetentionClass24 } from '../../../icons/RetentionClass24';
import { Clock24Regular } from '@fluentui/react-icons';

export const DisposalStatusTemplate = ({ tags }: { tags?: any[] }) => {
  //TODO: Commented code is pending business decision to determine whether SVG icon styles will be updated post loading
  const [businessImage, _] = useState('');
  const Image = useMemo(
    () =>
      businessImage != '' ? (
        <CardMedia
          sx={{ height: '1.5rem', width: '1.5rem' }}
          image={businessImage}
        />
      ) : (
        <DocumentHeaderFooter24Filled />
      ),
    [businessImage]
  );

  return (
    <div style={{ height: '4.5rem', overflowY: 'scroll' }}>
      {(tags ?? []).map((x, i) => {
        return (
          <Box
            alignItems='center'
            direction='row'
            gap='small'
            background='none'
            key={i}
          >
            {Image}
            <Box background='none' style={{ textOverflow: 'ellipsis' }}>
              <Typography noWrap variant='body1'>
                {x.DisposalStatus}
              </Typography>
              <Typography
                variant='body1'
                fontSize={12}
                sx={{ lineHeight: 1.2 }}
              >
                {x._Display}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </div>
  );
};
