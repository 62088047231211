import { Divider } from '@mui/material';
import { Box } from './Box';
import { HomeButton, ParentNavigationButton } from './Button';

export type ItemNavigationBarProps = {
  parentName?: string;
  onHome?: () => void;
  onParent?: () => void;
};

export const ItemNavigationBar = ({
  parentName = 'Disposal Request',
  onHome,
  onParent,
  ...rest
}: ItemNavigationBarProps) => {
  return (
    <Box
      background='dark'
      padding='none'
      rounded='top'
      height={3.25}
      blur
      width='100%'
    >
      <Box background='none' direction='row' alignItems='center' {...rest}>
        <HomeButton
          label=''
          onClick={onHome && onHome}
          style={{
            marginLeft: '1.688rem',
            marginRight: '0.438rem',
            alignSelf: 'start',
            color: '#FFFFFF',
            height: '100%',
            padding: 0,
            minWidth: 0,
          }}
          data-testid='item-nav-bar-home-btn'
        />
        <Divider
          orientation='vertical'
          style={{ borderColor: '#4D556B', height: '1.25rem' }}
        />
        {onParent && (
          <ParentNavigationButton
            onClick={onParent}
            style={{
              marginLeft: '1.063rem',
              color: '#FFFFFF',
              height: '100%',
              padding: 0,
              minWidth: 0,
              fontWeight: 400,
              fontFamily: 'Avenir Book',
            }}
            label={parentName}
            data-testid='item-nav-bar-parent-btn'
          />
        )}
      </Box>
    </Box>
  );
};
