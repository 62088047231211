import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Button } from '../Button';

export type InfoDialogProps = {
  title?: string;
  open?: boolean;
  information?: string;
  onClose?: () => void;
  size?: 'small' | 'large';
};

export const InfoDialog = ({
  title,
  open,
  information,
  onClose,
  size = 'small',
}: InfoDialogProps) => {
  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: size === 'small' ? '16rem' : '50rem',
          maxWidth: size === 'small' ? '42rem' : '62rem',
          minHeight: size === 'small' ? '4rem' : '6rem',
          maxHeight: size === 'small' ? '32.5rem' : '42.5rem',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{information}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InfoDialog;
