import { Divider, styled, Typography } from '@mui/material';
import { getFormattedDateTime } from '../../util';
import {
  BusinessTypePropDefsValue,
  BusinessTypeProperties,
  BusinessTypePropertyValues,
  Item,
  ItemIconValue,
} from '../../types';
import { InfoPanel } from '../InfoPanel';
import { DetailsRow } from '../DetailsRow';
import { RequestStatusChip } from '../RequestStatusChip';
import { PerspectiveClassChip } from '../PerspectiveClassChip';

export type ItemBusinessTypePanelProps = {
  item: Item;
  businessTypeProperties?: BusinessTypeProperties;
  businessTypePropertyValues?: BusinessTypePropertyValues;
  businessTypeIconDetails?: ItemIconValue;
};

const StyledImage = styled('img')``;

export const ItemBusinessTypePanel = ({
  item,
  businessTypeProperties,
  businessTypePropertyValues,
  businessTypeIconDetails,
}: ItemBusinessTypePanelProps) => {
  const getContentData = (propertyValue, dataType) => {
    if (dataType === 'Date') return getFormattedDateTime(propertyValue ?? '');
    else if (dataType === 'String') return propertyValue;
    else if (
      propertyValue?.hasOwnProperty('_Display') &&
      propertyValue?.hasOwnProperty('FullPath') &&
      propertyValue?.hasOwnProperty('_ImageId')
    )
      return <PerspectiveClassChip businessClass={propertyValue} />;
    else if (propertyValue?.hasOwnProperty('_Display'))
      return propertyValue._Display;
    else if (propertyValue?.value)
      return propertyValue?.value?.map((val, index) => (
        <Typography variant='body2' key={`${val._Display}-${index}`}>
          {val._Display}
        </Typography>
      ));
    else return null;
  };

  return (
    <>
      {item?.BusinessType ? (
        <>
          <InfoPanel
            title={`${item?.BusinessType?._Display} Properties`}
            inset='3.5rem'
            style={{ paddingRight: '5.25rem', paddingTop: '0.988rem' }}
          >
            <DetailsRow
              label='Business Type'
              data={
                <RequestStatusChip
                  style={{ minHeight: '3.125rem', textAlign: 'left' }}
                  title={item?.BusinessType?._Display}
                  icon={
                    businessTypeIconDetails ? (
                      <StyledImage
                        src={`${process.env.REACT_APP_ENC_IMC_URL}${businessTypeIconDetails?.Url}`}
                        alt={businessTypeIconDetails?.AltText}
                        style={{
                          height: '1.5rem',
                          width: '1.5rem',
                        }}
                      />
                    ) : null
                  }
                  subTitle={item?.BusinessType?.DerivedPath}
                />
              }
              key='business-type'
            />
            {businessTypeProperties?.PropDefs?.value?.map(
              (businessTypeProperty: BusinessTypePropDefsValue) => {
                const dataType = businessTypeProperty.PrimitiveDataType;
                const propertyValue =
                  businessTypePropertyValues?.[businessTypeProperty.Name];

                return (
                  <DetailsRow
                    label={businessTypeProperty._Display}
                    data={getContentData(propertyValue, dataType)}
                    key={businessTypeProperty.Name}
                  />
                );
              }
            )}
          </InfoPanel>
          <Divider
            style={{
              width: '88%',
              borderColor: '#DCE1EF',
              margin: '0 auto',
            }}
          />
        </>
      ) : null}
    </>
  );
};
