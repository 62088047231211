import { Link, Typography, useTheme } from '@mui/material';
import { Box } from '../../Box';

export const SummaryTemplate = ({
  title,
  subTitle,
  information,
  onClick,
  onContextMenu,
}: {
  title: string;
  subTitle?: string;
  information?: string;
  onClick?: () => void;
  onContextMenu?: (e: MouseEvent) => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      className='e-grid-column-template'
      alignItems='start'
      direction='column'
      gap='none'
      background='none'
      onContextMenu={(e) => e.preventDefault()}
    >
      <Link
        className='e-grid-summary-link'
        onClick={() => onClick && onClick()}
        onContextMenu={(e) => {
          onContextMenu && onContextMenu(e);
        }}
      >
        <span>{title}</span>
      </Link>
      <Typography
        variant='body2'
        sx={{ marginTop: '0.25rem', color: theme.palette.info.main }}
      >
        {information}
      </Typography>
      <Typography
        variant='body2'
        sx={{ marginTop: '0.75rem', color: theme.palette.info.main }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};
