import {
  Item,
  ItemBusinessClassesValue,
  ItemClassificationsValue,
} from '../../types';
import { getFormattedDateTime } from '../../util';
import { Box } from '../Box';
import { DetailsRow } from '../DetailsRow';
import { InfoPanel } from '../InfoPanel';
import { PerspectiveClassChip } from '../PerspectiveClassChip';
import { RequestStatusChip } from '../RequestStatusChip';
import { RetentionClassChip } from '../RetentionClassChip';

export type ItemGovernancePanelProps = {
  item: Item;
};

export const ItemGovernancePanel = ({ item }: ItemGovernancePanelProps) => {
  return (
    <>
      {Object.keys(item).length ? (
        <InfoPanel
          title='Governance Properties'
          inset='3.5rem'
          style={{
            paddingRight: '5.25rem',
            paddingTop: '0.988rem',
            paddingBottom: '3.25rem',
          }}
        >
          <DetailsRow
            label='Status'
            data={
              <RequestStatusChip
                style={{ minHeight: '2.5rem', textAlign: 'left' }}
                title={item?.Status}
                variant={item?.Status}
              />
            }
            key='governance-status'
          />
          <DetailsRow
            label='Date Created in EncompaaS'
            data={getFormattedDateTime(item?.DateCreated)}
            key='governance-date-created-in-encompaas'
          />
          <DetailsRow
            label='Perspective Classes'
            data={
              <Box
                direction='row'
                background='none'
                gap='small'
                style={{ flexWrap: 'wrap' }}
              >
                {item?.BusinessClasses?.value?.map(
                  (businessClass: ItemBusinessClassesValue, index: number) => (
                    <PerspectiveClassChip
                      businessClass={businessClass}
                      key={`${businessClass?._Display}-${index}`}
                    />
                  )
                )}
              </Box>
            }
            key='governance-perspective-classes'
          />
          <DetailsRow
            label='Retention Classes'
            key='governance-rentention-classes'
            data={
              <Box
                direction='row'
                background='none'
                gap='small'
                style={{ flexWrap: 'wrap' }}
              >
                {item?.Classifications?.value?.map(
                  (classification: ItemClassificationsValue, index: number) => (
                    <RetentionClassChip
                      classification={classification}
                      key={`${classification?.DisplayName}-${index}`}
                    />
                  )
                )}
              </Box>
            }
          />
        </InfoPanel>
      ) : null}
    </>
  );
};
