import {
  BusinessTypeProperties,
  BusinessTypePropertyValues,
  Item,
  ItemIconValue,
} from '../types';
import { Box } from './Box';
import { FileViewer } from './FileViewer';
import {
  ItemBusinessTypePanel,
  ItemTypePanel,
  ItemGovernancePanel,
} from './item';

export type ItemSummaryPanelProps = {
  item: Item;
  businessTypeProperties?: BusinessTypeProperties;
  businessTypePropertyValues?: BusinessTypePropertyValues;
  businessTypeIconDetails?: ItemIconValue;
  WebViewer?: any; //TODO: fix any type declaration here
};

export const ItemSummaryPanel = ({
  item,
  businessTypeProperties,
  businessTypePropertyValues,
  businessTypeIconDetails,
  WebViewer,
}: ItemSummaryPanelProps) => {
  return (
    <Box padding='none' background='none' direction='row' gap='none'>
      <Box background='none' width='50%' style={{ overflow: 'overlay' }}>
        <ItemBusinessTypePanel
          item={item}
          businessTypeProperties={businessTypeProperties}
          businessTypePropertyValues={businessTypePropertyValues}
          businessTypeIconDetails={businessTypeIconDetails}
        />
        <ItemTypePanel item={item} />
        <ItemGovernancePanel item={item} />
      </Box>
      <Box
        width='50%'
        height='100%'
        alignItems='center'
        justifyContent='center'
        style={{ backgroundColor: '#F4F6FB' }}
      >
        {Object.keys(item).length ? (
          <FileViewer itemDetails={item} WebViewer={WebViewer} />
        ) : null}
      </Box>
    </Box>
  );
};
