import { Alert } from './Alert';

type DisposalRequestAlertProps = {
  open: boolean;
  message?: string;
  onClose: () => void;
};

export const DisposalRequestSuccessAlert = ({
  open = false,
  onClose,
}: DisposalRequestAlertProps) => {
  return (
    <Alert
      alertType='success'
      title='Disposal Request Approved'
      message='Your response was successfully submitted.'
      open={open}
      onClose={onClose}
      autoHideDuration={10000}
    />
  );
};

export const DisposalRequestWarningAlert = ({
  open = false,
  message = 'Your response submission failed. Please try again, or contact your Administrator.',
  onClose,
}: DisposalRequestAlertProps) => {
  return (
    <Alert
      alertType='warning'
      title='Response submission failure'
      message={message}
      open={open}
      onClose={onClose}
    />
  );
};
