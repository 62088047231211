import { CardMedia, Typography } from '@mui/material';
import { Box } from '../../Box';
import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { useEffect, useMemo, useState } from 'react';
import { getImage } from '../../../util/data-utils';
import Image from '../components/ImageWithFallback';

export const BusinessTypeTemplate = ({
  title,
  information,
  tags,
  imageId,
}: {
  title: string;
  tags?: string[];
  information?: string;
  imageId?: string;
}) => {
  const [businessImage, setBusinessImage] = useState('');
  useEffect(() => {
    (async () => {
      setBusinessImage(await getImage(imageId));
    })();
  }, []);
  const MyImage = useMemo(
    () => (businessImage != '' ? <Image src={businessImage} /> : <></>),
    [businessImage]
  );
  return (
    <Box direction='column' gap='small' background='none'>
      <Box
        alignItems='center'
        direction='row'
        gap='small'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
      >
        <div style={{ height: '1.5rem', width: '1.5rem' }}>
          {!!imageId && businessImage !== '' ? (
            MyImage
          ) : (
            <DocumentHeaderFooter24Filled />
          )}
        </div>
        <Typography variant='body1' noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
