import { publicClientApplication } from './AuthProvider';
import { loginRequest } from './config';

export async function prepareHeaders(headers: Headers) {
  const accounts = publicClientApplication.getAllAccounts();

  publicClientApplication.setActiveAccount(accounts[0]);

  const silentRequest = {
    ...loginRequest,
    account: accounts[0],
  };

  const authResult = await publicClientApplication.acquireTokenSilent(
    silentRequest
  );

  if (authResult.accessToken) {
    headers.set('Authorization', `Bearer ${authResult.accessToken}`);
  }

  return headers;
}
