import { styled, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { useIcon, useFileDetails } from '../hooks';
import { Item } from '../types';
import { Box } from './Box';
import { ItemEmptyState } from './item';

const StyledImage = styled('img')``;

export const FileViewer = ({
  itemDetails,
  WebViewer,
}: {
  itemDetails: Item;
  WebViewer: any; //TODO: fix any type declaration here
}) => {
  const theme = useTheme();

  const viewer = useRef(null);

  const typeDefImageId = itemDetails?.TypeDef?._ImageId;
  const itemImageId = itemDetails?._ImageId;

  const { iconDetails } = useIcon({
    imageId: itemImageId ?? typeDefImageId,
  });

  const { fileDetails } = useFileDetails({
    item: itemDetails,
    viewer,
    WebViewer,
  });

  return (
    <>
      {fileDetails ? (
        <>
          <Box
            width='100%'
            direction='row'
            alignItems='center'
            background='light'
            style={{
              padding: '0.875rem 1.813rem',
              marginLeft: '1.813rem',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {iconDetails ? (
              <StyledImage
                src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                alt={iconDetails?.AltText}
                style={{ height: '1.5rem' }}
              />
            ) : null}
            <Typography variant='h5' paddingLeft='1.063rem'>
              {itemDetails._Display}
            </Typography>
          </Box>
          <div ref={viewer} style={{ height: '100%', width: '100%' }}></div>
        </>
      ) : (
        <ItemEmptyState repositoryUrl={itemDetails.RepositoryUrl} />
      )}
    </>
  );
};
