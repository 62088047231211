import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import applicationReducer from './slices/applicationSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import { requestApi } from '../services/requestApi';
import { userApi } from '../services/userApi';
import { itemApi } from '../services/itemApi';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['application'],
};

const rootReducer = combineReducers({
  application: applicationReducer,
  [requestApi.reducerPath]: requestApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [itemApi.reducerPath]: itemApi.reducer,
});

//convenience for testing
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(userApi.middleware)
        .concat(requestApi.middleware)
        .concat(itemApi.middleware),
  });
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// global store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(userApi.middleware)
      .concat(requestApi.middleware)
      .concat(itemApi.middleware),
});

export const persistor = persistStore(store);

// types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
