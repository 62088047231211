import { Typography, useTheme } from '@mui/material';
import { Box } from '../../Box';

export const CommentTemplate = ({
  comment,
  onClick,
  isLink = false,
}: {
  isLink?: boolean;
  comment?: string | null;
  onClick?: (comment: string) => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      height={5}
      background='none'
      data-testid='comment-box'
      className='e-grid-column-template'
      onClick={() => {
        if (comment?.trim().length) onClick && onClick(comment);
      }}
    >
      <Typography
        sx={
          isLink && {
            color: `${theme.palette.primary.main} !important`,
            '&>span': {
              cursor: 'pointer',
              color: `${theme.palette.primary.main} !important`,
              textDecoration: 'underline',
            },
          }
        }
        variant='body1'
        className='e-grid-comment-summary'
      >
        {comment}
      </Typography>
    </Box>
  );
};
