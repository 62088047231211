import { Item } from '../types';
import {
  useGetBusinessTypePropertiesQuery,
  useGetBusinessTypePropertyValuesQuery,
} from '../services/itemApi';
import { useIcon } from './useIcon';

type useBusinessTypeProps = {
  item: Item;
};

export const useBusinessType = ({ item }: useBusinessTypeProps) => {
  const businessTypeId = item?.BusinessTypeId;
  const businessObjectId = item?.BusinessObjectId;
  const businessTypeImageId = item?.BusinessType?._ImageId;

  //   Get Business Type Properties
  const { data: businessTypeProperties } = useGetBusinessTypePropertiesQuery(
    {
      businessTypeId,
    },
    { skip: !businessTypeId }
  );

  //   Get Business Type Property Values
  const { data: businessTypePropertyValues } =
    useGetBusinessTypePropertyValuesQuery(
      {
        businessTypeId,
        businessObjectId,
      },
      { skip: !businessTypeId || !businessObjectId }
    );

  //   Get Business Type Icon
  const { iconDetails } = useIcon({
    imageId: businessTypeImageId,
    skip: !businessTypeImageId,
  });

  return {
    businessTypeProperties,
    businessTypePropertyValues,
    businessTypeIconDetails: iconDetails,
  };
};
