import { Link, Typography, useTheme } from '@mui/material';
import { Item, User } from '../../../types';
import { getFormattedDateTime } from '../../../util';
import { DetailsRow } from '../../DetailsRow';
import { UserChip } from '../../UserChip';

export type ItemTypeTaskPanelProps = {
  item: Item;
};

export const ItemTypeTaskPanel = ({ item }: ItemTypeTaskPanelProps) => {
  const itemType = item?.TypeDef?._Display;

  const theme = useTheme();

  return (
    <>
      <DetailsRow
        label='Name'
        data={item?.DisplayName}
        key={`${itemType}-name`}
      />
      <DetailsRow label='Body' data='Body Data' key={`${itemType}-body`} />
      <DetailsRow
        label='Task Status'
        data='Task Status Data'
        key={`${itemType}-task-status`}
      />
      <DetailsRow
        label='Task Category'
        data='Task Category Data'
        key={`${itemType}-task-category`}
      />
      <DetailsRow
        label='Priority'
        data='Priority Data'
        key={`${itemType}-priority`}
      />
      <DetailsRow
        label='Assigned To'
        data='Assigned To Data'
        key={`${itemType}-assigned-to`}
      />
      <DetailsRow
        label='Is Current'
        data='Is Current Data'
        key={`${itemType}-is-current`}
      />
      <DetailsRow
        label='Start Date'
        data='Start Date Data'
        key={`${itemType}-start-date`}
      />
      <DetailsRow
        label='Percentage Complete'
        data='Percentage Complete Data'
        key={`${itemType}-percentage-complete`}
      />
      <DetailsRow
        label='Checkmark'
        data='Checkmark Data'
        key={`${itemType}-checkmark`}
      />
      <DetailsRow
        label='Created By'
        data={
          item?.CreatedBy?.DisplayName ? (
            <UserChip user={{ name: item?.CreatedBy?.DisplayName } as User} />
          ) : null
        }
        key={`${itemType}-created-by`}
      />
      <DetailsRow
        label='Date Created'
        data={getFormattedDateTime(item?.DateCreated)}
        key={`${itemType}-date-created`}
      />
      <DetailsRow
        label='Date Modified'
        data={getFormattedDateTime(item?.DateModified)}
        key={`${itemType}-date-modified`}
      />
      {/* https://encompaas.visualstudio.com/EncompaaS/_workitems/edit/54146 */}
      {/* <DetailsRow
        label='Description'
        data={<Typography variant='body1'>{item?.Description}</Typography>}
        key={`${itemType}-description`}
      /> */}
      <DetailsRow
        label='Repository URL'
        data={
          <Link
            href={item?.RepositoryUrl}
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                maxWidth: '29.313rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item?.RepositoryUrl}
            </Typography>
          </Link>
        }
        key={`${itemType}-repository-url`}
      />
    </>
  );
};
