import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';

import { Button } from '../';

export type CustomActionModalProps = {
  title?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  actionButton?: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
};

export const CustomActionModal = ({
  title,
  open,
  icon,
  children,
  actionButton,
  onClose,
}: CustomActionModalProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: '37.125rem',
          maxWidth: '45rem',
          minHeight: '40rem',
          maxHeight: '60rem',
          borderRadius: '0.75rem!important',
        },
      }}
    >
      <DialogTitle
        style={{
          background: theme.palette.background.paper,
          padding: '1.5rem',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingRight: '3rem',
        }}
      >
        {!!icon && icon}
        {title}
      </DialogTitle>
      <DialogContent style={{ background: theme.palette.primary.contrastText }}>
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '1.375rem 2rem!important',
          background: theme.palette.primary.contrastText,
        }}
      >
        {actionButton}
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CustomActionModal;
