import {
  CheckmarkSquare24Regular,
  DismissSquare24Regular,
  MailInbox24Regular,
  PeopleCommunity24Regular,
} from '@fluentui/react-icons';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  DRGrid,
  SideMenu,
  SideMenuItem,
} from '@encompaas/common/components';
import {
  DisposalRequest,
  DisposalRequestCount,
  DisposalRequestResponseStatus,
  DisposalRequestResponseStatusCaption,
} from '@encompaas/common/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser } from '@encompaas/common/hooks';
import { useGetRequestCountsByApprovalStatusQuery } from '@encompaas/common/services';
import {
  setSelectedSideMenuItemValue,
  setSideMenuCollapsed,
} from '@encompaas/common/store';
import { useAppDispatch, useAppSelector } from '@encompaas/common/store';

const HomePage = () => {
  const user = useUser();

  const navigate = useNavigate();

  const [key, setKey] = useState(Math.random().toString(36).substring(2, 7));

  const dispatch = useAppDispatch();

  const { data: counts, isLoading } = useGetRequestCountsByApprovalStatusQuery(
    { userId: user?.ID ?? '' },
    { skip: !user?.ID }
  );

  const { sideMenuCollapsed, selectedSideMenuItemValue } = useAppSelector(
    (state) => state.application?.ui ?? {}
  );

  const _menuItems: SideMenuItem[] = [
    {
      title: 'Pending requests',
      icon: <MailInbox24Regular />,
      value: DisposalRequestResponseStatus.Pending,
      caption:
        DisposalRequestResponseStatusCaption[
          DisposalRequestResponseStatus.Pending
        ],
    },
    {
      title: 'Delegated',
      icon: <PeopleCommunity24Regular />,
      value: DisposalRequestResponseStatus.Delegated,
      caption:
        DisposalRequestResponseStatusCaption[
          DisposalRequestResponseStatus.Delegated
        ],
    },
    {
      title: 'Approved ',
      icon: <CheckmarkSquare24Regular />,
      value: DisposalRequestResponseStatus.Approved,
      caption:
        DisposalRequestResponseStatusCaption[
          DisposalRequestResponseStatus.Approved
        ],
    },
    {
      title: 'Rejected',
      icon: <DismissSquare24Regular />,
      value: DisposalRequestResponseStatus.Rejected,
      caption:
        DisposalRequestResponseStatusCaption[
          DisposalRequestResponseStatus.Rejected
        ],
    },
  ];

  const _menuItemsIndex = {
    [DisposalRequestResponseStatus.Pending as string]: 0,
    [DisposalRequestResponseStatus.Delegated as string]: 1,
    [DisposalRequestResponseStatus.Approved as string]: 2,
    [DisposalRequestResponseStatus.Rejected as string]: 3,
  };

  const [selectedSideMenuItem, setSelectedSideMenuItem] =
    useState<SideMenuItem>(_menuItems[0]);

  const [menuItems, setMenuItems] = useState(_menuItems);

  useEffect(() => {
    //set default state if not already set
    handleSelect(
      menuItems.find(
        (i) => i.value === selectedSideMenuItemValue ?? 'unknown'
      ) ?? menuItems[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSideMenuItemValue]);

  const handleRefresh = () => {
    setKey(Math.random().toString(36).substring(2, 7));
  };
  const handleCollapse = (value: boolean) => {
    dispatch(setSideMenuCollapsed(value));
  };
  const handleSelect = (item: SideMenuItem) => {
    dispatch(
      setSelectedSideMenuItemValue(
        item.value ?? DisposalRequestResponseStatus.Pending
      )
    );
    setSelectedSideMenuItem(item);
  };

  const handleOpenRequest = (request: DisposalRequest) => {
    navigate(`/request/${request.ID}?history=true`);
  };
  const handleSelectRequest = (request?: DisposalRequest) => {
    console.log('Select request', request?.Name);
  };

  useEffect(() => {
    if (!isLoading && counts) {
      //reset all counts to 0 first - remove to not show zero counts
      Object.keys(_menuItemsIndex).forEach((status) => {
        const index = _menuItemsIndex[status];
        (_menuItems[index] ?? {}).count = 0;
      });
      //--------
      counts?.forEach((c: DisposalRequestCount) => {
        (_menuItems[_menuItemsIndex[c.Status] ?? -1] ?? {}).count = c.Count;
      });
      setMenuItems([..._menuItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts, isLoading]);

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.HOME_PAGE}>
      <Box direction='row' background='none' gap='medium'>
        <SideMenu
          collapsed={sideMenuCollapsed}
          selected={selectedSideMenuItem}
          collapsedSize='xlarge'
          onSelect={handleSelect}
          onRefresh={() => {
            handleRefresh();
          }}
          onCollapse={handleCollapse}
          items={menuItems}
          blur
        />
        <Box
          background='light'
          padding='large'
          rounded='top'
          blur
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {user?.ID && (
            <TabContext value={selectedSideMenuItem?.value as string}>
              {_menuItems.map((item) => (
                <TabPanel
                  value={item.value as string}
                  key={item.value as string}
                  sx={{ padding: 0, display: 'flex', overflow: 'hidden' }}
                >
                  <DRGrid
                    user={user}
                    key={key}
                    filter={item.value as DisposalRequestResponseStatus}
                    onOpen={handleOpenRequest}
                    title={`${selectedSideMenuItem?.caption} Disposal Requests`}
                    onSelect={handleSelectRequest}
                  />
                </TabPanel>
              ))}
            </TabContext>
          )}
        </Box>
      </Box>
    </PageWithAppBar>
  );
};

export default HomePage;
