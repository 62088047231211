import { loginRequest } from '../auth';
import { publicClientApplication } from '../auth/AuthProvider';
import { config } from '../config';

export const getImage: (imageId: string) => Promise<string> = async (
  imageId: string
) => {
  const token = await acquireToken();
  const BASE_URL: string = `${config.API_BASE_URL}/Enc.Image?`;

  const BASE_IMG_URL: string = `${config.REACT_APP_ENC_IMC_URL}/`;

  try {
    if (!!imageId) {
      //TODO: Move to RTKQuery
      const response = await fetch(`${BASE_URL}$filter=ID eq ${imageId}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      let data = await response.json();
      const url = data?.value[0]?.Url ?? '';
      if (url !== '') {
        const imageUrl = `${BASE_IMG_URL}${url}`;
        return imageUrl;
      }
    }
  } catch (error) {
    console.error('failed to fetch image');
    return '';
  }
};

export const acquireToken = async () => {
  const accounts = publicClientApplication.getAllAccounts();

  publicClientApplication.setActiveAccount(accounts[0]);

  const silentRequest = {
    ...loginRequest,
    account: accounts[0],
  };

  const authResult = await publicClientApplication.acquireTokenSilent(
    silentRequest
  );

  return authResult.accessToken;
};
