import { Banner, HomeButton, Page, Box } from '@encompaas/common/components';
import { EncompaasLogo } from '@encompaas/common/images';

import { useNavigate } from 'react-router-dom';
import { TEST_CONSTANTS } from '@encompaas/common/constants';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <Box alignItems='center' justifyContent='center'>
        <EncompaasLogo
          style={{ height: '30rem' }}
          data-testid={TEST_CONSTANTS.ENCOMPAAS_LOGO}
        />
        <Banner
          height={40}
          colour='dark'
          data-testid={TEST_CONSTANTS.ENCOMPAAS_BANNER}
        />
        <Box height={'5rem'} />
        <h1>404</h1>
        <h4>Page Not Found</h4>
        <Box height={5} />
        <HomeButton
          data-testid={TEST_CONSTANTS.HOME_BUTTON}
          size='xxlarge'
          onClick={() => navigate('/')}
        />
      </Box>
    </Page>
  );
};

export default PageNotFound;
