import { Button, useTheme, Typography } from '@mui/material';
import { DisposalRequestResponseStatus, DisposalRequestStatus } from '../types';
import {
  CheckmarkSquare20Regular,
  DocumentPerson20Regular,
  DismissSquare20Regular,
  ErrorCircle20Regular,
  ArchiveMultiple24Regular,
  CompassNorthwest24Regular,
  New20Regular,
  PeopleQueue24Regular,
  PeopleQueue20Regular,
} from '@fluentui/react-icons';

// TODO: this needs to be cleaned up

export type RequestStatusChiprops = {
  title?: string | undefined;
  subTitle?: string | undefined;
  color?: string;
  iconColor?: string;
  variant?: string | DisposalRequestResponseStatus;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  border?: boolean;
};

export const RequestStatusChip = ({
  title,
  subTitle,
  color,
  iconColor,
  icon,
  variant,
  border = true,
  ...rest
}: RequestStatusChiprops) => {
  const theme = useTheme();

  let _icon = icon ?? null;
  const _textColor = color ?? theme.palette.info.main;
  const _iconColor = iconColor ?? theme.palette.info.main;

  if (variant === DisposalRequestResponseStatus.Approved) {
    _icon = <CheckmarkSquare20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Rejected) {
    _icon = <DismissSquare20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Delegated) {
    _icon = <DocumentPerson20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Pending) {
    _icon = <ErrorCircle20Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Managed) {
    _icon = <ArchiveMultiple24Regular color={_iconColor} />;
  } else if (variant === DisposalRequestResponseStatus.Discovered) {
    _icon = <CompassNorthwest24Regular color={_iconColor} />;
  } else if (variant === DisposalRequestStatus.InApproval) {
    _icon = <PeopleQueue20Regular color={_iconColor} />;
  } else {
    _icon = !!_icon
      ? _icon
      : (_icon = <New20Regular color={theme.palette.info.main} />);
  }

  return (
    <Button
      disabled={true}
      startIcon={_icon}
      variant='outlined'
      sx={{
        background: 'none',
        color: { _textColor },
        padding: '0 0.75rem',
        minHeight: subTitle ? '3.2rem' : '2rem',
        height: 'auto',
        fontSize: '0.875rem !important',
        borderColor: border ? theme.palette.info.light : 'transparent',
        '&.Mui-disabled': {
          color: { _textColor },
          borderColor: border ? theme.palette.info.light : 'transparent',
        },
        '&:hover': {
          borderColor: { _textColor },
        },
        borderRadius: '0.25rem !important',
      }}
      size='small'
      data-testid='info-chip'
      {...rest}
    >
      <div style={{ fontSize: '0.875rem !important', color: _textColor }}>
        <Typography variant='body2'>{title}</Typography>
        {subTitle && (
          <Typography
            variant='body3'
            sx={{
              fontSize: '0.775rem !important',
              color: theme.palette.info.main,
            }}
          >
            {subTitle}
          </Typography>
        )}
      </div>
    </Button>
  );
};
