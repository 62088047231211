import {
  DisposalRequestResponseStatusCaption,
  DisposalRequestResponseStatus,
} from '../types';
import { RequestStatusChip } from './RequestStatusChip';
import {
  CheckmarkSquare20Regular,
  DocumentPerson20Regular,
  DismissSquare20Regular,
} from '@fluentui/react-icons';
import { useTheme } from '@mui/material';

export type ApprovalStatusChipProps = {
  status: string | DisposalRequestResponseStatus;
};

export const ApprovalStatusChip = ({ status }: ApprovalStatusChipProps) => {
  const theme = useTheme();
  let color = theme.palette.info.main;
  let icon = null;

  if (status === DisposalRequestResponseStatus.Approved) {
    color = theme.palette.primary.main;
    icon = <CheckmarkSquare20Regular color={color} />;
  } else if (status === DisposalRequestResponseStatus.Delegated) {
    icon = <DocumentPerson20Regular color={color} />;
  } else if (status === DisposalRequestResponseStatus.Rejected) {
    color = theme.palette.warning.main;
    icon = <DismissSquare20Regular color={color} />;
  }

  return (
    <RequestStatusChip
      variant={status}
      title={DisposalRequestResponseStatusCaption[status]}
      icon={icon}
      iconColor={color}
    />
  );
};
