import { styled } from '@mui/material';
import { useIcon } from '../hooks';
import { ItemBusinessClassesValue } from '../types';
import { RequestStatusChip } from './RequestStatusChip';

export type PerspectiveClassChipProps = {
  businessClass: ItemBusinessClassesValue;
};

const StyledImage = styled('img')``;

export const PerspectiveClassChip = ({
  businessClass,
}: PerspectiveClassChipProps) => {
  const imageId = businessClass?._ImageId;

  const { iconDetails } = useIcon({
    imageId,
  });

  return (
    <RequestStatusChip
      title={businessClass?._Display}
      icon={
        iconDetails ? (
          <StyledImage
            src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
            alt={iconDetails?.AltText}
            style={{ height: '1.5rem', width: '1.5rem' }}
          />
        ) : null
      }
      style={{ textAlign: 'left' }}
    />
  );
};
