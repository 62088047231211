import { SVGProps } from 'react';
const SvgCloseRequest20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.50476 0.136942C7.19302 -0.045647 6.80698 -0.0456473 6.49524 0.136941L0.493972 3.65195C-0.165469 4.03819 -0.164456 4.99176 0.495806 5.3766L6.32917 8.77662C6.46633 8.44352 6.64235 8.13053 6.85159 7.84328C6.72851 7.8248 6.60811 7.7832 6.49707 7.71849L0.998732 4.51374L6.49524 1.29438C6.80698 1.11179 7.19302 1.11179 7.50476 1.29438L13.0013 4.51374L10.4643 5.99245L10.4867 5.99239C11.0176 5.99239 11.527 6.08444 11.9998 6.25346L13.5042 5.3766C14.1645 4.99176 14.1655 4.03819 13.506 3.65195L7.50476 0.136942Z'
      fill='currentColor'
    />
    <path
      d='M0.0803981 6.66208L6.01812 10.003C6.00111 10.1619 5.99239 10.3233 5.99239 10.4867C5.99239 10.713 6.00912 10.9354 6.04141 11.1527L0.495806 7.92045C0.0322649 7.65028 -0.106343 7.09978 0.0803981 6.66208Z'
      fill='currentColor'
    />
    <path
      d='M7.51688 13.86C7.16613 13.551 6.86395 13.1881 6.62329 12.7843C6.58479 12.7686 6.54703 12.7504 6.51025 12.7297L0.0803981 9.11191C-0.106343 9.54961 0.0322649 10.1001 0.495806 10.3703L6.49707 13.8682C6.80789 14.0493 7.19211 14.0493 7.50293 13.8682L7.51688 13.86Z'
      fill='currentColor'
    />
    <path
      d='M13.3212 6.99879C13.5221 7.16224 13.7086 7.3427 13.8785 7.53797C14.0254 7.2685 14.0391 6.9422 13.9196 6.66209L13.3212 6.99879Z'
      fill='currentColor'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.9822 10.4867C13.9822 12.4172 12.4172 13.9823 10.4867 13.9823C8.55614 13.9823 6.99113 12.4172 6.99113 10.4867C6.99113 8.55614 8.55614 6.99113 10.4867 6.99113C12.4172 6.99113 13.9822 8.55614 13.9822 10.4867ZM11.7825 9.68519C11.919 9.54868 11.919 9.32736 11.7825 9.19085C11.646 9.05434 11.4247 9.05434 11.2882 9.19085L10.4867 9.99234L9.68519 9.19085C9.54868 9.05434 9.32735 9.05434 9.19084 9.19085C9.05433 9.32736 9.05433 9.54868 9.19084 9.68519L9.99234 10.4867L9.19084 11.2882C9.05433 11.4247 9.05433 11.646 9.19084 11.7825C9.32735 11.919 9.54868 11.919 9.68519 11.7825L10.4867 10.981L11.2882 11.7825C11.4247 11.919 11.646 11.919 11.7825 11.7825C11.919 11.646 11.919 11.4247 11.7825 11.2882L10.981 10.4867L11.7825 9.68519Z'
      fill='currentColor'
    />
  </svg>
);
export default SvgCloseRequest20;
