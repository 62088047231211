import { Routes, Route } from 'react-router-dom';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import {
  HomePage,
  LoginPage,
  PageNotFound,
  DisposalRequestPage,
  ItemPage,
} from './pages';

function App() {
  return (
    <div className='App' data-testid={TEST_CONSTANTS.APP_CONTAINER}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='login' element={<LoginPage />} />
        <Route path='request/:id' element={<DisposalRequestPage />} />
        <Route path='item/:id' element={<ItemPage />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
