import { Chip, useTheme } from '@mui/material';

export enum STATUS {
  DEFAULT = 'default',
  WARNING = 'warning',
}

export type StatusChipProps = {
  label: string;
  status?: STATUS;
  textColor?: string;
  bgColor?: string;
};

export const StatusChip = ({
  label,
  status = STATUS.DEFAULT,
  textColor,
  bgColor,
  ...rest
}: StatusChipProps) => {
  const theme = useTheme();

  let _textColor = textColor ?? theme.palette.info[800];
  let _bgColor = bgColor ?? theme.palette.info[400];

  if (status === STATUS.WARNING) {
    _textColor = textColor ?? theme.palette.warning[900];
    _bgColor = bgColor ?? theme.palette.warning[50];
  }

  return (
    <Chip
      label={label}
      color={status}
      sx={{
        textTransform: 'uppercase',
        fontSize: '0.625rem !important',
        fontWeight: '500',
        fontFamily: 'Avenir Medium',
        padding: '0.25rem 0rem',
        borderRadius: '0.25rem',
        height: 'auto',
        color: _textColor,
        backgroundColor: _bgColor,
      }}
      {...rest}
    />
  );
};
