import { Typography } from '@mui/material';
import { Box } from '../../Box';
import {
  AddCircle24Regular,
  CompassNorthwest24Regular,
  Delete24Regular,
} from '@fluentui/react-icons';
import { RetentionClass24 } from '../../../icons/RetentionClass24';

export const ManagementStatusTemplate = ({ title }: { title: string }) => {
  const ManagementStatusIcon = (status: string) => {
    switch (status) {
      case 'Discovered':
        return <CompassNorthwest24Regular />;
      case 'Managed':
        return <RetentionClass24 />;
      case 'Disposed':
        return <Delete24Regular />;
      case 'Created':
        return <AddCircle24Regular />;
      default:
        return <></>;
    }
  };
  return (
    <Box direction='column' gap='small' background='none'>
      <Box
        alignItems='center'
        direction='row'
        gap='small'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
      >
        <Box
          background='none'
          style={{ width: '1.5rem', height: '1.5rem', flex: 'none' }}
        >
          {ManagementStatusIcon(title)}
        </Box>
        <Typography variant='body1' noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
