import { useState, useEffect } from 'react';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
import { publicClientApplication } from '../../auth/AuthProvider';
import { loginRequest } from '../../auth';
import {
  DisposalRequest,
  DisposalRequestResponseStatus,
  User,
  ApproverResponse,
  DisposalRequestStatus,
} from '../../types';
import { config } from '../../config';

class DisposalRequestAdaptor extends ODataV4Adaptor {
  status: DisposalRequestStatus | DisposalRequestResponseStatus;
  userId: string;
  adminMode: boolean;

  constructor(
    status: DisposalRequestStatus | DisposalRequestResponseStatus,
    userId: string,
    adminMode?: boolean
  ) {
    super();
    this.status = status;
    this.userId = userId;
    this.adminMode = !!adminMode;
  }

  processResponse() {
    // calling base class processResponse function
    var original = super.processResponse.apply(this, arguments);
    // parse the response data in ApproverResponses (not for admin mode)
    !this.adminMode &&
      Array.isArray(original.result) &&
      original.result?.forEach((item: DisposalRequest) => {
        item.Response = item.ApproverResponses?.value?.find(
          (toUser: ApproverResponse) =>
            toUser.ApproverIdToStatus === `${this.userId}_${this.status}`
        );
        //find the delegatedTo record for delegated responses
        if (
          this.status === DisposalRequestResponseStatus.Delegated &&
          !!item.Response
        ) {
          item.DelegatedTo = item.ApproverResponses?.value?.find(
            (toUser: ApproverResponse) =>
              toUser.ID === item.Response.DelegatedToId
          );
        }
      });
    return {
      result: Array.isArray(original.result) ? original.result : null,
      count: original.count,
    };
  }
}

const useData = ({
  status,
  user,
  gridData,
  adminMode = false,
}: {
  status: DisposalRequestStatus | DisposalRequestResponseStatus;
  user: User;
  gridData: DisposalRequest[];
  adminMode?: boolean;
}) => {
  const [dataManager, setDataManager] = useState<DataManager>();

  //could also add token as a property rather than acquiring it here
  const acquireToken = async () => {
    const accounts = publicClientApplication.getAllAccounts();

    publicClientApplication.setActiveAccount(accounts[0]);

    const silentRequest = {
      ...loginRequest,
      account: accounts[0],
    };

    const authResult = await publicClientApplication.acquireTokenSilent(
      silentRequest
    );

    return authResult.accessToken;
  };

  async function fetchData() {
    const token = await acquireToken();

    const filterString = adminMode
      ? `Status eq '${status}'`
      : `ApproverResponses/ApproverIdToStatus eq '${user.ID}_${status}'`;

    const data = new DataManager({
      url:
        config.API_BASE_URL +
        `/DisposalRequest?$select=*&$expand=requestedby&$expand=ApproverResponses($select=*;$expand=Approver,DelegatedTo)&$filter=${filterString}&$count=true&$inlinecount=allpages`,
      adaptor: new DisposalRequestAdaptor(status, user.ID),
      headers: [{ Authorization: `Bearer ${token}` }],
    });

    const query = adminMode
      ? new Query()
          .from('DisposalRequest')
          .select('*')
          .expand('requestedby')
          .expand('ApproverResponses($select=*;$expand=Approver,DelegatedTo)')
          .where('Status', 'equal', status)
          .take(10)
      : new Query()
          .from('DisposalRequest')
          .select('*')
          .expand('requestedby')
          .expand('ApproverResponses($select=*;$expand=Approver,DelegatedTo)')
          .where(
            'ApproverResponses/ApproverIdToStatus',
            'equal',
            `${user.ID}_${status}`
          )
          .take(10);

    data.executeQuery(query).then((a: any) => {
      if (a.result['@odata.count'] == 0) {
        setDataManager(null);
      } else {
        setDataManager(data);
      }
    });
  }

  useEffect(() => {
    if (!gridData?.length) fetchData();
  }, [status, gridData]);

  return { dataManager };
};

export default useData;
