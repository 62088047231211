import './styles/global.scss';
export * from './auth';
export * from './components';
export * from './constants';
export * from './hooks';
export * from './icons';
export * from './images';
export * from './services';
export * from './store';
export * from './themes';
export * from './types';
export * from './util';

export * from './config';

import { registerLicense } from '@syncfusion/ej2-base';
import { config } from './config';

// Registers syncfusion components with the encompaas license
registerLicense(config.SYNCFUSION_LICENSE || 'unlicensed');
