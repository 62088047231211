import * as React from 'react';
import { SVGProps } from 'react';
const SvgEncompaasLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id='encompaasLogo_svg__Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 254.061 202.918'
    {...props}
  >
    <defs>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient'
        x1={-2889.988}
        y1={342.15}
        x2={-2812.52}
        y2={373.45}
        gradientTransform='rotate(20.518 -843.373 12421.829) scale(1.48595)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#7200e5' />
        <stop offset={0.681} stopColor='#00efea' stopOpacity={0.7} />
        <stop offset={1} stopColor='#00efea' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-2'
        x1={-2901.678}
        y1={329.9}
        x2={-2824.21}
        y2={361.199}
        gradientTransform='rotate(24.518 -1078.51 10231.561) scale(1.45681)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-3'
        x1={-2914.449}
        y1={318.277}
        x2={-2836.98}
        y2={349.576}
        gradientTransform='rotate(28.518 -1236.234 8651.734) scale(1.42825)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-4'
        x1={-2928.246}
        y1={307.356}
        x2={-2850.798}
        y2={338.648}
        gradientTransform='rotate(32.518 -1345.367 7457.364) scale(1.40024)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-5'
        x1={-2943.083}
        y1={297.219}
        x2={-2865.635}
        y2={328.51}
        gradientTransform='rotate(36.518 -1422.098 6522.026) scale(1.37279)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-6'
        x1={-2958.918}
        y1={287.942}
        x2={-2881.449}
        y2={319.241}
        gradientTransform='rotate(40.518 -1476.216 5769.207) scale(1.34587)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-7'
        x1={-2975.656}
        y1={279.665}
        x2={-2898.208}
        y2={310.956}
        gradientTransform='rotate(44.518 -1513.99 5149.88) scale(1.31948)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-8'
        x1={-2993.294}
        y1={272.416}
        x2={-2915.845}
        y2={303.707}
        gradientTransform='rotate(48.518 -1539.62 4631.177) scale(1.29361)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-9'
        x1={-3011.772}
        y1={266.277}
        x2={-2934.304}
        y2={297.576}
        gradientTransform='rotate(52.518 -1556.017 4190.235) scale(1.26824)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-10'
        x1={-3030.998}
        y1={261.373}
        x2={-2953.53}
        y2={292.672}
        gradientTransform='rotate(56.518 -1565.272 3810.657) scale(1.24337)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-11'
        x1={-3050.901}
        y1={257.73}
        x2={-2973.434}
        y2={289.029}
        gradientTransform='rotate(60.518 -1568.916 3480.373) scale(1.21899)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-12'
        x1={-3071.401}
        y1={255.468}
        x2={-2993.952}
        y2={286.76}
        gradientTransform='rotate(64.518 -1568.1 3190.3) scale(1.19509)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-13'
        x1={-3092.438}
        y1={254.608}
        x2={-3014.989}
        y2={285.899}
        gradientTransform='rotate(68.518 -1563.7 2933.47) scale(1.17166)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-14'
        x1={-3113.92}
        y1={255.212}
        x2={-3036.451}
        y2={286.511}
        gradientTransform='rotate(72.518 -1556.403 2704.45) scale(1.14869)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-15'
        x1={-3135.721}
        y1={257.381}
        x2={-3058.253}
        y2={288.68}
        gradientTransform='rotate(76.518 -1546.743 2498.936) scale(1.12616)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-16'
        x1={-3157.724}
        y1={261.128}
        x2={-3080.275}
        y2={292.419}
        gradientTransform='rotate(80.518 -1535.152 2313.476) scale(1.10408)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-17'
        x1={-3179.876}
        y1={266.518}
        x2={-3102.424}
        y2={297.811}
        gradientTransform='rotate(84.518 -1521.975 2145.267) scale(1.08243)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-18'
        x1={-3202.042}
        y1={273.551}
        x2={-3124.57}
        y2={304.852}
        gradientTransform='rotate(88.518 -1507.494 1992.01) scale(1.06121)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-19'
        x1={-3224.059}
        y1={282.322}
        x2={-3146.611}
        y2={313.613}
        gradientTransform='rotate(92.518 -1491.939 1851.798) scale(1.0404)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-20'
        x1={-3245.879}
        y1={292.786}
        x2={-3168.41}
        y2={324.085}
        gradientTransform='rotate(96.518 -1475.503 1723.042) scale(1.02)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-21'
        x1={-3267.316}
        y1={304.993}
        x2={-3189.847}
        y2={336.292}
        gradientTransform='rotate(100.518 -1458.344 1604.397)'
        xlinkHref='#encompaasLogo_svg__linear-gradient'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-22'
        x1={-358.431}
        y1={2056.708}
        x2={-312.311}
        y2={1981.082}
        gradientTransform='matrix(.04027 -1.45687 1.5923 -.13395 -3076.65 -129.209)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#7200e5' />
        <stop offset={0.569} stopColor='#00efea' stopOpacity={0.7} />
        <stop offset={1} stopColor='#00efea' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-23'
        x1={-345.418}
        y1={2054.389}
        x2={-299.297}
        y2={1978.762}
        gradientTransform='matrix(.11307 -1.42429 1.5506 -.04944 -2965.128 -269.714)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-24'
        x1={-332.235}
        y1={2051.376}
        x2={-286.113}
        y2={1975.748}
        gradientTransform='matrix(.182 -1.38864 1.50589 .03116 -2849.863 -401.553)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-25'
        x1={-318.923}
        y1={2047.64}
        x2={-272.801}
        y2={1972.011}
        gradientTransform='matrix(.24701 -1.35021 1.45845 .10777 -2731.41 -524.715)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-26'
        x1={-305.5}
        y1={2043.171}
        x2={-259.378}
        y2={1967.542}
        gradientTransform='matrix(.3081 -1.30925 1.40855 .18035 -2610.307 -639.22)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-27'
        x1={-291.991}
        y1={2037.956}
        x2={-245.868}
        y2={1962.326}
        gradientTransform='matrix(.36524 -1.266 1.35649 .24884 -2487.074 -745.11)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-28'
        x1={-278.455}
        y1={2031.964}
        x2={-232.333}
        y2={1956.336}
        gradientTransform='matrix(.41844 -1.22075 1.30253 .31323 -2362.214 -842.46)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-29'
        x1={-264.917}
        y1={2025.188}
        x2={-218.795}
        y2={1949.559}
        gradientTransform='matrix(.46772 -1.1737 1.24694 .3735 -2236.21 -931.364)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-30'
        x1={-251.481}
        y1={2017.577}
        x2={-205.36}
        y2={1941.95}
        gradientTransform='matrix(.51312 -1.1251 1.18999 .42965 -2109.527 -1011.94)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-31'
        x1={-238.101}
        y1={2009.174}
        x2={-191.981}
        y2={1933.549}
        gradientTransform='matrix(.55466 -1.0752 1.13191 .4817 -1982.608 -1084.328)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-32'
        x1={-224.926}
        y1={1999.905}
        x2={-178.807}
        y2={1924.282}
        gradientTransform='matrix(.5924 -1.02422 1.07296 .5297 -1855.876 -1148.686)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-33'
        x1={-211.969}
        y1={1989.785}
        x2={-165.853}
        y2={1914.165}
        gradientTransform='matrix(.6264 -.97236 1.0084 .57045 -1729.734 -1205.194)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-34'
        x1={-199.301}
        y1={1978.795}
        x2={-153.187}
        y2={1903.179}
        gradientTransform='matrix(.65673 -.91985 .95155 .61233 -1604.562 -1254.045)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-35'
        x1={-186.949}
        y1={1966.947}
        x2={-140.836}
        y2={1891.334}
        gradientTransform='matrix(.68347 -.86689 .89337 .64987 -1480.72 -1295.45)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-36'
        x1={-174.986}
        y1={1954.23}
        x2={-128.875}
        y2={1878.621}
        gradientTransform='matrix(.7067 -.81366 .83422 .68302 -1358.547 -1329.634)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-37'
        x1={-163.474}
        y1={1940.643}
        x2={-117.365}
        y2={1865.037}
        gradientTransform='matrix(.72651 -.76034 .77441 .71179 -1238.358 -1356.833)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-38'
        x1={-152.474}
        y1={1926.186}
        x2={-106.367}
        y2={1850.584}
        gradientTransform='matrix(.74302 -.70714 .7143 .7362 -1120.447 -1377.295)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-39'
        x1={-142.053}
        y1={1910.864}
        x2={-95.95}
        y2={1835.267}
        gradientTransform='rotate(-40.859 -2370.123 653.537)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-22'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-40'
        x1={-2351.039}
        y1={3104.223}
        x2={-2321.832}
        y2={3190.895}
        gradientTransform='matrix(-1.18707 .82103 -.95871 -1.28658 358.636 6078.994)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#7200e5' />
        <stop offset={0.5} stopColor='#00efea' stopOpacity={0.7} />
        <stop offset={1} stopColor='#00efea' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-41'
        x1={-2355.526}
        y1={3124.503}
        x2={-2326.319}
        y2={3211.175}
        gradientTransform='matrix(-1.22951 .69364 -.8264 -1.32547 -145.335 5932.013)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-42'
        x1={-2358.203}
        y1={3145.565}
        x2={-2328.995}
        y2={3232.24}
        gradientTransform='matrix(-1.26009 .56683 -.69386 -1.3519 -624.985 5747.212)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-43'
        x1={-2358.955}
        y1={3167.273}
        x2={-2329.746}
        y2={3253.951}
        gradientTransform='matrix(-1.27911 .4416 -.56215 -1.36625 -1077.65 5527.889)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-44'
        x1={-2357.678}
        y1={3189.477}
        x2={-2328.468}
        y2={3276.159}
        gradientTransform='matrix(-1.287 .31888 -.43229 -1.36897 -1501.01 5277.461)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-45'
        x1={-2354.282}
        y1={3212.016}
        x2={-2325.07}
        y2={3298.704}
        gradientTransform='matrix(-1.2842 .1995 -.30522 -1.36062 -1893.09 4999.429)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-46'
        x1={-2348.687}
        y1={3234.717}
        x2={-2319.472}
        y2={3321.412}
        gradientTransform='matrix(-1.27128 .0843 -.18184 -1.34179 -2252.264 4697.345)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-47'
        x1={-2340.829}
        y1={3257.398}
        x2={-2311.611}
        y2={3344.103}
        gradientTransform='matrix(-1.24882 -.02604 -.06295 -1.31314 -2577.243 4374.784)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-48'
        x1={-2330.659}
        y1={3279.867}
        x2={-2301.439}
        y2={3366.577}
        gradientTransform='matrix(-1.21745 -.13086 .05073 -1.27536 -2867.076 4035.31)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-49'
        x1={-2318.147}
        y1={3301.926}
        x2={-2288.932}
        y2={3388.622}
        gradientTransform='matrix(-1.17785 -.22958 .15852 -1.22922 -3121.137 3682.453)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-50'
        x1={-2303.276}
        y1={3323.366}
        x2={-2274.058}
        y2={3410.072}
        gradientTransform='matrix(-1.13074 -.32171 .25985 -1.17546 -3339.118 3319.68)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-51'
        x1={-2286.055}
        y1={3343.98}
        x2={-2256.835}
        y2={3430.693}
        gradientTransform='matrix(-1.07686 -.40683 .35423 -1.11489 -3521.015 2950.368)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-52'
        x1={-2266.509}
        y1={3363.55}
        x2={-2237.286}
        y2={3450.27}
        gradientTransform='matrix(-1.01697 -.4846 .43707 -1.03962 -3667.11 2577.785)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-53'
        x1={-2244.685}
        y1={3381.859}
        x2={-2215.459}
        y2={3468.587}
        gradientTransform='matrix(-.95183 -.55475 .51842 -.973 -3777.958 2205.069)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-54'
        x1={-2220.649}
        y1={3398.692}
        x2={-2191.421}
        y2={3485.426}
        gradientTransform='matrix(-.88221 -.61709 .59152 -.9001 -3854.372 1835.206)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-55'
        x1={-2194.492}
        y1={3413.829}
        x2={-2165.263}
        y2={3500.568}
        gradientTransform='matrix(-.80889 -.67148 .6558 -.8219 -3897.4 1471.016)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-56'
        x1={-2166.328}
        y1={3427.061}
        x2={-2137.098}
        y2={3513.801}
        gradientTransform='matrix(-.73264 -.71789 .71083 -.73956 -3908.304 1115.138)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-57'
        x1={-2136.283}
        y1={3438.194}
        x2={-2107.059}
        y2={3524.915}
        gradientTransform='rotate(-130.859 -1768.241 1273.958)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-40'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-58'
        x1={-2889.988}
        y1={342.15}
        x2={-2812.52}
        y2={373.45}
        gradientTransform='rotate(20.518 -843.373 12421.829) scale(1.48595)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#7200e5' />
        <stop offset={0.681} stopColor='#00efea' stopOpacity={0.7} />
        <stop offset={0.81} stopColor='#00efea' stopOpacity={0.692} />
        <stop offset={0.857} stopColor='#00efea' stopOpacity={0.664} />
        <stop offset={0.89} stopColor='#00efea' stopOpacity={0.614} />
        <stop offset={0.917} stopColor='#00efea' stopOpacity={0.544} />
        <stop offset={0.94} stopColor='#00efea' stopOpacity={0.451} />
        <stop offset={0.96} stopColor='#00efea' stopOpacity={0.338} />
        <stop offset={0.979} stopColor='#00efea' stopOpacity={0.202} />
        <stop offset={0.995} stopColor='#00efea' stopOpacity={0.05} />
        <stop offset={1} stopColor='#00efea' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-59'
        x1={-2901.678}
        y1={329.9}
        x2={-2824.21}
        y2={361.199}
        gradientTransform='rotate(24.518 -1078.51 10231.561) scale(1.45681)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-60'
        x1={-2914.449}
        y1={318.277}
        x2={-2836.98}
        y2={349.576}
        gradientTransform='rotate(28.518 -1236.234 8651.734) scale(1.42825)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-61'
        x1={-2928.246}
        y1={307.356}
        x2={-2850.798}
        y2={338.648}
        gradientTransform='rotate(32.518 -1345.367 7457.364) scale(1.40024)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-62'
        x1={-2943.083}
        y1={297.219}
        x2={-2865.635}
        y2={328.51}
        gradientTransform='rotate(36.518 -1422.098 6522.026) scale(1.37279)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-63'
        x1={-2958.918}
        y1={287.942}
        x2={-2881.449}
        y2={319.241}
        gradientTransform='rotate(40.518 -1476.216 5769.207) scale(1.34587)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-64'
        x1={-2975.656}
        y1={279.665}
        x2={-2898.208}
        y2={310.956}
        gradientTransform='rotate(44.518 -1513.99 5149.88) scale(1.31948)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-65'
        x1={-2993.294}
        y1={272.416}
        x2={-2915.845}
        y2={303.707}
        gradientTransform='rotate(48.518 -1539.62 4631.177) scale(1.29361)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-66'
        x1={-3011.772}
        y1={266.277}
        x2={-2934.304}
        y2={297.576}
        gradientTransform='rotate(52.518 -1556.017 4190.235) scale(1.26824)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-67'
        x1={-3030.998}
        y1={261.373}
        x2={-2953.53}
        y2={292.672}
        gradientTransform='rotate(56.518 -1565.272 3810.657) scale(1.24337)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-68'
        x1={-3050.901}
        y1={257.73}
        x2={-2973.434}
        y2={289.029}
        gradientTransform='rotate(60.518 -1568.916 3480.373) scale(1.21899)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-69'
        x1={-3071.401}
        y1={255.468}
        x2={-2993.952}
        y2={286.76}
        gradientTransform='rotate(64.518 -1568.1 3190.3) scale(1.19509)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-70'
        x1={-3092.438}
        y1={254.608}
        x2={-3014.989}
        y2={285.899}
        gradientTransform='rotate(68.518 -1563.7 2933.47) scale(1.17166)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-71'
        x1={-3113.92}
        y1={255.212}
        x2={-3036.451}
        y2={286.511}
        gradientTransform='rotate(72.518 -1556.403 2704.45) scale(1.14869)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-72'
        x1={-3135.721}
        y1={257.381}
        x2={-3058.253}
        y2={288.68}
        gradientTransform='rotate(76.518 -1546.743 2498.936) scale(1.12616)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-73'
        x1={-3157.724}
        y1={261.128}
        x2={-3080.275}
        y2={292.419}
        gradientTransform='rotate(80.518 -1535.152 2313.476) scale(1.10408)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-74'
        x1={-3179.876}
        y1={266.518}
        x2={-3102.424}
        y2={297.811}
        gradientTransform='rotate(84.518 -1521.975 2145.267) scale(1.08243)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-75'
        x1={-3202.042}
        y1={273.551}
        x2={-3124.57}
        y2={304.852}
        gradientTransform='rotate(88.518 -1507.494 1992.01) scale(1.06121)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-76'
        x1={-3224.059}
        y1={282.322}
        x2={-3146.611}
        y2={313.613}
        gradientTransform='rotate(92.518 -1491.939 1851.798) scale(1.0404)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-77'
        x1={-3245.879}
        y1={292.786}
        x2={-3168.41}
        y2={324.085}
        gradientTransform='rotate(96.518 -1475.503 1723.042) scale(1.02)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-78'
        x1={-3267.316}
        y1={304.993}
        x2={-3189.847}
        y2={336.292}
        gradientTransform='rotate(100.518 -1458.344 1604.397)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-58'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-79'
        x1={-358.431}
        y1={2056.708}
        x2={-312.311}
        y2={1981.082}
        gradientTransform='matrix(.04027 -1.45687 1.5923 -.13395 -3076.65 -129.209)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#7200e5' />
        <stop offset={0.569} stopColor='#00efea' stopOpacity={0.7} />
        <stop offset={0.744} stopColor='#00efea' stopOpacity={0.692} />
        <stop offset={0.807} stopColor='#00efea' stopOpacity={0.664} />
        <stop offset={0.852} stopColor='#00efea' stopOpacity={0.614} />
        <stop offset={0.888} stopColor='#00efea' stopOpacity={0.544} />
        <stop offset={0.919} stopColor='#00efea' stopOpacity={0.451} />
        <stop offset={0.947} stopColor='#00efea' stopOpacity={0.338} />
        <stop offset={0.971} stopColor='#00efea' stopOpacity={0.202} />
        <stop offset={0.994} stopColor='#00efea' stopOpacity={0.05} />
        <stop offset={1} stopColor='#00efea' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-80'
        x1={-345.418}
        y1={2054.389}
        x2={-299.297}
        y2={1978.762}
        gradientTransform='matrix(.11307 -1.42429 1.5506 -.04944 -2965.128 -269.714)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-81'
        x1={-332.235}
        y1={2051.376}
        x2={-286.113}
        y2={1975.748}
        gradientTransform='matrix(.182 -1.38864 1.50589 .03116 -2849.863 -401.553)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-82'
        x1={-318.923}
        y1={2047.64}
        x2={-272.801}
        y2={1972.011}
        gradientTransform='matrix(.24701 -1.35021 1.45845 .10777 -2731.41 -524.715)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-83'
        x1={-305.5}
        y1={2043.171}
        x2={-259.378}
        y2={1967.542}
        gradientTransform='matrix(.3081 -1.30925 1.40855 .18035 -2610.307 -639.22)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-84'
        x1={-291.991}
        y1={2037.956}
        x2={-245.868}
        y2={1962.326}
        gradientTransform='matrix(.36524 -1.266 1.35649 .24884 -2487.074 -745.11)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-85'
        x1={-278.455}
        y1={2031.964}
        x2={-232.333}
        y2={1956.336}
        gradientTransform='matrix(.41844 -1.22075 1.30253 .31323 -2362.214 -842.46)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-86'
        x1={-264.917}
        y1={2025.188}
        x2={-218.795}
        y2={1949.559}
        gradientTransform='matrix(.46772 -1.1737 1.24694 .3735 -2236.21 -931.364)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-87'
        x1={-251.481}
        y1={2017.577}
        x2={-205.36}
        y2={1941.95}
        gradientTransform='matrix(.51312 -1.1251 1.18999 .42965 -2109.527 -1011.94)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-88'
        x1={-238.101}
        y1={2009.174}
        x2={-191.981}
        y2={1933.549}
        gradientTransform='matrix(.55466 -1.0752 1.13191 .4817 -1982.608 -1084.328)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-89'
        x1={-224.926}
        y1={1999.905}
        x2={-178.807}
        y2={1924.282}
        gradientTransform='matrix(.5924 -1.02422 1.07296 .5297 -1855.876 -1148.686)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-90'
        x1={-211.969}
        y1={1989.785}
        x2={-165.853}
        y2={1914.165}
        gradientTransform='matrix(.6264 -.97236 1.0084 .57045 -1729.734 -1205.194)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-91'
        x1={-199.301}
        y1={1978.795}
        x2={-153.187}
        y2={1903.179}
        gradientTransform='matrix(.65673 -.91985 .95155 .61233 -1604.562 -1254.045)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-92'
        x1={-186.949}
        y1={1966.947}
        x2={-140.836}
        y2={1891.334}
        gradientTransform='matrix(.68347 -.86689 .89337 .64987 -1480.72 -1295.45)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-93'
        x1={-174.986}
        y1={1954.23}
        x2={-128.875}
        y2={1878.621}
        gradientTransform='matrix(.7067 -.81366 .83422 .68302 -1358.547 -1329.634)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-94'
        x1={-163.474}
        y1={1940.643}
        x2={-117.365}
        y2={1865.037}
        gradientTransform='matrix(.72651 -.76034 .77441 .71179 -1238.358 -1356.833)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-95'
        x1={-152.474}
        y1={1926.186}
        x2={-106.367}
        y2={1850.584}
        gradientTransform='matrix(.74302 -.70714 .7143 .7362 -1120.447 -1377.295)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-96'
        x1={-142.053}
        y1={1910.864}
        x2={-95.95}
        y2={1835.267}
        gradientTransform='rotate(-40.859 -2370.123 653.537)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-79'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-97'
        x1={-2351.039}
        y1={3104.223}
        x2={-2321.832}
        y2={3190.895}
        gradientTransform='matrix(-1.18707 .82103 -.95871 -1.28658 358.636 6078.994)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#7200e5' />
        <stop offset={0.5} stopColor='#00efea' stopOpacity={0.7} />
        <stop offset={0.703} stopColor='#00efea' stopOpacity={0.692} />
        <stop offset={0.776} stopColor='#00efea' stopOpacity={0.664} />
        <stop offset={0.828} stopColor='#00efea' stopOpacity={0.614} />
        <stop offset={0.87} stopColor='#00efea' stopOpacity={0.544} />
        <stop offset={0.906} stopColor='#00efea' stopOpacity={0.451} />
        <stop offset={0.938} stopColor='#00efea' stopOpacity={0.338} />
        <stop offset={0.967} stopColor='#00efea' stopOpacity={0.202} />
        <stop offset={0.993} stopColor='#00efea' stopOpacity={0.05} />
        <stop offset={1} stopColor='#00efea' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-98'
        x1={-2355.526}
        y1={3124.503}
        x2={-2326.319}
        y2={3211.175}
        gradientTransform='matrix(-1.22951 .69364 -.8264 -1.32547 -145.335 5932.013)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-99'
        x1={-2358.203}
        y1={3145.565}
        x2={-2328.995}
        y2={3232.24}
        gradientTransform='matrix(-1.26009 .56683 -.69386 -1.3519 -624.985 5747.212)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-100'
        x1={-2358.955}
        y1={3167.273}
        x2={-2329.746}
        y2={3253.951}
        gradientTransform='matrix(-1.27911 .4416 -.56215 -1.36625 -1077.65 5527.889)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-101'
        x1={-2357.678}
        y1={3189.477}
        x2={-2328.468}
        y2={3276.159}
        gradientTransform='matrix(-1.287 .31888 -.43229 -1.36897 -1501.01 5277.461)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-102'
        x1={-2354.282}
        y1={3212.016}
        x2={-2325.07}
        y2={3298.704}
        gradientTransform='matrix(-1.2842 .1995 -.30522 -1.36062 -1893.09 4999.429)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-103'
        x1={-2348.687}
        y1={3234.717}
        x2={-2319.472}
        y2={3321.412}
        gradientTransform='matrix(-1.27128 .0843 -.18184 -1.34179 -2252.264 4697.345)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-104'
        x1={-2340.829}
        y1={3257.398}
        x2={-2311.611}
        y2={3344.103}
        gradientTransform='matrix(-1.24882 -.02604 -.06295 -1.31314 -2577.243 4374.784)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-105'
        x1={-2330.659}
        y1={3279.867}
        x2={-2301.439}
        y2={3366.577}
        gradientTransform='matrix(-1.21745 -.13086 .05073 -1.27536 -2867.076 4035.31)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-106'
        x1={-2318.147}
        y1={3301.926}
        x2={-2288.932}
        y2={3388.622}
        gradientTransform='matrix(-1.17785 -.22958 .15852 -1.22922 -3121.137 3682.453)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-107'
        x1={-2303.276}
        y1={3323.366}
        x2={-2274.058}
        y2={3410.072}
        gradientTransform='matrix(-1.13074 -.32171 .25985 -1.17546 -3339.118 3319.68)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-108'
        x1={-2286.055}
        y1={3343.98}
        x2={-2256.835}
        y2={3430.693}
        gradientTransform='matrix(-1.07686 -.40683 .35423 -1.11489 -3521.015 2950.368)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-109'
        x1={-2266.509}
        y1={3363.55}
        x2={-2237.286}
        y2={3450.27}
        gradientTransform='matrix(-1.01697 -.4846 .43707 -1.03962 -3667.11 2577.785)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-110'
        x1={-2244.685}
        y1={3381.859}
        x2={-2215.459}
        y2={3468.587}
        gradientTransform='matrix(-.95183 -.55475 .51842 -.973 -3777.958 2205.069)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-111'
        x1={-2220.649}
        y1={3398.692}
        x2={-2191.421}
        y2={3485.426}
        gradientTransform='matrix(-.88221 -.61709 .59152 -.9001 -3854.372 1835.206)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-112'
        x1={-2194.492}
        y1={3413.829}
        x2={-2165.263}
        y2={3500.568}
        gradientTransform='matrix(-.80889 -.67148 .6558 -.8219 -3897.4 1471.016)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-113'
        x1={-2166.328}
        y1={3427.061}
        x2={-2137.098}
        y2={3513.801}
        gradientTransform='matrix(-.73264 -.71789 .71083 -.73956 -3908.304 1115.138)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <linearGradient
        id='encompaasLogo_svg__linear-gradient-114'
        x1={-2136.283}
        y1={3438.194}
        x2={-2107.059}
        y2={3524.915}
        gradientTransform='rotate(-130.859 -1768.241 1273.958)'
        xlinkHref='#encompaasLogo_svg__linear-gradient-97'
      />
      <style>
        {
          '.encompaasLogo_svg__cls-100{opacity:.6}.encompaasLogo_svg__cls-101{opacity:.8}'
        }
      </style>
    </defs>
    <g className='encompaasLogo_svg__cls-101'>
      <path
        d='M131.976 166.082c-3.448 0-6.512-.463-9.064-1.419-10.053-3.762-21.117-18.524-28.188-37.61-7.588-20.484-8.88-41.364-3.458-55.854 5.842-15.61 17.413-28.01 32.581-34.918 15.17-6.906 32.121-7.493 47.729-1.651 15.61 5.842 28.01 17.412 34.917 32.581 6.907 15.169 7.493 32.119 1.651 47.728-5.423 14.49-20.106 29.392-39.279 39.861-13.33 7.278-26.756 11.282-36.889 11.282Zm17.7-133.72c-8.558 0-17.092 1.834-25.115 5.487-14.749 6.716-26 18.774-31.681 33.953-5.283 14.114-3.989 34.545 3.46 54.653 6.904 18.636 17.57 33 27.175 36.595 9.608 3.595 27.08-.237 44.525-9.762 18.82-10.277 33.209-24.838 38.491-38.952 5.68-15.179 5.11-31.661-1.605-46.41-6.717-14.75-18.775-26.002-33.954-31.683-6.922-2.59-14.118-3.881-21.296-3.881Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.483 164.495c-4.582 0-8.562-.689-11.668-2.105-9.574-4.367-19.385-19.562-24.994-38.71-6.02-20.554-5.857-41.063.438-54.864 6.78-14.866 18.945-26.203 34.252-31.92 15.308-5.72 31.925-5.133 46.792 1.648s26.204 18.946 31.92 34.253c5.718 15.307 5.133 31.925-1.648 46.792-6.295 13.8-21.675 27.37-41.142 36.297-12.255 5.62-24.405 8.609-33.95 8.609Zm19.462-129.81c-7.024 0-14.065 1.261-20.843 3.793-14.884 5.56-26.714 16.583-33.306 31.04-13.99 30.67 5.372 82.966 23.72 91.335 18.349 8.373 70.533-11.295 84.522-41.965 6.594-14.456 7.163-30.615 1.603-45.5s-16.583-26.713-31.039-33.307c-7.874-3.591-16.254-5.396-24.657-5.396Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-2)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.712 162.791c-5.97 0-11.053-.978-14.725-2.973-9.064-4.926-17.621-20.457-21.797-39.569-4.482-20.512-2.92-40.56 4.18-53.626 7.648-14.076 20.32-24.331 35.682-28.876 15.36-4.545 31.573-2.836 45.65 4.812 29.057 15.79 39.851 52.275 24.063 81.333-7.1 13.066-23.069 25.285-42.718 32.685-10.893 4.102-21.568 6.214-30.335 6.214Zm20.435-125.88a58.54 58.54 0 0 0-16.625 2.424c-14.937 4.42-27.26 14.391-34.697 28.079-15.78 29.04-.42 81.51 16.953 90.95 17.375 9.44 69.754-6.224 85.532-35.263l.728.395-.728-.395c15.353-28.255 4.856-63.733-23.4-79.086-8.666-4.71-18.167-7.104-27.763-7.104Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-3)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.763 161.026c-7.652 0-14.08-1.35-18.322-4.053-8.53-5.438-15.835-21.213-18.612-40.19-2.98-20.368-.081-39.867 7.756-52.16 17.427-27.338 53.85-35.402 81.19-17.973 27.337 17.43 35.4 53.85 17.97 81.19-7.837 12.293-24.291 23.15-44.014 29.044-9.24 2.76-18.228 4.142-25.968 4.142Zm20.494-121.955c-18.898 0-37.414 9.342-48.303 26.424-7.634 11.975-10.445 31.06-7.519 51.053 2.712 18.53 9.73 33.86 17.88 39.055 16.344 10.42 68.644-1.316 86.062-28.637 16.947-26.583 9.108-61.999-17.476-78.947a56.84 56.84 0 0 0-30.644-8.948Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-4)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.717 159.262c-9.665 0-17.73-1.832-22.527-5.384-7.97-5.9-14.035-21.828-15.454-40.578-1.522-20.123 2.646-38.995 11.151-50.483 18.917-25.546 55.087-30.938 80.633-12.024 25.545 18.915 30.939 55.087 12.025 80.632-8.506 11.487-25.341 20.981-45.034 25.396-7.305 1.638-14.4 2.44-20.794 2.44Zm19.57-118.174c-17.176 0-34.138 7.844-45.12 22.677-8.285 11.188-12.34 29.662-10.844 49.415 1.386 18.309 7.2 33.781 14.814 39.419 15.273 11.309 67.224 3.409 86.128-22.121 18.393-24.84 13.148-60.013-11.692-78.406a55.765 55.765 0 0 0-33.286-10.984Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-5)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.579 157.56c-11.99 0-22.026-2.461-27.335-6.998-7.39-6.317-12.234-22.309-12.34-40.743-.112-19.785 5.256-37.957 14.36-48.61 9.807-11.476 23.496-18.445 38.546-19.625 15.051-1.176 29.658 3.573 41.134 13.38C193.42 64.77 200.39 78.46 201.57 93.51c1.18 15.048-3.573 29.658-13.38 41.133-9.103 10.653-26.217 18.787-45.778 21.758-5.133.78-10.133 1.158-14.832 1.158Zm17.736-114.592c-1.456 0-2.916.057-4.383.172-14.633 1.147-27.945 7.924-37.482 19.083-8.868 10.376-14.096 28.166-13.985 47.587.102 18 4.731 33.531 11.793 39.565 7.061 6.036 23.127 8.186 40.919 5.485 19.2-2.917 35.958-10.853 44.827-21.23 9.537-11.158 14.157-25.364 13.01-39.997-1.147-14.635-7.924-27.946-19.084-37.483-10.04-8.58-22.548-13.182-35.615-13.182Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-6)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.252 155.98c-14.558 0-26.897-3.284-32.64-8.932-6.797-6.682-10.44-22.655-9.282-40.69C88.572 87 95.065 69.595 104.698 59.8c21.419-21.785 56.57-22.08 78.354-.66 21.785 21.421 22.08 56.571.659 78.355-9.632 9.796-26.926 16.581-46.26 18.149-2.79.226-5.531.337-8.2.337Zm14.957-111.185c-13.95 0-27.89 5.368-38.421 16.077-9.382 9.541-15.712 26.582-16.932 45.584-1.13 17.611 2.334 33.117 8.827 39.501 13.025 12.808 63.53 12.237 84.938-9.534 20.829-21.182 20.542-55.362-.64-76.192-10.474-10.299-24.129-15.436-37.772-15.436Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-7)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M131.511 154.57c-17.238 0-32.157-4.357-38.212-11.207-6.19-7-8.662-22.87-6.295-40.43 2.539-18.847 10.079-35.425 20.17-44.347 22.439-19.84 56.837-17.724 76.676 4.715 19.841 22.44 17.726 56.836-4.714 76.677-10.09 8.921-27.467 14.374-46.484 14.585-.38.004-.763.006-1.14.006Zm11.614-108.085c-12.44 0-24.927 4.356-34.957 13.225-9.83 8.69-17.186 24.923-19.678 43.424-2.31 17.146.019 32.548 5.932 39.236 5.913 6.688 20.923 10.894 38.213 10.693 18.667-.207 35.68-5.519 45.507-14.21 21.82-19.292 23.877-52.739 4.585-74.559-10.424-11.789-24.981-17.81-39.602-17.81Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-8)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M133.894 153.35a99.98 99.98 0 0 1-5.861-.172c-17.34-1.019-32.148-6.375-37.722-13.645-5.575-7.27-6.906-22.96-3.391-39.971 3.772-18.258 12.28-33.957 22.758-41.992 11.29-8.656 25.274-12.397 39.375-10.537 14.103 1.861 26.637 9.103 35.292 20.392 8.658 11.288 12.4 25.271 10.539 39.374s-9.104 26.637-20.392 35.293c-9.37 7.185-24.248 11.259-40.598 11.259Zm8.085-105.313c-11.322 0-22.27 3.695-31.406 10.7-10.206 7.827-18.51 23.2-22.214 41.123-3.432 16.611-2.208 31.833 3.118 38.779s19.71 12.076 36.642 13.071c18.264 1.07 35.27-2.958 45.478-10.785l.448.584-.448-.584c10.977-8.417 18.019-20.605 19.829-34.318 1.81-13.714-1.83-27.311-10.247-38.288s-20.605-18.018-34.318-19.828a52.67 52.67 0 0 0-6.882-.454Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-9)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M135.895 152.308c-4.005 0-8.12-.263-12.285-.8-16.89-2.183-31.005-8.434-35.96-15.926-4.954-7.49-5.183-22.926-.583-39.323 4.939-17.599 14.333-32.37 25.131-39.512 11.632-7.694 25.561-10.397 39.23-7.612 13.665 2.785 25.427 10.724 33.122 22.357 15.881 24.011 9.268 56.468-14.745 72.35-8.317 5.502-20.477 8.466-33.91 8.466Zm5.029-102.768a50.474 50.474 0 0 0-27.931 8.41c-10.518 6.956-19.69 21.423-24.537 38.698-4.493 16.012-4.337 30.982.397 38.14 9.494 14.355 56.157 23.725 80.157 7.852l.398.602-.398-.602c11.311-7.481 19.032-18.92 21.74-32.208 2.707-13.287.078-26.834-7.404-38.146-9.753-14.747-25.94-22.746-42.422-22.746Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-10)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M137.376 151.407c-5.778 0-11.847-.604-17.977-1.836-16.369-3.29-29.746-10.368-34.08-18.034-4.334-7.665-3.502-22.777 2.12-38.499 6.032-16.874 16.23-30.677 27.28-36.923 11.902-6.729 25.71-8.42 38.886-4.763 13.174 3.659 24.136 12.228 30.864 24.13 6.73 11.903 8.421 25.713 4.763 38.887-3.658 13.175-12.228 24.136-24.13 30.865l-.348-.615.347.615c-7.193 4.067-16.947 6.173-27.725 6.173Zm2.458-100.53c-8.474 0-16.843 2.184-24.42 6.468-10.762 6.084-20.723 19.605-26.645 36.17-5.489 15.351-6.36 30.003-2.22 37.327 8.306 14.69 53.301 27.045 77.857 13.162 11.574-6.544 19.907-17.202 23.464-30.013 3.557-12.81 1.913-26.24-4.631-37.813-6.543-11.574-17.201-19.907-30.012-23.464a50.024 50.024 0 0 0-13.393-1.836Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-11)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.263 150.606c-7.232 0-15.028-1.068-22.85-3.217-15.784-4.337-28.383-12.174-32.097-19.968-3.714-7.793-1.867-22.515 4.705-37.506 7.055-16.09 17.972-28.893 29.205-34.247 24.98-11.905 54.987-1.268 66.893 23.712 11.904 24.98 1.267 54.988-23.712 66.893-6.046 2.882-13.712 4.333-22.144 4.333Zm.478-98.43a48.51 48.51 0 0 0-20.919 4.743c-24.964 11.897-40.373 54.971-33.256 69.907 7.118 14.933 50.277 30.093 75.245 18.196 24.29-11.576 34.633-40.756 23.058-65.046-8.358-17.534-25.887-27.8-44.128-27.8Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-12)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.545 149.86c-8.384 0-17.668-1.64-26.882-4.877-15.14-5.321-26.926-13.848-30.025-21.724-3.1-7.875-.287-22.148 7.166-36.36 8-15.254 19.553-27.028 30.905-31.495 25.245-9.938 53.866 2.52 63.8 27.765 9.935 25.244-2.521 53.865-27.764 63.8-4.916 1.934-10.796 2.892-17.2 2.892Zm-.875-96.517a47.593 47.593 0 0 0-17.463 3.324c-11.058 4.352-22.347 15.889-30.2 30.863-7.277 13.878-10.066 27.708-7.105 35.232 2.961 7.524 14.428 15.743 29.212 20.939 15.95 5.605 32.075 6.354 43.133 2.003 24.547-9.66 36.658-37.49 26.999-62.038-7.404-18.814-25.484-30.323-44.576-30.323Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-13)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.253 149.13c-9.261 0-19.8-2.311-30.092-6.758-14.443-6.238-25.387-15.384-27.879-23.298-2.493-7.914 1.234-21.68 9.496-35.07 8.866-14.372 20.97-25.097 32.378-28.69 12.291-3.87 25.35-2.723 36.776 3.23 11.427 5.952 19.85 15.998 23.721 28.287 3.871 12.29 2.725 25.35-3.228 36.777-5.954 11.427-16 19.85-28.289 23.721-3.834 1.208-8.196 1.8-12.883 1.8ZM136.7 54.394c-4.737 0-9.497.725-14.145 2.19-25.352 7.984-45.78 46.921-41.005 62.089 4.033 12.8 32.485 29.189 56.504 29.19 4.44 0 8.726-.56 12.681-1.805 11.95-3.764 21.719-11.955 27.508-23.066 5.788-11.111 6.903-23.812 3.14-35.761s-11.956-21.719-23.067-27.508c-6.79-3.537-14.174-5.33-21.616-5.33Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-14)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M137.456 148.374c-9.907 0-21.474-3.066-32.542-8.794-13.7-7.09-23.776-16.782-25.672-24.692s2.69-21.12 11.685-33.65c9.655-13.45 22.226-23.11 33.628-25.844 12.288-2.944 24.98-.93 35.747 5.674s18.32 17.005 21.265 29.288c6.08 25.357-9.604 50.932-34.962 57.011-2.82.676-5.895 1.007-9.15 1.007Zm-1.79-92.995c-3.598 0-7.219.425-10.806 1.285-11.106 2.663-23.395 12.134-32.872 25.335-8.784 12.236-13.288 25.026-11.477 32.584 1.812 7.558 11.626 16.915 25.003 23.838 14.432 7.47 29.681 10.338 40.787 7.677 11.944-2.864 22.058-10.208 28.479-20.678 6.422-10.47 8.38-22.816 5.518-34.76-2.864-11.944-10.207-22.059-20.678-28.48-7.327-4.492-15.572-6.8-23.954-6.8Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-15)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M136.238 147.563c-10.37 0-22.751-3.89-34.309-10.935-12.913-7.87-22.105-18.04-23.419-25.905-1.313-7.867 4.076-20.471 13.73-32.11 10.362-12.494 23.317-21.083 34.656-22.976 25.213-4.214 49.155 12.875 53.366 38.09 2.041 12.216-.797 24.494-7.993 34.573-7.194 10.08-17.883 16.755-30.097 18.795-1.883.314-3.87.468-5.934.468Zm5.723-1.731.105.631-.105-.631c11.877-1.984 22.271-8.474 29.267-18.275 6.995-9.802 9.756-21.74 7.773-33.618-4.097-24.52-27.376-41.138-51.894-37.04-25.198 4.209-49.851 38.537-47.335 53.613 2.519 15.075 36.987 39.53 62.189 35.32Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-16)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M134.683 146.672c-10.69 0-23.685-4.772-35.47-13.136-12.091-8.58-20.386-19.154-21.133-26.937s5.386-19.742 15.624-30.465c10.988-11.51 24.247-19.024 35.466-20.1 24.954-2.402 47.193 15.955 49.588 40.903 2.393 24.949-15.956 47.193-40.905 49.587-1.03.1-2.089.148-3.17.148Zm-1.138-89.594c-1.408 0-2.826.067-4.256.205-24.933 2.393-51.39 34.28-49.96 49.196.714 7.436 8.804 17.655 20.61 26.034 12.74 9.04 26.87 13.814 37.795 12.762 24.259-2.328 42.102-23.958 39.774-48.218-2.19-22.83-21.479-39.979-43.963-39.979Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-17)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M132.876 145.682c-10.908.001-24.334-5.696-36.109-15.355-11.238-9.22-18.628-20.127-18.826-27.79-.197-7.664 6.618-18.94 17.364-28.727 11.535-10.504 25.014-16.947 36.06-17.232 11.926-.31 23.207 4.037 31.837 12.232 8.632 8.196 13.555 19.263 13.862 31.162.308 11.9-4.036 23.206-12.232 31.837s-19.264 13.555-31.163 13.863c-.262.007-.528.01-.793.01Zm.761-1.24.016.615-.016-.615c11.57-.3 22.332-5.087 30.302-13.48s12.195-19.387 11.895-30.958c-.3-11.57-5.086-22.332-13.479-30.302-8.393-7.97-19.37-12.187-30.959-11.895-24.548.636-52.604 30.012-52.224 44.697.376 14.521 29.258 41.948 53.637 41.948.277 0 .552-.003.828-.01Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-18)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M131.579 144.595c-.645 0-1.292-.015-1.943-.043-10.822-.476-23.921-7.03-35.042-17.532-10.359-9.784-16.84-20.958-16.51-28.466.33-7.508 7.767-18.07 18.946-26.907 12-9.486 25.64-14.861 36.445-14.388 24.067 1.06 42.785 21.5 41.727 45.566-1.03 23.416-20.41 41.769-43.623 41.77Zm.45-86.161c-23.781 0-52.12 26.061-52.74 40.173-.632 14.388 26.35 43.682 50.4 44.74.632.027 1.264.041 1.89.041 22.574.002 41.416-17.845 42.418-40.616 1.028-23.402-17.173-43.278-40.574-44.308-.463-.02-.93-.03-1.395-.03Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-19)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M130.66 143.46c-1.615 0-3.248-.091-4.893-.28-10.551-1.205-22.914-8.51-33.071-19.542-9.463-10.278-15.037-21.649-14.201-28.97.837-7.32 8.832-17.14 20.37-25.019 12.384-8.456 26.067-12.787 36.627-11.578 11.367 1.299 21.548 6.946 28.667 15.902 7.12 8.956 10.325 20.148 9.026 31.515-2.494 21.82-21.064 37.972-42.524 37.972Zm-4.758-1.454c22.816 2.606 43.501-13.837 46.107-36.652 1.264-11.053-1.853-21.937-8.776-30.645-6.923-8.71-16.822-14.2-27.876-15.464-23.45-2.677-54.083 21.53-55.686 35.558-.8 6.994 4.654 17.998 13.895 28.034 9.97 10.828 22.058 17.994 32.336 19.169Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-20)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.761 142.285c-2.534 0-5.102-.231-7.682-.71-10.237-1.901-21.83-9.892-31.009-21.374-8.552-10.7-13.226-22.202-11.907-29.304 1.32-7.102 9.81-16.16 21.633-23.076 12.69-7.423 26.38-10.72 36.613-8.819 11.028 2.048 20.599 8.267 26.95 17.513 6.35 9.246 8.719 20.41 6.672 31.439-3.749 20.185-21.44 34.33-41.27 34.33Zm1.338-82.682c-21.922 0-48.488 19.076-50.796 31.506-2.527 13.61 19.236 45.101 41.987 49.326 22.14 4.11 43.49-10.555 47.6-32.693 1.992-10.723-.313-21.58-6.488-30.57s-15.48-15.039-26.204-17.03a33.446 33.446 0 0 0-6.099-.539Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-21)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
    </g>
    <g className='encompaasLogo_svg__cls-101'>
      <path
        d='M113.91 148.786c-16.484 0-31.83-5.71-42.803-16.083-10.974-10.373-16.785-24.53-16.361-39.862.932-33.702 31.624-63.639 68.416-66.734 24.8-2.092 50.018 12.689 64.075 25.976 10.523 9.948 16.43 20.316 16.206 28.446-.29 10.527-11.361 26.649-28.203 41.071-18.063 15.471-38.724 25.538-55.265 26.93-2.035.17-4.058.256-6.065.256Zm13.815-121.104c-1.481 0-2.95.06-4.415.183-35.925 3.022-65.892 32.191-66.8 65.025-.41 14.823 5.204 28.507 15.808 38.531 11.945 11.29 29.267 16.89 47.509 15.35 36.91-3.104 81.295-46.104 81.852-66.29.211-7.63-5.495-17.513-15.654-27.116-13.92-13.159-36.843-25.683-58.3-25.683Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-22)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M115.656 147.851c-17.29.001-33.28-6.431-44.151-17.831-10.247-10.744-15.23-24.973-14.033-40.067 2.616-32.946 33.895-60.68 69.727-61.822l.027.86-.027-.86c14.947-.466 39.78 6.668 60.482 28.377 9.913 10.393 15.232 20.97 14.592 29.018-.816 10.29-12.368 25.503-29.429 38.76-18.3 14.22-38.845 23.016-54.956 23.53-.745.024-1.49.035-2.232.035Zm12.97-118.022c-.467 0-.923.007-1.372.021C92.27 30.966 61.736 57.99 59.187 90.09c-1.158 14.599 3.658 28.358 13.563 38.743 11 11.534 27.41 17.814 45.084 17.264 35.94-1.146 81.157-40.97 82.724-60.706.6-7.561-4.547-17.656-14.122-27.695-19.687-20.642-43.089-27.866-57.81-27.866Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-23)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M116.875 147.02c-.326 0-.65-.003-.974-.01-17.505-.362-33.504-7.504-43.893-19.594-9.517-11.075-13.689-25.334-11.747-40.15 4.152-31.678 35.079-56.962 69.312-56.962.479 0 .957.005 1.437.015 14.4.298 37.944 8.473 56.902 30.534 9.286 10.806 14.015 21.56 12.973 29.505-1.314 10.03-13.286 24.33-30.5 36.433-18.086 12.717-37.997 20.23-53.51 20.23ZM129.57 31.983c-33.418 0-63.6 24.634-67.644 55.5-1.88 14.338 2.153 28.13 11.355 38.839 10.08 11.729 25.627 18.658 42.655 19.01.34.007.68.01 1.022.01 34.768.002 79.765-36.145 82.263-55.202.979-7.473-3.607-17.749-12.582-28.193-18.598-21.641-41.609-29.66-55.664-29.95a67.46 67.46 0 0 0-1.405-.014Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-24)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M117.54 146.247c-1.202 0-2.377-.042-3.526-.127-16.846-1.245-31.938-8.981-41.403-21.227-8.786-11.366-12.163-25.612-9.51-40.115 5.714-31.231 37.786-54.61 71.493-52.121 22.122 1.634 42.566 18.51 53.352 32.463 8.643 11.181 12.78 22.079 11.349 29.898-1.785 9.75-14.119 23.135-31.422 34.101-17.16 10.875-35.661 17.127-50.333 17.128Zm12.106-112.135c-31.082 0-59.638 22.02-64.933 50.961-2.569 14.04.697 27.825 9.194 38.818 9.316 12.053 23.604 19.367 40.229 20.596 33.786 2.509 80.124-31.053 83.547-49.764 1.328-7.254-2.797-17.946-11.033-28.601-16.928-21.9-38.687-30.835-52.177-31.832a65.567 65.567 0 0 0-4.827-.178Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-25)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M118.422 145.529c-2.149 0-4.217-.124-6.192-.377-16.409-2.1-30.232-10.163-38.925-22.701-8.052-11.617-10.654-25.809-7.323-39.962 7.127-30.282 39.415-51.53 71.97-47.36 21.182 2.713 40.087 20.103 49.839 34.172 7.985 11.519 11.53 22.525 9.723 30.196-2.223 9.453-14.862 21.924-32.196 31.772-16.092 9.142-33.117 14.26-46.896 14.26Zm11.302-109.325c-28.888 0-55.829 19.633-62.187 46.651-3.225 13.707-.71 27.446 7.082 38.685 8.43 12.162 21.86 19.984 37.814 22.027 32.639 4.18 79.257-26.291 83.527-44.436 1.676-7.125-1.868-17.936-9.483-28.92-9.564-13.798-28.068-30.85-48.728-33.497a63.189 63.189 0 0 0-8.025-.51Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-26)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M119.533 144.877c-3.175 0-6.186-.251-8.983-.765-15.71-2.882-28.662-11.414-36.467-24.024-7.32-11.826-9.164-25.923-5.192-39.692 8.448-29.282 40.831-48.43 72.19-42.673 20.232 3.711 37.629 21.535 46.379 35.671 7.313 11.816 10.266 22.895 8.102 30.397-2.636 9.139-15.523 20.7-32.83 29.453-14.888 7.528-30.376 11.633-43.2 11.633Zm10.26-106.618c-26.826 0-52.159 17.455-59.404 42.57-3.848 13.34-2.066 26.991 5.02 38.438 7.57 12.231 20.15 20.51 35.422 23.312 31.425 5.76 78.172-21.675 83.233-39.22 2.013-6.975-.952-17.87-7.93-29.144-8.582-13.865-25.604-31.34-45.333-34.96a61.031 61.031 0 0 0-11.007-.996Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-27)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M120.882 144.302c-4.29 0-8.298-.425-11.91-1.294-15.002-3.607-27.088-12.557-34.034-25.202-6.589-11.996-7.697-25.957-3.12-39.31 9.679-28.234 42.056-45.314 72.166-38.074 19.276 4.635 35.198 22.816 42.977 36.977 6.532 11.893 8.959 23.293 6.49 30.495-3.02 8.811-16.103 19.469-33.328 27.15-13.555 6.045-27.452 9.258-39.241 9.258Zm8.972-104.025c-24.884 0-48.633 15.478-56.596 38.713-4.437 12.942-3.367 26.468 3.014 38.084 6.737 12.264 18.476 20.95 33.055 24.456 30.174 7.254 76.886-17.212 82.684-34.13 2.333-6.803-.054-17.744-6.383-29.268-7.63-13.89-23.206-31.712-42-36.231a58.914 58.914 0 0 0-13.774-1.624Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-28)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M122.474 143.815c-5.496 0-10.566-.648-14.976-1.968-14.287-4.28-25.52-13.6-31.633-26.244-5.86-12.124-6.256-25.909-1.113-38.815 10.819-27.149 43.073-42.212 71.91-33.574 11.45 3.43 28.701 15.466 39.643 38.103 5.849 12.097 7.677 23.494 4.891 30.486-3.377 8.471-16.602 18.233-33.693 24.87-12.106 4.7-24.366 7.142-35.029 7.142Zm7.431-101.556c-23.05 0-45.249 13.686-53.774 35.079-4.988 12.514-4.608 25.874 1.07 37.62 5.929 12.264 16.84 21.308 30.723 25.467 28.881 8.651 75.412-12.907 81.893-29.172 2.635-6.611.822-17.56-4.848-29.29-6.707-13.877-20.876-31.979-38.732-37.327a56.877 56.877 0 0 0-16.332-2.377Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-29)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M124.303 143.427c-6.793 0-12.996-.924-18.176-2.794-13.569-4.9-23.962-14.542-29.268-27.153-5.138-12.212-4.845-25.783.825-38.215 11.87-26.026 43.91-39.118 71.434-29.18 17.364 6.269 30.451 24.956 36.382 39.054 5.156 12.257 6.393 23.613 3.31 30.375-3.704 8.12-17.022 16.999-33.931 22.62-10.554 3.508-21.135 5.293-30.576 5.293Zm5.64-99.224c-21.321 0-42.004 12.065-50.942 31.663-5.5 12.06-5.786 25.218-.807 37.053 5.147 12.233 15.241 21.592 28.425 26.352 27.56 9.95 73.764-8.768 80.874-24.358 2.919-6.4 1.675-17.321-3.328-29.213-5.818-13.828-18.615-32.143-35.54-38.254-6.092-2.2-12.416-3.243-18.682-3.243Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-30)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.354 143.147c-8.177 0-15.584-1.257-21.494-3.773-12.851-5.468-22.42-15.39-26.945-27.94-4.421-12.257-3.466-25.578 2.688-37.508 12.831-24.874 44.569-36.046 70.75-24.905 10.752 4.577 25.12 17.449 33.198 39.85 4.46 12.369 5.118 23.64 1.758 30.153-4.004 7.76-17.368 15.77-34.05 20.406-8.916 2.479-17.79 3.717-25.905 3.717Zm3.616-97.035c-19.685 0-38.9 10.605-48.11 28.461-5.974 11.578-6.902 24.499-2.617 36.383 4.39 12.174 13.684 21.806 26.17 27.119 26.219 11.157 71.956-4.8 79.64-19.699 3.183-6.17 2.5-17.02-1.83-29.025C178.265 75.6 166.8 57.13 150.8 50.32c-6.712-2.856-13.802-4.209-20.83-4.209Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-31)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M128.59 142.986c-9.63 0-18.309-1.657-24.895-4.908-12.137-5.992-20.896-16.152-24.668-28.61-3.712-12.263-2.122-25.298 4.475-36.704 13.705-23.696 45.048-33.007 69.868-20.752 10.14 5.005 23.37 18.272 30.098 40.496 3.761 12.429 3.85 23.578.238 29.824-4.276 7.392-17.642 14.55-34.052 18.232-7.222 1.621-14.372 2.422-21.063 2.422Zm1.398-95.001c-18.138 0-35.936 9.291-45.293 25.469-6.404 11.072-7.95 23.72-4.349 35.614 3.66 12.09 12.168 21.953 23.958 27.774 6.886 3.4 15.336 4.837 24.122 4.838 22.92.001 48.154-9.78 54.086-20.038 3.426-5.922 3.29-16.664-.364-28.735-6.6-21.802-19.508-34.783-29.388-39.66-7.232-3.57-15.034-5.262-22.772-5.262Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-32)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M130.964 142.948c-11.126 0-21.14-2.128-28.332-6.2-11.426-6.468-19.395-16.827-22.437-29.17-3.015-12.226-.82-24.941 6.177-35.803 14.493-22.497 45.357-30.005 68.803-16.733 9.533 5.397 21.653 19.004 27.077 41.008 3.066 12.438 2.603 23.424-1.239 29.388-4.521 7.018-17.844 13.34-33.943 16.104-5.493.943-10.922 1.406-16.106 1.406Zm-.966-93.126c-16.675 0-33.11 8.112-42.495 22.681-6.795 10.548-8.927 22.89-6.003 34.753 2.954 11.98 10.693 22.038 21.794 28.322 23.466 13.284 67.91 2.603 76.589-10.869 3.645-5.658 4.043-16.252 1.063-28.338-5.321-21.588-17.144-34.9-26.434-40.159-7.65-4.33-16.113-6.39-24.514-6.39Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-33)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M133.406 143.036c-12.633 0-24.026-2.681-31.738-7.645-10.722-6.9-17.915-17.421-20.255-29.625-2.33-12.148.437-24.512 7.79-34.812C104.4 49.67 134.712 43.906 156.77 58.102c13.63 8.772 21.408 27.132 24.14 41.389 2.378 12.397 1.382 23.182-2.664 28.849-4.74 6.64-17.98 12.145-33.73 14.026-3.766.45-7.489.67-11.109.67Zm-3.404-91.413c-15.292 0-30.422 7.056-39.73 20.093-7.143 10.006-9.832 22.01-7.571 33.804 2.271 11.847 9.26 22.064 19.676 28.768 22.076 14.208 65.704 6.032 74.8-6.71 3.841-5.38 4.755-15.788 2.445-27.84C175.53 78.4 164.768 64.81 156.06 59.205c-7.967-5.128-17.042-7.582-26.058-7.582Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-34)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M135.839 143.25c-14.115 0-26.91-3.323-35.038-9.236-10.025-7.292-16.46-17.94-18.123-29.98-1.66-12.033 1.648-24.015 9.314-33.738 15.817-20.062 45.502-24.152 66.169-9.116 12.735 9.263 19.347 27.59 21.287 41.65 1.698 12.306.19 22.852-4.034 28.21-4.935 6.257-18.053 10.969-33.42 12.002-2.072.139-4.128.208-6.155.208Zm-5.836-89.861c-13.987 0-27.87 6.112-37.005 17.699-7.45 9.448-10.664 21.086-9.051 32.77 1.613 11.693 7.866 22.035 17.606 29.12 20.677 15.042 63.39 9.276 72.855-2.73 4.014-5.09 5.424-15.275 3.772-27.242-2.905-21.048-12.634-34.872-20.771-40.791-8.183-5.952-17.82-8.826-27.406-8.826Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-35)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.182 143.584c-15.535 0-29.72-4.061-38.15-10.963-9.338-7.645-15.035-18.384-16.043-30.241-1.01-11.877 2.805-23.45 10.74-32.586 16.357-18.832 45.348-21.311 64.625-5.53 11.857 9.707 17.338 27.958 18.516 41.802 1.033 12.162-.962 22.432-5.338 27.471-5.103 5.874-18.063 9.814-33.017 10.038-.445.006-.89.01-1.333.01Zm-8.18-88.464c-12.754 0-25.452 5.271-34.33 15.493-7.713 8.88-11.42 20.123-10.439 31.66.979 11.519 6.516 21.953 15.59 29.382 19.281 15.784 60.98 12.331 70.766 1.064 4.16-4.79 6.043-14.714 5.037-26.547-1.156-13.59-6.508-31.482-18.063-40.942-8.297-6.792-18.447-10.11-28.56-10.11Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-36)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M140.293 144.03c-1.059 0-2.128-.02-3.208-.06-15.578-.59-29.682-5.357-37.729-12.752-8.66-7.96-13.638-18.761-14.015-30.414-.38-11.683 3.906-22.82 12.066-31.36 16.82-17.602 45.057-18.54 62.947-2.098 10.994 10.104 15.383 28.241 15.825 41.846.39 11.971-2.067 21.93-6.569 26.643-4.855 5.081-16.155 8.195-29.317 8.195Zm-10.29-87.212c-11.591 0-23.168 4.523-31.715 13.468-7.933 8.3-12.099 19.125-11.73 30.478.368 11.324 5.205 21.82 13.622 29.557 17.888 16.44 58.489 15.2 68.55 4.672 4.28-4.48 6.61-14.11 6.232-25.761-.434-13.357-4.717-31.14-15.432-40.99-8.314-7.641-18.927-11.424-29.526-11.424Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-37)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M141.928 144.565c-2.34 0-4.756-.11-7.22-.335-15.075-1.376-28.508-6.767-35.935-14.421-7.993-8.238-12.27-19.07-12.042-30.5.228-11.451 4.948-22.13 13.291-30.07 17.2-16.372 44.63-15.846 61.145 1.174 10.148 10.46 13.48 28.448 13.215 41.794-.234 11.73-3.12 21.348-7.722 25.727-4.425 4.211-13.73 6.63-24.732 6.63Zm-11.922-86.082c-10.498 0-21.011 3.856-29.165 11.615-8.11 7.72-12.7 18.103-12.922 29.236-.221 11.11 3.936 21.639 11.708 29.648 16.506 17.012 55.925 17.882 66.215 8.092 4.376-4.166 7.124-13.47 7.351-24.89.262-13.107-2.985-30.746-12.88-40.943-8.234-8.487-19.262-12.758-30.307-12.758Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-38)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M143.026 145.16c-3.379 0-6.96-.26-10.637-.796-14.546-2.121-27.297-8.089-34.109-15.963-7.338-8.484-10.934-19.317-10.123-30.504.81-11.187 5.928-21.39 14.411-28.727 17.512-15.147 44.082-13.224 59.23 4.288 6.812 7.875 10.88 21.353 10.884 36.054.003 13.697-3.524 25.6-8.988 30.326-3.981 3.445-11.589 5.322-20.668 5.322Zm-13.015-85.045c-9.468 0-18.978 3.266-26.685 9.932-8.25 7.135-13.226 17.055-14.013 27.934s2.708 21.412 9.844 29.66c15.138 17.503 53.309 20.378 63.78 11.32 5.218-4.514 8.59-16.074 8.586-29.448-.004-14.431-3.967-27.626-10.601-35.296-8.063-9.322-19.456-14.102-30.91-14.102Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-39)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
    </g>
    <g className='encompaasLogo_svg__cls-101'>
      <path
        d='M126.677 178.552c-21.021 0-42.761-10.438-56.995-29.538-14.145-18.983-16.904-49.255-13.363-70.835 2.377-14.483 7.466-25.366 13.963-29.86 8.595-5.943 27.436-5.923 48.008.055 22.05 6.407 41.162 18.214 51.125 31.584 11.384 15.277 16.265 34.07 13.395 51.564-2.51 15.292-10.698 28.422-23.057 36.97-9.845 6.809-21.35 10.06-33.076 10.06Zm-55.58-30.592c21.612 29.001 60.932 37.56 87.652 19.081 11.963-8.274 19.89-20.99 22.32-35.805 2.794-17.023-1.97-35.329-13.068-50.224-22.206-29.8-80.286-42.605-96.715-31.243-6.106 4.224-10.927 14.683-13.227 28.695-3.48 21.212-.8 50.927 13.037 69.496Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-40)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.386 175.844c-22.275 0-45.14-12.242-58.47-33.622-12.142-19.476-12.293-48.902-7.057-69.424 3.553-13.928 9.475-24.106 16.246-27.927 8.898-5.02 27.298-3.403 46.873 4.116 20.988 8.062 38.644 21.1 47.233 34.874 9.782 15.69 12.959 34.234 8.713 50.874-3.737 14.644-12.869 26.7-25.714 33.947-8.613 4.858-18.163 7.162-27.824 7.162Zm-57.01-34.533c18.63 29.882 56.308 41.487 83.988 25.872 12.433-7.014 21.273-18.689 24.893-32.874 4.13-16.187 1.029-34.243-8.507-49.538-19.143-30.703-74.782-48.004-91.8-38.4-6.363 3.59-11.985 13.377-15.424 26.852-5.145 20.168-5.023 49.043 6.85 68.088Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-41)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.11 173.19c-23.47 0-47.27-14.178-59.339-37.688-10.138-19.754-7.838-48.12-1.06-67.47 4.667-13.324 11.356-22.77 18.352-25.917 9.118-4.1 26.944-.98 45.418 7.947 19.808 9.573 35.939 23.676 43.15 37.726 8.182 15.941 9.721 34.086 4.222 49.782-4.88 13.93-14.85 24.878-28.073 30.826-7.231 3.253-14.935 4.793-22.67 4.793ZM94.005 42.018c-3.652 0-6.8.524-9.252 1.627-6.58 2.96-12.942 12.05-17.457 24.94-6.66 19.012-8.943 46.839.97 66.15 11.773 22.94 34.973 36.778 57.826 36.78 7.51 0 14.98-1.494 21.999-4.651 12.8-5.758 22.452-16.359 27.178-29.85 5.347-15.264 3.841-32.926-4.132-48.46-13.814-26.915-54.808-46.536-77.132-46.536Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-42)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.864 170.59c-24.627.002-49.163-16.233-59.638-41.684-5.449-13.244-7.517-38.224 4.587-65.039 5.718-12.668 13.11-21.353 20.28-23.829 9.252-3.193 26.393 1.331 43.671 11.529 18.528 10.935 33.077 25.94 38.92 40.14 6.596 16.034 6.581 33.646-.043 48.32-5.94 13.16-16.643 22.974-30.138 27.633-5.753 1.986-11.7 2.93-17.639 2.93ZM97.957 40.667c-2.395 0-4.527.297-6.328.92-6.746 2.328-13.79 10.695-19.324 22.954-8.017 17.763-12.538 44.361-4.563 63.742 12.674 30.803 46.423 47.772 75.226 37.829 13.064-4.511 23.427-14.013 29.18-26.758 6.44-14.266 6.446-31.405.02-47.024-11.698-28.43-53.076-51.663-74.211-51.663Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-43)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.668 168.048c-25.77 0-50.83-18.397-59.41-45.569-4.159-13.17-4.076-37.357 9.847-62.186 6.708-11.963 14.738-19.861 22.031-21.668 9.304-2.31 25.657 3.52 41.659 14.844 17.16 12.144 30.088 27.89 34.58 42.118 5.047 15.978 3.568 32.94-4.055 46.535-6.916 12.336-18.248 20.994-31.908 24.379a52.937 52.937 0 0 1-12.744 1.547Zm-57.887-46.05c9.747 30.865 41.26 50.134 70.246 42.952 13.228-3.278 24.201-11.662 30.9-23.61 7.408-13.212 8.838-29.713 3.925-45.271-10.014-31.715-56.511-60.312-74.33-55.893-6.868 1.702-14.53 9.319-21.023 20.899-13.672 24.382-13.78 48.058-9.718 60.923Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-44)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.542 165.565c-26.917 0-52.28-20.663-58.705-49.3-2.911-12.979-.809-36.212 14.697-58.976 7.635-11.207 16.236-18.29 23.6-19.435 9.29-1.44 24.752 5.578 39.41 17.88 15.724 13.198 27.006 29.522 30.178 43.666 3.541 15.785.699 31.993-7.8 44.468-7.808 11.463-19.663 18.95-33.382 21.081a52.112 52.112 0 0 1-7.998.616Zm-57.184-49.64c6.882 30.675 36.014 51.978 64.942 47.483 13.288-2.064 24.771-9.315 32.335-20.418 8.255-12.119 11.013-27.883 7.566-43.25-7.07-31.52-50.06-63.116-67.826-60.345-6.838 1.062-15.269 8.08-22.552 18.772-15.225 22.35-17.308 45.082-14.465 57.757Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-45)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.516 163.148c-28.101 0-53.54-23.014-57.583-52.845-2.75-20.297 8.581-42.567 19.121-55.47 8.495-10.398 17.599-16.643 24.98-17.132 9.19-.612 23.692 7.487 36.957 20.627 14.23 14.096 23.852 30.84 25.743 44.79 2.127 15.695-1.87 30.667-11.256 42.157-8.614 10.546-20.89 16.854-34.563 17.76-1.136.076-2.27.113-3.399.113ZM113.033 39.19c-.306 0-.605.01-.898.029-6.86.455-15.795 6.651-23.902 16.576-10.348 12.667-21.477 34.49-18.792 54.304 4.099 30.251 30.741 53.312 59.372 51.419 13.25-.879 25.142-6.989 33.488-17.205 9.116-11.16 12.996-25.717 10.925-40.991-4.141-30.565-42.183-64.132-60.193-64.132Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-46)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.62 160.805c-.352 0-.703-.004-1.058-.012-28.897-.602-53.593-25.798-55.049-56.164-.943-19.684 11.863-40.169 23.116-51.73 9.28-9.535 18.81-14.923 26.156-14.762 9.021.188 22.496 9.246 34.327 23.076 12.693 14.838 20.662 31.848 21.316 45.502.732 15.26-4.388 29.34-14.418 39.645-9.088 9.339-21.267 14.445-34.39 14.445Zm-54.624-56.248c1.42 29.604 25.464 54.166 53.598 54.753 13.122.26 25.31-4.694 34.353-13.984 9.74-10.008 14.711-23.695 13.999-38.54-1.458-30.418-36.894-66.805-54.192-67.166a12.13 12.13 0 0 0-.268-.002c-6.807 0-16.047 5.329-24.794 14.316-11.045 11.348-23.615 31.416-22.696 50.623Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-47)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.806 158.542c-1.754 0-3.523-.094-5.301-.285-28.171-3.029-50.136-29.489-48.963-58.984l.723.03-.723-.03c.754-18.943 14.842-37.592 26.678-47.806 9.988-8.62 19.884-13.117 27.12-12.337 8.794.945 21.177 10.845 31.55 25.222 11.13 15.426 17.46 32.554 16.933 45.817-.586 14.726-6.725 27.855-17.285 36.97-8.627 7.444-19.382 11.403-30.732 11.403Zm-2.088-118.049c-6.65 0-15.68 4.414-24.552 12.07C87.55 62.588 73.723 80.85 72.989 99.33c-1.144 28.75 20.241 54.538 47.67 57.486 12.872 1.387 25.278-2.443 34.933-10.775 10.253-8.849 16.214-21.609 16.784-35.93 1.175-29.541-30.326-67.73-47.19-69.543-.475-.05-.965-.076-1.468-.076Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-48)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.042 156.365c-3.073 0-6.176-.3-9.283-.905-27.253-5.313-46.442-32.767-42.778-61.196 2.333-18.097 17.52-34.878 29.817-43.751 10.453-7.542 20.862-11.228 27.858-9.869 8.506 1.659 19.754 12.282 28.656 27.064 9.554 15.863 14.273 32.967 12.624 45.752-1.82 14.104-8.87 26.237-19.855 34.162-7.974 5.753-17.335 8.742-27.04 8.743Zm2.51-114.592c-6.465 0-15.144 3.548-23.927 9.886-12.068 8.707-26.968 25.137-29.243 42.786-3.574 27.706 15.109 54.455 41.646 59.628 12.538 2.445 25.049-.253 35.227-7.596 10.665-7.695 17.512-19.485 19.28-33.198 3.672-28.47-23.833-68.068-40.149-71.248a14.834 14.834 0 0 0-2.834-.258Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-49)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.335 154.273c-4.317 0-8.68-.607-12.995-1.835-26.165-7.444-42.561-35.623-36.55-62.813 2.37-10.727 12.32-27.537 32.55-39.61 10.97-6.546 21.569-9.303 28.355-7.37 8.165 2.322 18.242 13.548 25.674 28.599 7.975 16.152 11.123 33.096 8.42 45.325-2.964 13.408-10.824 24.509-22.132 31.256-7.155 4.27-15.153 6.448-23.322 6.448Zm6.563-110.864c-6.224 0-14.394 2.743-22.851 7.79-12.409 7.404-28.212 21.989-31.912 38.724-5.856 26.49 10.106 53.94 35.582 61.19 12.112 3.445 24.626 1.859 35.234-4.471 10.978-6.551 18.611-17.337 21.492-30.371 6.019-27.222-17.46-67.845-33.125-72.301-1.323-.377-2.807-.561-4.42-.561Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-50)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.697 152.27c-5.507 0-11.058-1.011-16.437-3.044-24.92-9.415-38.525-38.058-30.33-63.85 3.363-10.584 14.294-25.446 34.89-35.42 11.383-5.511 22.075-7.332 28.602-4.867 7.775 2.937 16.658 14.648 22.63 29.834 6.41 16.299 8.036 32.955 4.35 44.555-4.02 12.65-12.586 22.694-24.121 28.28a44.804 44.804 0 0 1-19.584 4.512Zm9.956-106.926c-5.892 0-13.376 2.01-21.248 5.822-16.652 8.064-30.074 21.652-34.193 34.617-7.983 25.123 5.261 53.02 29.522 62.185 11.604 4.384 24.019 3.88 34.961-1.42 11.2-5.423 19.52-15.181 23.426-27.477 8.203-25.816-11.257-67.089-26.174-72.724-1.783-.674-3.908-1.003-6.294-1.003Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-51)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.142 150.36c-6.66 0-13.33-1.506-19.616-4.501-23.534-11.215-34.375-40.073-24.165-64.329 4.162-9.889 16.03-23.281 36.853-31.21 11.69-4.453 22.377-5.342 28.591-2.382 7.342 3.498 15.017 15.577 19.553 30.77 4.868 16.31 5.033 32.559.442 43.468-4.984 11.842-14.159 20.812-25.832 25.258a44.389 44.389 0 0 1-15.826 2.927ZM139.69 47.514c-5.413 0-12.01 1.366-19.01 4.032-20.436 7.783-32.053 20.855-36.11 30.494-9.941 23.62.61 51.718 23.52 62.635 11.008 5.246 23.228 5.792 34.41 1.534 11.337-4.317 20.247-13.032 25.09-24.54 10.217-24.273-5.262-65.834-19.349-72.546-2.264-1.078-5.178-1.61-8.55-1.61Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-52)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.68 148.548c-7.792 0-15.518-2.087-22.536-6.177-22.028-12.839-30.15-41.67-18.103-64.27 7.45-13.978 24.75-23.118 38.457-27.01 11.88-3.375 22.468-3.354 28.322.058 6.871 4.005 13.335 16.337 16.467 31.417 3.361 16.188 2.134 31.92-3.283 42.084-5.859 10.992-15.544 18.88-27.274 22.21a44.072 44.072 0 0 1-12.05 1.688Zm14.178-98.706c-4.716 0-10.193.827-16.01 2.48-20.52 5.826-32.918 17.448-37.678 26.381-11.727 22-3.823 50.066 17.619 62.562 10.343 6.029 22.273 7.58 33.592 4.365 11.391-3.236 20.8-10.9 26.494-21.581 12.05-22.61.483-64.111-12.7-71.795-2.76-1.608-6.667-2.412-11.317-2.412Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-53)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M128.325 146.838c-8.916 0-17.645-2.751-25.208-8.041-20.419-14.283-25.888-42.86-12.19-63.7 8.44-12.844 26.055-20.222 39.726-22.844 11.954-2.295 22.34-1.386 27.79 2.426 6.37 4.455 11.629 16.93 13.399 31.782 1.9 15.943-.643 31.057-6.804 40.43-6.644 10.11-16.748 16.913-28.452 19.158a43.836 43.836 0 0 1-8.261.789Zm14.66-94.591c-3.704 0-7.79.406-12.097 1.232-13.42 2.574-30.691 9.785-38.919 22.303-13.329 20.282-8.007 48.092 11.863 61.991 9.614 6.725 21.16 9.228 32.518 7.05 11.37-2.181 21.188-8.792 27.645-18.617 13.7-20.844 5.95-61.958-6.268-70.504-3.26-2.28-8.447-3.455-14.743-3.455Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-54)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.089 145.238c-10.052 0-19.73-3.499-27.647-10.07-18.724-15.544-21.624-43.649-6.464-62.65h.001c9.323-11.684 27.122-17.333 40.672-18.723 11.901-1.22 21.996.531 27.004 4.689 5.842 4.848 9.913 17.361 10.372 31.877.494 15.582-3.282 29.986-10.1 38.532-7.341 9.202-17.775 14.925-29.378 16.116-1.492.152-2.98.229-4.46.229ZM95.93 73.278h.001c-14.75 18.487-11.928 45.83 6.288 60.952 8.829 7.328 19.913 10.728 31.206 9.567 11.275-1.157 21.415-6.72 28.55-15.664 15.159-18.999 11.103-59.414-.097-68.712-4.762-3.952-14.516-5.603-26.103-4.415-13.303 1.365-30.757 6.884-39.845 18.272Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-55)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.98 143.754c-11.212 0-21.795-4.33-29.864-12.239-16.961-16.619-17.389-44.05-.959-61.147 10.1-10.508 27.96-14.466 41.307-14.666 11.735-.162 21.444 2.373 25.981 6.818 5.291 5.185 8.198 17.634 7.407 31.714-.848 15.114-5.767 28.727-13.157 36.416-7.955 8.276-18.632 12.928-30.062 13.1a41.57 41.57 0 0 1-.653.004Zm11.632-86.873c-.374 0-.75.003-1.13.009-13.108.196-30.622 4.057-40.468 14.3-15.984 16.63-15.565 43.31.934 59.477 7.845 7.687 18.134 11.899 29.032 11.899.212 0 .422-.002.635-.005 11.111-.166 21.49-4.689 29.223-12.734 16.425-17.091 15.919-56.518 5.775-66.458-4.172-4.088-13.092-6.488-24-6.488Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-56)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M130.92 142.374c-11.78 0-23.494-4.915-31.787-14.502-15.148-17.513-13.224-44.084 4.289-59.231 7.874-6.812 21.352-10.88 36.053-10.884h.019c13.69 0 25.584 3.528 30.307 8.988 4.726 5.464 6.502 17.751 4.526 31.305-2.121 14.547-8.089 27.298-15.964 34.11-7.924 6.855-17.706 10.214-27.442 10.214Zm8.574-83.457h-.019c-14.43.003-27.625 3.966-35.296 10.6-17.028 14.73-18.898 40.567-4.17 57.596 14.731 17.029 40.568 18.9 57.596 4.17 7.67-6.635 13.492-19.12 15.574-33.4 1.931-13.235.26-25.16-4.254-30.379-4.513-5.217-16.064-8.587-29.431-8.587Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-57)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
    </g>
    <g className='encompaasLogo_svg__cls-101'>
      <path
        d='M131.976 166.082c-3.448 0-6.512-.463-9.064-1.419-10.053-3.762-21.117-18.524-28.188-37.61-7.588-20.484-8.88-41.364-3.458-55.854 5.842-15.61 17.413-28.01 32.581-34.918 15.17-6.906 32.121-7.493 47.729-1.651 15.61 5.842 28.01 17.412 34.917 32.581 6.907 15.169 7.493 32.119 1.651 47.728-5.423 14.49-20.106 29.392-39.279 39.861-13.33 7.278-26.756 11.282-36.889 11.282Zm17.7-133.72c-8.558 0-17.092 1.834-25.115 5.487-14.749 6.716-26 18.774-31.681 33.953-5.283 14.114-3.989 34.545 3.46 54.653 6.904 18.636 17.57 33 27.175 36.595 9.608 3.595 27.08-.237 44.525-9.762 18.82-10.277 33.209-24.838 38.491-38.952 5.68-15.179 5.11-31.661-1.605-46.41-6.717-14.75-18.775-26.002-33.954-31.683-6.922-2.59-14.118-3.881-21.296-3.881Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-58)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.483 164.495c-4.582 0-8.562-.689-11.668-2.105-9.574-4.367-19.385-19.562-24.994-38.71-6.02-20.554-5.857-41.063.438-54.864 6.78-14.866 18.945-26.203 34.252-31.92 15.308-5.72 31.925-5.133 46.792 1.648s26.204 18.946 31.92 34.253c5.718 15.307 5.133 31.925-1.648 46.792-6.295 13.8-21.675 27.37-41.142 36.297-12.255 5.62-24.405 8.609-33.95 8.609Zm19.462-129.81c-7.024 0-14.065 1.261-20.843 3.793-14.884 5.56-26.714 16.583-33.306 31.04-13.99 30.67 5.372 82.966 23.72 91.335 18.349 8.373 70.533-11.295 84.522-41.965 6.594-14.456 7.163-30.615 1.603-45.5s-16.583-26.713-31.039-33.307c-7.874-3.591-16.254-5.396-24.657-5.396Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-59)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.712 162.791c-5.97 0-11.053-.978-14.725-2.973-9.064-4.926-17.621-20.457-21.797-39.569-4.482-20.512-2.92-40.56 4.18-53.626 7.648-14.076 20.32-24.331 35.682-28.876 15.36-4.545 31.573-2.836 45.65 4.812 29.057 15.79 39.851 52.275 24.063 81.333-7.1 13.066-23.069 25.285-42.718 32.685-10.893 4.102-21.568 6.214-30.335 6.214Zm20.435-125.88a58.54 58.54 0 0 0-16.625 2.424c-14.937 4.42-27.26 14.391-34.697 28.079-15.78 29.04-.42 81.51 16.953 90.95 17.375 9.44 69.754-6.224 85.532-35.263l.728.395-.728-.395c15.353-28.255 4.856-63.733-23.4-79.086-8.666-4.71-18.167-7.104-27.763-7.104Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-60)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.763 161.026c-7.652 0-14.08-1.35-18.322-4.053-8.53-5.438-15.835-21.213-18.612-40.19-2.98-20.368-.081-39.867 7.756-52.16 17.427-27.338 53.85-35.402 81.19-17.973 27.337 17.43 35.4 53.85 17.97 81.19-7.837 12.293-24.291 23.15-44.014 29.044-9.24 2.76-18.228 4.142-25.968 4.142Zm20.494-121.955c-18.898 0-37.414 9.342-48.303 26.424-7.634 11.975-10.445 31.06-7.519 51.053 2.712 18.53 9.73 33.86 17.88 39.055 16.344 10.42 68.644-1.316 86.062-28.637 16.947-26.583 9.108-61.999-17.476-78.947a56.84 56.84 0 0 0-30.644-8.948Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-61)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.717 159.262c-9.665 0-17.73-1.832-22.527-5.384-7.97-5.9-14.035-21.828-15.454-40.578-1.522-20.123 2.646-38.995 11.151-50.483 18.917-25.546 55.087-30.938 80.633-12.024 25.545 18.915 30.939 55.087 12.025 80.632-8.506 11.487-25.341 20.981-45.034 25.396-7.305 1.638-14.4 2.44-20.794 2.44Zm19.57-118.174c-17.176 0-34.138 7.844-45.12 22.677-8.285 11.188-12.34 29.662-10.844 49.415 1.386 18.309 7.2 33.781 14.814 39.419 15.273 11.309 67.224 3.409 86.128-22.121 18.393-24.84 13.148-60.013-11.692-78.406a55.765 55.765 0 0 0-33.286-10.984Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-62)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.579 157.56c-11.99 0-22.026-2.461-27.335-6.998-7.39-6.317-12.234-22.309-12.34-40.743-.112-19.785 5.256-37.957 14.36-48.61 9.807-11.476 23.496-18.445 38.546-19.625 15.051-1.176 29.658 3.573 41.134 13.38C193.42 64.77 200.39 78.46 201.57 93.51c1.18 15.048-3.573 29.658-13.38 41.133-9.103 10.653-26.217 18.787-45.778 21.758-5.133.78-10.133 1.158-14.832 1.158Zm17.736-114.592c-1.456 0-2.916.057-4.383.172-14.633 1.147-27.945 7.924-37.482 19.083-8.868 10.376-14.096 28.166-13.985 47.587.102 18 4.731 33.531 11.793 39.565 7.061 6.036 23.127 8.186 40.919 5.485 19.2-2.917 35.958-10.853 44.827-21.23 9.537-11.158 14.157-25.364 13.01-39.997-1.147-14.635-7.924-27.946-19.084-37.483-10.04-8.58-22.548-13.182-35.615-13.182Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-63)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.252 155.98c-14.558 0-26.897-3.284-32.64-8.932-6.797-6.682-10.44-22.655-9.282-40.69C88.572 87 95.065 69.595 104.698 59.8c21.419-21.785 56.57-22.08 78.354-.66 21.785 21.421 22.08 56.571.659 78.355-9.632 9.796-26.926 16.581-46.26 18.149-2.79.226-5.531.337-8.2.337Zm14.957-111.185c-13.95 0-27.89 5.368-38.421 16.077-9.382 9.541-15.712 26.582-16.932 45.584-1.13 17.611 2.334 33.117 8.827 39.501 13.025 12.808 63.53 12.237 84.938-9.534 20.829-21.182 20.542-55.362-.64-76.192-10.474-10.299-24.129-15.436-37.772-15.436Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-64)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M131.511 154.57c-17.238 0-32.157-4.357-38.212-11.207-6.19-7-8.662-22.87-6.295-40.43 2.539-18.847 10.079-35.425 20.17-44.347 22.439-19.84 56.837-17.724 76.676 4.715 19.841 22.44 17.726 56.836-4.714 76.677-10.09 8.921-27.467 14.374-46.484 14.585-.38.004-.763.006-1.14.006Zm11.614-108.085c-12.44 0-24.927 4.356-34.957 13.225-9.83 8.69-17.186 24.923-19.678 43.424-2.31 17.146.019 32.548 5.932 39.236 5.913 6.688 20.923 10.894 38.213 10.693 18.667-.207 35.68-5.519 45.507-14.21 21.82-19.292 23.877-52.739 4.585-74.559-10.424-11.789-24.981-17.81-39.602-17.81Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-65)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M133.894 153.35a99.98 99.98 0 0 1-5.861-.172c-17.34-1.019-32.148-6.375-37.722-13.645-5.575-7.27-6.906-22.96-3.391-39.971 3.772-18.258 12.28-33.957 22.758-41.992 11.29-8.656 25.274-12.397 39.375-10.537 14.103 1.861 26.637 9.103 35.292 20.392 8.658 11.288 12.4 25.271 10.539 39.374s-9.104 26.637-20.392 35.293c-9.37 7.185-24.248 11.259-40.598 11.259Zm8.085-105.313c-11.322 0-22.27 3.695-31.406 10.7-10.206 7.827-18.51 23.2-22.214 41.123-3.432 16.611-2.208 31.833 3.118 38.779s19.71 12.076 36.642 13.071c18.264 1.07 35.27-2.958 45.478-10.785l.448.584-.448-.584c10.977-8.417 18.019-20.605 19.829-34.318 1.81-13.714-1.83-27.311-10.247-38.288s-20.605-18.018-34.318-19.828a52.67 52.67 0 0 0-6.882-.454Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-66)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M135.895 152.308c-4.005 0-8.12-.263-12.285-.8-16.89-2.183-31.005-8.434-35.96-15.926-4.954-7.49-5.183-22.926-.583-39.323 4.939-17.599 14.333-32.37 25.131-39.512 11.632-7.694 25.561-10.397 39.23-7.612 13.665 2.785 25.427 10.724 33.122 22.357 15.881 24.011 9.268 56.468-14.745 72.35-8.317 5.502-20.477 8.466-33.91 8.466Zm5.029-102.768a50.474 50.474 0 0 0-27.931 8.41c-10.518 6.956-19.69 21.423-24.537 38.698-4.493 16.012-4.337 30.982.397 38.14 9.494 14.355 56.157 23.725 80.157 7.852l.398.602-.398-.602c11.311-7.481 19.032-18.92 21.74-32.208 2.707-13.287.078-26.834-7.404-38.146-9.753-14.747-25.94-22.746-42.422-22.746Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-67)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M137.376 151.407c-5.778 0-11.847-.604-17.977-1.836-16.369-3.29-29.746-10.368-34.08-18.034-4.334-7.665-3.502-22.777 2.12-38.499 6.032-16.874 16.23-30.677 27.28-36.923 11.902-6.729 25.71-8.42 38.886-4.763 13.174 3.659 24.136 12.228 30.864 24.13 6.73 11.903 8.421 25.713 4.763 38.887-3.658 13.175-12.228 24.136-24.13 30.865l-.348-.615.347.615c-7.193 4.067-16.947 6.173-27.725 6.173Zm2.458-100.53c-8.474 0-16.843 2.184-24.42 6.468-10.762 6.084-20.723 19.605-26.645 36.17-5.489 15.351-6.36 30.003-2.22 37.327 8.306 14.69 53.301 27.045 77.857 13.162 11.574-6.544 19.907-17.202 23.464-30.013 3.557-12.81 1.913-26.24-4.631-37.813-6.543-11.574-17.201-19.907-30.012-23.464a50.024 50.024 0 0 0-13.393-1.836Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-68)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.263 150.606c-7.232 0-15.028-1.068-22.85-3.217-15.784-4.337-28.383-12.174-32.097-19.968-3.714-7.793-1.867-22.515 4.705-37.506 7.055-16.09 17.972-28.893 29.205-34.247 24.98-11.905 54.987-1.268 66.893 23.712 11.904 24.98 1.267 54.988-23.712 66.893-6.046 2.882-13.712 4.333-22.144 4.333Zm.478-98.43a48.51 48.51 0 0 0-20.919 4.743c-24.964 11.897-40.373 54.971-33.256 69.907 7.118 14.933 50.277 30.093 75.245 18.196 24.29-11.576 34.633-40.756 23.058-65.046-8.358-17.534-25.887-27.8-44.128-27.8Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-69)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.545 149.86c-8.384 0-17.668-1.64-26.882-4.877-15.14-5.321-26.926-13.848-30.025-21.724-3.1-7.875-.287-22.148 7.166-36.36 8-15.254 19.553-27.028 30.905-31.495 25.245-9.938 53.866 2.52 63.8 27.765 9.935 25.244-2.521 53.865-27.764 63.8-4.916 1.934-10.796 2.892-17.2 2.892Zm-.875-96.517a47.593 47.593 0 0 0-17.463 3.324c-11.058 4.352-22.347 15.889-30.2 30.863-7.277 13.878-10.066 27.708-7.105 35.232 2.961 7.524 14.428 15.743 29.212 20.939 15.95 5.605 32.075 6.354 43.133 2.003 24.547-9.66 36.658-37.49 26.999-62.038-7.404-18.814-25.484-30.323-44.576-30.323Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-70)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.253 149.13c-9.261 0-19.8-2.311-30.092-6.758-14.443-6.238-25.387-15.384-27.879-23.298-2.493-7.914 1.234-21.68 9.496-35.07 8.866-14.372 20.97-25.097 32.378-28.69 12.291-3.87 25.35-2.723 36.776 3.23 11.427 5.952 19.85 15.998 23.721 28.287 3.871 12.29 2.725 25.35-3.228 36.777-5.954 11.427-16 19.85-28.289 23.721-3.834 1.208-8.196 1.8-12.883 1.8ZM136.7 54.394c-4.737 0-9.497.725-14.145 2.19-25.352 7.984-45.78 46.921-41.005 62.089 4.033 12.8 32.485 29.189 56.504 29.19 4.44 0 8.726-.56 12.681-1.805 11.95-3.764 21.719-11.955 27.508-23.066 5.788-11.111 6.903-23.812 3.14-35.761s-11.956-21.719-23.067-27.508c-6.79-3.537-14.174-5.33-21.616-5.33Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-71)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M137.456 148.374c-9.907 0-21.474-3.066-32.542-8.794-13.7-7.09-23.776-16.782-25.672-24.692s2.69-21.12 11.685-33.65c9.655-13.45 22.226-23.11 33.628-25.844 12.288-2.944 24.98-.93 35.747 5.674s18.32 17.005 21.265 29.288c6.08 25.357-9.604 50.932-34.962 57.011-2.82.676-5.895 1.007-9.15 1.007Zm-1.79-92.995c-3.598 0-7.219.425-10.806 1.285-11.106 2.663-23.395 12.134-32.872 25.335-8.784 12.236-13.288 25.026-11.477 32.584 1.812 7.558 11.626 16.915 25.003 23.838 14.432 7.47 29.681 10.338 40.787 7.677 11.944-2.864 22.058-10.208 28.479-20.678 6.422-10.47 8.38-22.816 5.518-34.76-2.864-11.944-10.207-22.059-20.678-28.48-7.327-4.492-15.572-6.8-23.954-6.8Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-72)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M136.238 147.563c-10.37 0-22.751-3.89-34.309-10.935-12.913-7.87-22.105-18.04-23.419-25.905-1.313-7.867 4.076-20.471 13.73-32.11 10.362-12.494 23.317-21.083 34.656-22.976 25.213-4.214 49.155 12.875 53.366 38.09 2.041 12.216-.797 24.494-7.993 34.573-7.194 10.08-17.883 16.755-30.097 18.795-1.883.314-3.87.468-5.934.468Zm5.723-1.731.105.631-.105-.631c11.877-1.984 22.271-8.474 29.267-18.275 6.995-9.802 9.756-21.74 7.773-33.618-4.097-24.52-27.376-41.138-51.894-37.04-25.198 4.209-49.851 38.537-47.335 53.613 2.519 15.075 36.987 39.53 62.189 35.32Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-73)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M134.683 146.672c-10.69 0-23.685-4.772-35.47-13.136-12.091-8.58-20.386-19.154-21.133-26.937s5.386-19.742 15.624-30.465c10.988-11.51 24.247-19.024 35.466-20.1 24.954-2.402 47.193 15.955 49.588 40.903 2.393 24.949-15.956 47.193-40.905 49.587-1.03.1-2.089.148-3.17.148Zm-1.138-89.594c-1.408 0-2.826.067-4.256.205-24.933 2.393-51.39 34.28-49.96 49.196.714 7.436 8.804 17.655 20.61 26.034 12.74 9.04 26.87 13.814 37.795 12.762 24.259-2.328 42.102-23.958 39.774-48.218-2.19-22.83-21.479-39.979-43.963-39.979Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-74)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M132.876 145.682c-10.908.001-24.334-5.696-36.109-15.355-11.238-9.22-18.628-20.127-18.826-27.79-.197-7.664 6.618-18.94 17.364-28.727 11.535-10.504 25.014-16.947 36.06-17.232 11.926-.31 23.207 4.037 31.837 12.232 8.632 8.196 13.555 19.263 13.862 31.162.308 11.9-4.036 23.206-12.232 31.837s-19.264 13.555-31.163 13.863c-.262.007-.528.01-.793.01Zm.761-1.24.016.615-.016-.615c11.57-.3 22.332-5.087 30.302-13.48s12.195-19.387 11.895-30.958c-.3-11.57-5.086-22.332-13.479-30.302-8.393-7.97-19.37-12.187-30.959-11.895-24.548.636-52.604 30.012-52.224 44.697.376 14.521 29.258 41.948 53.637 41.948.277 0 .552-.003.828-.01Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-75)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M131.579 144.595c-.645 0-1.292-.015-1.943-.043-10.822-.476-23.921-7.03-35.042-17.532-10.359-9.784-16.84-20.958-16.51-28.466.33-7.508 7.767-18.07 18.946-26.907 12-9.486 25.64-14.861 36.445-14.388 24.067 1.06 42.785 21.5 41.727 45.566-1.03 23.416-20.41 41.769-43.623 41.77Zm.45-86.161c-23.781 0-52.12 26.061-52.74 40.173-.632 14.388 26.35 43.682 50.4 44.74.632.027 1.264.041 1.89.041 22.574.002 41.416-17.845 42.418-40.616 1.028-23.402-17.173-43.278-40.574-44.308-.463-.02-.93-.03-1.395-.03Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-76)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M130.66 143.46c-1.615 0-3.248-.091-4.893-.28-10.551-1.205-22.914-8.51-33.071-19.542-9.463-10.278-15.037-21.649-14.201-28.97.837-7.32 8.832-17.14 20.37-25.019 12.384-8.456 26.067-12.787 36.627-11.578 11.367 1.299 21.548 6.946 28.667 15.902 7.12 8.956 10.325 20.148 9.026 31.515-2.494 21.82-21.064 37.972-42.524 37.972Zm-4.758-1.454c22.816 2.606 43.501-13.837 46.107-36.652 1.264-11.053-1.853-21.937-8.776-30.645-6.923-8.71-16.822-14.2-27.876-15.464-23.45-2.677-54.083 21.53-55.686 35.558-.8 6.994 4.654 17.998 13.895 28.034 9.97 10.828 22.058 17.994 32.336 19.169Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-77)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.761 142.285c-2.534 0-5.102-.231-7.682-.71-10.237-1.901-21.83-9.892-31.009-21.374-8.552-10.7-13.226-22.202-11.907-29.304 1.32-7.102 9.81-16.16 21.633-23.076 12.69-7.423 26.38-10.72 36.613-8.819 11.028 2.048 20.599 8.267 26.95 17.513 6.35 9.246 8.719 20.41 6.672 31.439-3.749 20.185-21.44 34.33-41.27 34.33Zm1.338-82.682c-21.922 0-48.488 19.076-50.796 31.506-2.527 13.61 19.236 45.101 41.987 49.326 22.14 4.11 43.49-10.555 47.6-32.693 1.992-10.723-.313-21.58-6.488-30.57s-15.48-15.039-26.204-17.03a33.446 33.446 0 0 0-6.099-.539Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-78)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
    </g>
    <g className='encompaasLogo_svg__cls-101'>
      <path
        d='M113.91 148.786c-16.484 0-31.83-5.71-42.803-16.083-10.974-10.373-16.785-24.53-16.361-39.862.932-33.702 31.624-63.639 68.416-66.734 24.8-2.092 50.018 12.689 64.075 25.976 10.523 9.948 16.43 20.316 16.206 28.446-.29 10.527-11.361 26.649-28.203 41.071-18.063 15.471-38.724 25.538-55.265 26.93-2.035.17-4.058.256-6.065.256Zm13.815-121.104c-1.481 0-2.95.06-4.415.183-35.925 3.022-65.892 32.191-66.8 65.025-.41 14.823 5.204 28.507 15.808 38.531 11.945 11.29 29.267 16.89 47.509 15.35 36.91-3.104 81.295-46.104 81.852-66.29.211-7.63-5.495-17.513-15.654-27.116-13.92-13.159-36.843-25.683-58.3-25.683Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-79)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M115.656 147.851c-17.29.001-33.28-6.431-44.151-17.831-10.247-10.744-15.23-24.973-14.033-40.067 2.616-32.946 33.895-60.68 69.727-61.822l.027.86-.027-.86c14.947-.466 39.78 6.668 60.482 28.377 9.913 10.393 15.232 20.97 14.592 29.018-.816 10.29-12.368 25.503-29.429 38.76-18.3 14.22-38.845 23.016-54.956 23.53-.745.024-1.49.035-2.232.035Zm12.97-118.022c-.467 0-.923.007-1.372.021C92.27 30.966 61.736 57.99 59.187 90.09c-1.158 14.599 3.658 28.358 13.563 38.743 11 11.534 27.41 17.814 45.084 17.264 35.94-1.146 81.157-40.97 82.724-60.706.6-7.561-4.547-17.656-14.122-27.695-19.687-20.642-43.089-27.866-57.81-27.866Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-80)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M116.875 147.02c-.326 0-.65-.003-.974-.01-17.505-.362-33.504-7.504-43.893-19.594-9.517-11.075-13.689-25.334-11.747-40.15 4.152-31.678 35.079-56.962 69.312-56.962.479 0 .957.005 1.437.015 14.4.298 37.944 8.473 56.902 30.534 9.286 10.806 14.015 21.56 12.973 29.505-1.314 10.03-13.286 24.33-30.5 36.433-18.086 12.717-37.997 20.23-53.51 20.23ZM129.57 31.983c-33.418 0-63.6 24.634-67.644 55.5-1.88 14.338 2.153 28.13 11.355 38.839 10.08 11.729 25.627 18.658 42.655 19.01.34.007.68.01 1.022.01 34.768.002 79.765-36.145 82.263-55.202.979-7.473-3.607-17.749-12.582-28.193-18.598-21.641-41.609-29.66-55.664-29.95a67.46 67.46 0 0 0-1.405-.014Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-81)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M117.54 146.247c-1.202 0-2.377-.042-3.526-.127-16.846-1.245-31.938-8.981-41.403-21.227-8.786-11.366-12.163-25.612-9.51-40.115 5.714-31.231 37.786-54.61 71.493-52.121 22.122 1.634 42.566 18.51 53.352 32.463 8.643 11.181 12.78 22.079 11.349 29.898-1.785 9.75-14.119 23.135-31.422 34.101-17.16 10.875-35.661 17.127-50.333 17.128Zm12.106-112.135c-31.082 0-59.638 22.02-64.933 50.961-2.569 14.04.697 27.825 9.194 38.818 9.316 12.053 23.604 19.367 40.229 20.596 33.786 2.509 80.124-31.053 83.547-49.764 1.328-7.254-2.797-17.946-11.033-28.601-16.928-21.9-38.687-30.835-52.177-31.832a65.567 65.567 0 0 0-4.827-.178Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-82)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M118.422 145.529c-2.149 0-4.217-.124-6.192-.377-16.409-2.1-30.232-10.163-38.925-22.701-8.052-11.617-10.654-25.809-7.323-39.962 7.127-30.282 39.415-51.53 71.97-47.36 21.182 2.713 40.087 20.103 49.839 34.172 7.985 11.519 11.53 22.525 9.723 30.196-2.223 9.453-14.862 21.924-32.196 31.772-16.092 9.142-33.117 14.26-46.896 14.26Zm11.302-109.325c-28.888 0-55.829 19.633-62.187 46.651-3.225 13.707-.71 27.446 7.082 38.685 8.43 12.162 21.86 19.984 37.814 22.027 32.639 4.18 79.257-26.291 83.527-44.436 1.676-7.125-1.868-17.936-9.483-28.92-9.564-13.798-28.068-30.85-48.728-33.497a63.189 63.189 0 0 0-8.025-.51Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-83)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M119.533 144.877c-3.175 0-6.186-.251-8.983-.765-15.71-2.882-28.662-11.414-36.467-24.024-7.32-11.826-9.164-25.923-5.192-39.692 8.448-29.282 40.831-48.43 72.19-42.673 20.232 3.711 37.629 21.535 46.379 35.671 7.313 11.816 10.266 22.895 8.102 30.397-2.636 9.139-15.523 20.7-32.83 29.453-14.888 7.528-30.376 11.633-43.2 11.633Zm10.26-106.618c-26.826 0-52.159 17.455-59.404 42.57-3.848 13.34-2.066 26.991 5.02 38.438 7.57 12.231 20.15 20.51 35.422 23.312 31.425 5.76 78.172-21.675 83.233-39.22 2.013-6.975-.952-17.87-7.93-29.144-8.582-13.865-25.604-31.34-45.333-34.96a61.031 61.031 0 0 0-11.007-.996Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-84)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M120.882 144.302c-4.29 0-8.298-.425-11.91-1.294-15.002-3.607-27.088-12.557-34.034-25.202-6.589-11.996-7.697-25.957-3.12-39.31 9.679-28.234 42.056-45.314 72.166-38.074 19.276 4.635 35.198 22.816 42.977 36.977 6.532 11.893 8.959 23.293 6.49 30.495-3.02 8.811-16.103 19.469-33.328 27.15-13.555 6.045-27.452 9.258-39.241 9.258Zm8.972-104.025c-24.884 0-48.633 15.478-56.596 38.713-4.437 12.942-3.367 26.468 3.014 38.084 6.737 12.264 18.476 20.95 33.055 24.456 30.174 7.254 76.886-17.212 82.684-34.13 2.333-6.803-.054-17.744-6.383-29.268-7.63-13.89-23.206-31.712-42-36.231a58.914 58.914 0 0 0-13.774-1.624Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-85)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M122.474 143.815c-5.496 0-10.566-.648-14.976-1.968-14.287-4.28-25.52-13.6-31.633-26.244-5.86-12.124-6.256-25.909-1.113-38.815 10.819-27.149 43.073-42.212 71.91-33.574 11.45 3.43 28.701 15.466 39.643 38.103 5.849 12.097 7.677 23.494 4.891 30.486-3.377 8.471-16.602 18.233-33.693 24.87-12.106 4.7-24.366 7.142-35.029 7.142Zm7.431-101.556c-23.05 0-45.249 13.686-53.774 35.079-4.988 12.514-4.608 25.874 1.07 37.62 5.929 12.264 16.84 21.308 30.723 25.467 28.881 8.651 75.412-12.907 81.893-29.172 2.635-6.611.822-17.56-4.848-29.29-6.707-13.877-20.876-31.979-38.732-37.327a56.877 56.877 0 0 0-16.332-2.377Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-86)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M124.303 143.427c-6.793 0-12.996-.924-18.176-2.794-13.569-4.9-23.962-14.542-29.268-27.153-5.138-12.212-4.845-25.783.825-38.215 11.87-26.026 43.91-39.118 71.434-29.18 17.364 6.269 30.451 24.956 36.382 39.054 5.156 12.257 6.393 23.613 3.31 30.375-3.704 8.12-17.022 16.999-33.931 22.62-10.554 3.508-21.135 5.293-30.576 5.293Zm5.64-99.224c-21.321 0-42.004 12.065-50.942 31.663-5.5 12.06-5.786 25.218-.807 37.053 5.147 12.233 15.241 21.592 28.425 26.352 27.56 9.95 73.764-8.768 80.874-24.358 2.919-6.4 1.675-17.321-3.328-29.213-5.818-13.828-18.615-32.143-35.54-38.254-6.092-2.2-12.416-3.243-18.682-3.243Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-87)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.354 143.147c-8.177 0-15.584-1.257-21.494-3.773-12.851-5.468-22.42-15.39-26.945-27.94-4.421-12.257-3.466-25.578 2.688-37.508 12.831-24.874 44.569-36.046 70.75-24.905 10.752 4.577 25.12 17.449 33.198 39.85 4.46 12.369 5.118 23.64 1.758 30.153-4.004 7.76-17.368 15.77-34.05 20.406-8.916 2.479-17.79 3.717-25.905 3.717Zm3.616-97.035c-19.685 0-38.9 10.605-48.11 28.461-5.974 11.578-6.902 24.499-2.617 36.383 4.39 12.174 13.684 21.806 26.17 27.119 26.219 11.157 71.956-4.8 79.64-19.699 3.183-6.17 2.5-17.02-1.83-29.025C178.265 75.6 166.8 57.13 150.8 50.32c-6.712-2.856-13.802-4.209-20.83-4.209Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-88)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M128.59 142.986c-9.63 0-18.309-1.657-24.895-4.908-12.137-5.992-20.896-16.152-24.668-28.61-3.712-12.263-2.122-25.298 4.475-36.704 13.705-23.696 45.048-33.007 69.868-20.752 10.14 5.005 23.37 18.272 30.098 40.496 3.761 12.429 3.85 23.578.238 29.824-4.276 7.392-17.642 14.55-34.052 18.232-7.222 1.621-14.372 2.422-21.063 2.422Zm1.398-95.001c-18.138 0-35.936 9.291-45.293 25.469-6.404 11.072-7.95 23.72-4.349 35.614 3.66 12.09 12.168 21.953 23.958 27.774 6.886 3.4 15.336 4.837 24.122 4.838 22.92.001 48.154-9.78 54.086-20.038 3.426-5.922 3.29-16.664-.364-28.735-6.6-21.802-19.508-34.783-29.388-39.66-7.232-3.57-15.034-5.262-22.772-5.262Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-89)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M130.964 142.948c-11.126 0-21.14-2.128-28.332-6.2-11.426-6.468-19.395-16.827-22.437-29.17-3.015-12.226-.82-24.941 6.177-35.803 14.493-22.497 45.357-30.005 68.803-16.733 9.533 5.397 21.653 19.004 27.077 41.008 3.066 12.438 2.603 23.424-1.239 29.388-4.521 7.018-17.844 13.34-33.943 16.104-5.493.943-10.922 1.406-16.106 1.406Zm-.966-93.126c-16.675 0-33.11 8.112-42.495 22.681-6.795 10.548-8.927 22.89-6.003 34.753 2.954 11.98 10.693 22.038 21.794 28.322 23.466 13.284 67.91 2.603 76.589-10.869 3.645-5.658 4.043-16.252 1.063-28.338-5.321-21.588-17.144-34.9-26.434-40.159-7.65-4.33-16.113-6.39-24.514-6.39Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-90)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M133.406 143.036c-12.633 0-24.026-2.681-31.738-7.645-10.722-6.9-17.915-17.421-20.255-29.625-2.33-12.148.437-24.512 7.79-34.812C104.4 49.67 134.712 43.906 156.77 58.102c13.63 8.772 21.408 27.132 24.14 41.389 2.378 12.397 1.382 23.182-2.664 28.849-4.74 6.64-17.98 12.145-33.73 14.026-3.766.45-7.489.67-11.109.67Zm-3.404-91.413c-15.292 0-30.422 7.056-39.73 20.093-7.143 10.006-9.832 22.01-7.571 33.804 2.271 11.847 9.26 22.064 19.676 28.768 22.076 14.208 65.704 6.032 74.8-6.71 3.841-5.38 4.755-15.788 2.445-27.84C175.53 78.4 164.768 64.81 156.06 59.205c-7.967-5.128-17.042-7.582-26.058-7.582Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-91)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M135.839 143.25c-14.115 0-26.91-3.323-35.038-9.236-10.025-7.292-16.46-17.94-18.123-29.98-1.66-12.033 1.648-24.015 9.314-33.738 15.817-20.062 45.502-24.152 66.169-9.116 12.735 9.263 19.347 27.59 21.287 41.65 1.698 12.306.19 22.852-4.034 28.21-4.935 6.257-18.053 10.969-33.42 12.002-2.072.139-4.128.208-6.155.208Zm-5.836-89.861c-13.987 0-27.87 6.112-37.005 17.699-7.45 9.448-10.664 21.086-9.051 32.77 1.613 11.693 7.866 22.035 17.606 29.12 20.677 15.042 63.39 9.276 72.855-2.73 4.014-5.09 5.424-15.275 3.772-27.242-2.905-21.048-12.634-34.872-20.771-40.791-8.183-5.952-17.82-8.826-27.406-8.826Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-92)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M138.182 143.584c-15.535 0-29.72-4.061-38.15-10.963-9.338-7.645-15.035-18.384-16.043-30.241-1.01-11.877 2.805-23.45 10.74-32.586 16.357-18.832 45.348-21.311 64.625-5.53 11.857 9.707 17.338 27.958 18.516 41.802 1.033 12.162-.962 22.432-5.338 27.471-5.103 5.874-18.063 9.814-33.017 10.038-.445.006-.89.01-1.333.01Zm-8.18-88.464c-12.754 0-25.452 5.271-34.33 15.493-7.713 8.88-11.42 20.123-10.439 31.66.979 11.519 6.516 21.953 15.59 29.382 19.281 15.784 60.98 12.331 70.766 1.064 4.16-4.79 6.043-14.714 5.037-26.547-1.156-13.59-6.508-31.482-18.063-40.942-8.297-6.792-18.447-10.11-28.56-10.11Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-93)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M140.293 144.03c-1.059 0-2.128-.02-3.208-.06-15.578-.59-29.682-5.357-37.729-12.752-8.66-7.96-13.638-18.761-14.015-30.414-.38-11.683 3.906-22.82 12.066-31.36 16.82-17.602 45.057-18.54 62.947-2.098 10.994 10.104 15.383 28.241 15.825 41.846.39 11.971-2.067 21.93-6.569 26.643-4.855 5.081-16.155 8.195-29.317 8.195Zm-10.29-87.212c-11.591 0-23.168 4.523-31.715 13.468-7.933 8.3-12.099 19.125-11.73 30.478.368 11.324 5.205 21.82 13.622 29.557 17.888 16.44 58.489 15.2 68.55 4.672 4.28-4.48 6.61-14.11 6.232-25.761-.434-13.357-4.717-31.14-15.432-40.99-8.314-7.641-18.927-11.424-29.526-11.424Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-94)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M141.928 144.565c-2.34 0-4.756-.11-7.22-.335-15.075-1.376-28.508-6.767-35.935-14.421-7.993-8.238-12.27-19.07-12.042-30.5.228-11.451 4.948-22.13 13.291-30.07 17.2-16.372 44.63-15.846 61.145 1.174 10.148 10.46 13.48 28.448 13.215 41.794-.234 11.73-3.12 21.348-7.722 25.727-4.425 4.211-13.73 6.63-24.732 6.63Zm-11.922-86.082c-10.498 0-21.011 3.856-29.165 11.615-8.11 7.72-12.7 18.103-12.922 29.236-.221 11.11 3.936 21.639 11.708 29.648 16.506 17.012 55.925 17.882 66.215 8.092 4.376-4.166 7.124-13.47 7.351-24.89.262-13.107-2.985-30.746-12.88-40.943-8.234-8.487-19.262-12.758-30.307-12.758Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-95)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M143.026 145.16c-3.379 0-6.96-.26-10.637-.796-14.546-2.121-27.297-8.089-34.109-15.963-7.338-8.484-10.934-19.317-10.123-30.504.81-11.187 5.928-21.39 14.411-28.727 17.512-15.147 44.082-13.224 59.23 4.288 6.812 7.875 10.88 21.353 10.884 36.054.003 13.697-3.524 25.6-8.988 30.326-3.981 3.445-11.589 5.322-20.668 5.322Zm-13.015-85.045c-9.468 0-18.978 3.266-26.685 9.932-8.25 7.135-13.226 17.055-14.013 27.934s2.708 21.412 9.844 29.66c15.138 17.503 53.309 20.378 63.78 11.32 5.218-4.514 8.59-16.074 8.586-29.448-.004-14.431-3.967-27.626-10.601-35.296-8.063-9.322-19.456-14.102-30.91-14.102Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-96)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
    </g>
    <g className='encompaasLogo_svg__cls-101'>
      <path
        d='M126.677 178.552c-21.021 0-42.761-10.438-56.995-29.538-14.145-18.983-16.904-49.255-13.363-70.835 2.377-14.483 7.466-25.366 13.963-29.86 8.595-5.943 27.436-5.923 48.008.055 22.05 6.407 41.162 18.214 51.125 31.584 11.384 15.277 16.265 34.07 13.395 51.564-2.51 15.292-10.698 28.422-23.057 36.97-9.845 6.809-21.35 10.06-33.076 10.06Zm-55.58-30.592c21.612 29.001 60.932 37.56 87.652 19.081 11.963-8.274 19.89-20.99 22.32-35.805 2.794-17.023-1.97-35.329-13.068-50.224-22.206-29.8-80.286-42.605-96.715-31.243-6.106 4.224-10.927 14.683-13.227 28.695-3.48 21.212-.8 50.927 13.037 69.496Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-97)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.386 175.844c-22.275 0-45.14-12.242-58.47-33.622-12.142-19.476-12.293-48.902-7.057-69.424 3.553-13.928 9.475-24.106 16.246-27.927 8.898-5.02 27.298-3.403 46.873 4.116 20.988 8.062 38.644 21.1 47.233 34.874 9.782 15.69 12.959 34.234 8.713 50.874-3.737 14.644-12.869 26.7-25.714 33.947-8.613 4.858-18.163 7.162-27.824 7.162Zm-57.01-34.533c18.63 29.882 56.308 41.487 83.988 25.872 12.433-7.014 21.273-18.689 24.893-32.874 4.13-16.187 1.029-34.243-8.507-49.538-19.143-30.703-74.782-48.004-91.8-38.4-6.363 3.59-11.985 13.377-15.424 26.852-5.145 20.168-5.023 49.043 6.85 68.088Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-98)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.11 173.19c-23.47 0-47.27-14.178-59.339-37.688-10.138-19.754-7.838-48.12-1.06-67.47 4.667-13.324 11.356-22.77 18.352-25.917 9.118-4.1 26.944-.98 45.418 7.947 19.808 9.573 35.939 23.676 43.15 37.726 8.182 15.941 9.721 34.086 4.222 49.782-4.88 13.93-14.85 24.878-28.073 30.826-7.231 3.253-14.935 4.793-22.67 4.793ZM94.005 42.018c-3.652 0-6.8.524-9.252 1.627-6.58 2.96-12.942 12.05-17.457 24.94-6.66 19.012-8.943 46.839.97 66.15 11.773 22.94 34.973 36.778 57.826 36.78 7.51 0 14.98-1.494 21.999-4.651 12.8-5.758 22.452-16.359 27.178-29.85 5.347-15.264 3.841-32.926-4.132-48.46-13.814-26.915-54.808-46.536-77.132-46.536Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-99)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.864 170.59c-24.627.002-49.163-16.233-59.638-41.684-5.449-13.244-7.517-38.224 4.587-65.039 5.718-12.668 13.11-21.353 20.28-23.829 9.252-3.193 26.393 1.331 43.671 11.529 18.528 10.935 33.077 25.94 38.92 40.14 6.596 16.034 6.581 33.646-.043 48.32-5.94 13.16-16.643 22.974-30.138 27.633-5.753 1.986-11.7 2.93-17.639 2.93ZM97.957 40.667c-2.395 0-4.527.297-6.328.92-6.746 2.328-13.79 10.695-19.324 22.954-8.017 17.763-12.538 44.361-4.563 63.742 12.674 30.803 46.423 47.772 75.226 37.829 13.064-4.511 23.427-14.013 29.18-26.758 6.44-14.266 6.446-31.405.02-47.024-11.698-28.43-53.076-51.663-74.211-51.663Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-100)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.668 168.048c-25.77 0-50.83-18.397-59.41-45.569-4.159-13.17-4.076-37.357 9.847-62.186 6.708-11.963 14.738-19.861 22.031-21.668 9.304-2.31 25.657 3.52 41.659 14.844 17.16 12.144 30.088 27.89 34.58 42.118 5.047 15.978 3.568 32.94-4.055 46.535-6.916 12.336-18.248 20.994-31.908 24.379a52.937 52.937 0 0 1-12.744 1.547Zm-57.887-46.05c9.747 30.865 41.26 50.134 70.246 42.952 13.228-3.278 24.201-11.662 30.9-23.61 7.408-13.212 8.838-29.713 3.925-45.271-10.014-31.715-56.511-60.312-74.33-55.893-6.868 1.702-14.53 9.319-21.023 20.899-13.672 24.382-13.78 48.058-9.718 60.923Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-101)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.542 165.565c-26.917 0-52.28-20.663-58.705-49.3-2.911-12.979-.809-36.212 14.697-58.976 7.635-11.207 16.236-18.29 23.6-19.435 9.29-1.44 24.752 5.578 39.41 17.88 15.724 13.198 27.006 29.522 30.178 43.666 3.541 15.785.699 31.993-7.8 44.468-7.808 11.463-19.663 18.95-33.382 21.081a52.112 52.112 0 0 1-7.998.616Zm-57.184-49.64c6.882 30.675 36.014 51.978 64.942 47.483 13.288-2.064 24.771-9.315 32.335-20.418 8.255-12.119 11.013-27.883 7.566-43.25-7.07-31.52-50.06-63.116-67.826-60.345-6.838 1.062-15.269 8.08-22.552 18.772-15.225 22.35-17.308 45.082-14.465 57.757Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-102)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.516 163.148c-28.101 0-53.54-23.014-57.583-52.845-2.75-20.297 8.581-42.567 19.121-55.47 8.495-10.398 17.599-16.643 24.98-17.132 9.19-.612 23.692 7.487 36.957 20.627 14.23 14.096 23.852 30.84 25.743 44.79 2.127 15.695-1.87 30.667-11.256 42.157-8.614 10.546-20.89 16.854-34.563 17.76-1.136.076-2.27.113-3.399.113ZM113.033 39.19c-.306 0-.605.01-.898.029-6.86.455-15.795 6.651-23.902 16.576-10.348 12.667-21.477 34.49-18.792 54.304 4.099 30.251 30.741 53.312 59.372 51.419 13.25-.879 25.142-6.989 33.488-17.205 9.116-11.16 12.996-25.717 10.925-40.991-4.141-30.565-42.183-64.132-60.193-64.132Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-103)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.62 160.805c-.352 0-.703-.004-1.058-.012-28.897-.602-53.593-25.798-55.049-56.164-.943-19.684 11.863-40.169 23.116-51.73 9.28-9.535 18.81-14.923 26.156-14.762 9.021.188 22.496 9.246 34.327 23.076 12.693 14.838 20.662 31.848 21.316 45.502.732 15.26-4.388 29.34-14.418 39.645-9.088 9.339-21.267 14.445-34.39 14.445Zm-54.624-56.248c1.42 29.604 25.464 54.166 53.598 54.753 13.122.26 25.31-4.694 34.353-13.984 9.74-10.008 14.711-23.695 13.999-38.54-1.458-30.418-36.894-66.805-54.192-67.166a12.13 12.13 0 0 0-.268-.002c-6.807 0-16.047 5.329-24.794 14.316-11.045 11.348-23.615 31.416-22.696 50.623Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-104)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M125.806 158.542c-1.754 0-3.523-.094-5.301-.285-28.171-3.029-50.136-29.489-48.963-58.984l.723.03-.723-.03c.754-18.943 14.842-37.592 26.678-47.806 9.988-8.62 19.884-13.117 27.12-12.337 8.794.945 21.177 10.845 31.55 25.222 11.13 15.426 17.46 32.554 16.933 45.817-.586 14.726-6.725 27.855-17.285 36.97-8.627 7.444-19.382 11.403-30.732 11.403Zm-2.088-118.049c-6.65 0-15.68 4.414-24.552 12.07C87.55 62.588 73.723 80.85 72.989 99.33c-1.144 28.75 20.241 54.538 47.67 57.486 12.872 1.387 25.278-2.443 34.933-10.775 10.253-8.849 16.214-21.609 16.784-35.93 1.175-29.541-30.326-67.73-47.19-69.543-.475-.05-.965-.076-1.468-.076Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-105)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.042 156.365c-3.073 0-6.176-.3-9.283-.905-27.253-5.313-46.442-32.767-42.778-61.196 2.333-18.097 17.52-34.878 29.817-43.751 10.453-7.542 20.862-11.228 27.858-9.869 8.506 1.659 19.754 12.282 28.656 27.064 9.554 15.863 14.273 32.967 12.624 45.752-1.82 14.104-8.87 26.237-19.855 34.162-7.974 5.753-17.335 8.742-27.04 8.743Zm2.51-114.592c-6.465 0-15.144 3.548-23.927 9.886-12.068 8.707-26.968 25.137-29.243 42.786-3.574 27.706 15.109 54.455 41.646 59.628 12.538 2.445 25.049-.253 35.227-7.596 10.665-7.695 17.512-19.485 19.28-33.198 3.672-28.47-23.833-68.068-40.149-71.248a14.834 14.834 0 0 0-2.834-.258Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-106)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.335 154.273c-4.317 0-8.68-.607-12.995-1.835-26.165-7.444-42.561-35.623-36.55-62.813 2.37-10.727 12.32-27.537 32.55-39.61 10.97-6.546 21.569-9.303 28.355-7.37 8.165 2.322 18.242 13.548 25.674 28.599 7.975 16.152 11.123 33.096 8.42 45.325-2.964 13.408-10.824 24.509-22.132 31.256-7.155 4.27-15.153 6.448-23.322 6.448Zm6.563-110.864c-6.224 0-14.394 2.743-22.851 7.79-12.409 7.404-28.212 21.989-31.912 38.724-5.856 26.49 10.106 53.94 35.582 61.19 12.112 3.445 24.626 1.859 35.234-4.471 10.978-6.551 18.611-17.337 21.492-30.371 6.019-27.222-17.46-67.845-33.125-72.301-1.323-.377-2.807-.561-4.42-.561Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-107)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M126.697 152.27c-5.507 0-11.058-1.011-16.437-3.044-24.92-9.415-38.525-38.058-30.33-63.85 3.363-10.584 14.294-25.446 34.89-35.42 11.383-5.511 22.075-7.332 28.602-4.867 7.775 2.937 16.658 14.648 22.63 29.834 6.41 16.299 8.036 32.955 4.35 44.555-4.02 12.65-12.586 22.694-24.121 28.28a44.804 44.804 0 0 1-19.584 4.512Zm9.956-106.926c-5.892 0-13.376 2.01-21.248 5.822-16.652 8.064-30.074 21.652-34.193 34.617-7.983 25.123 5.261 53.02 29.522 62.185 11.604 4.384 24.019 3.88 34.961-1.42 11.2-5.423 19.52-15.181 23.426-27.477 8.203-25.816-11.257-67.089-26.174-72.724-1.783-.674-3.908-1.003-6.294-1.003Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-108)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.142 150.36c-6.66 0-13.33-1.506-19.616-4.501-23.534-11.215-34.375-40.073-24.165-64.329 4.162-9.889 16.03-23.281 36.853-31.21 11.69-4.453 22.377-5.342 28.591-2.382 7.342 3.498 15.017 15.577 19.553 30.77 4.868 16.31 5.033 32.559.442 43.468-4.984 11.842-14.159 20.812-25.832 25.258a44.389 44.389 0 0 1-15.826 2.927ZM139.69 47.514c-5.413 0-12.01 1.366-19.01 4.032-20.436 7.783-32.053 20.855-36.11 30.494-9.941 23.62.61 51.718 23.52 62.635 11.008 5.246 23.228 5.792 34.41 1.534 11.337-4.317 20.247-13.032 25.09-24.54 10.217-24.273-5.262-65.834-19.349-72.546-2.264-1.078-5.178-1.61-8.55-1.61Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-109)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M127.68 148.548c-7.792 0-15.518-2.087-22.536-6.177-22.028-12.839-30.15-41.67-18.103-64.27 7.45-13.978 24.75-23.118 38.457-27.01 11.88-3.375 22.468-3.354 28.322.058 6.871 4.005 13.335 16.337 16.467 31.417 3.361 16.188 2.134 31.92-3.283 42.084-5.859 10.992-15.544 18.88-27.274 22.21a44.072 44.072 0 0 1-12.05 1.688Zm14.178-98.706c-4.716 0-10.193.827-16.01 2.48-20.52 5.826-32.918 17.448-37.678 26.381-11.727 22-3.823 50.066 17.619 62.562 10.343 6.029 22.273 7.58 33.592 4.365 11.391-3.236 20.8-10.9 26.494-21.581 12.05-22.61.483-64.111-12.7-71.795-2.76-1.608-6.667-2.412-11.317-2.412Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-110)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M128.325 146.838c-8.916 0-17.645-2.751-25.208-8.041-20.419-14.283-25.888-42.86-12.19-63.7 8.44-12.844 26.055-20.222 39.726-22.844 11.954-2.295 22.34-1.386 27.79 2.426 6.37 4.455 11.629 16.93 13.399 31.782 1.9 15.943-.643 31.057-6.804 40.43-6.644 10.11-16.748 16.913-28.452 19.158a43.836 43.836 0 0 1-8.261.789Zm14.66-94.591c-3.704 0-7.79.406-12.097 1.232-13.42 2.574-30.691 9.785-38.919 22.303-13.329 20.282-8.007 48.092 11.863 61.991 9.614 6.725 21.16 9.228 32.518 7.05 11.37-2.181 21.188-8.792 27.645-18.617 13.7-20.844 5.95-61.958-6.268-70.504-3.26-2.28-8.447-3.455-14.743-3.455Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-111)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.089 145.238c-10.052 0-19.73-3.499-27.647-10.07-18.724-15.544-21.624-43.649-6.464-62.65h.001c9.323-11.684 27.122-17.333 40.672-18.723 11.901-1.22 21.996.531 27.004 4.689 5.842 4.848 9.913 17.361 10.372 31.877.494 15.582-3.282 29.986-10.1 38.532-7.341 9.202-17.775 14.925-29.378 16.116-1.492.152-2.98.229-4.46.229ZM95.93 73.278h.001c-14.75 18.487-11.928 45.83 6.288 60.952 8.829 7.328 19.913 10.728 31.206 9.567 11.275-1.157 21.415-6.72 28.55-15.664 15.159-18.999 11.103-59.414-.097-68.712-4.762-3.952-14.516-5.603-26.103-4.415-13.303 1.365-30.757 6.884-39.845 18.272Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-112)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M129.98 143.754c-11.212 0-21.795-4.33-29.864-12.239-16.961-16.619-17.389-44.05-.959-61.147 10.1-10.508 27.96-14.466 41.307-14.666 11.735-.162 21.444 2.373 25.981 6.818 5.291 5.185 8.198 17.634 7.407 31.714-.848 15.114-5.767 28.727-13.157 36.416-7.955 8.276-18.632 12.928-30.062 13.1a41.57 41.57 0 0 1-.653.004Zm11.632-86.873c-.374 0-.75.003-1.13.009-13.108.196-30.622 4.057-40.468 14.3-15.984 16.63-15.565 43.31.934 59.477 7.845 7.687 18.134 11.899 29.032 11.899.212 0 .422-.002.635-.005 11.111-.166 21.49-4.689 29.223-12.734 16.425-17.091 15.919-56.518 5.775-66.458-4.172-4.088-13.092-6.488-24-6.488Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-113)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
      <path
        d='M130.92 142.374c-11.78 0-23.494-4.915-31.787-14.502-15.148-17.513-13.224-44.084 4.289-59.231 7.874-6.812 21.352-10.88 36.053-10.884h.019c13.69 0 25.584 3.528 30.307 8.988 4.726 5.464 6.502 17.751 4.526 31.305-2.121 14.547-8.089 27.298-15.964 34.11-7.924 6.855-17.706 10.214-27.442 10.214Zm8.574-83.457h-.019c-14.43.003-27.625 3.966-35.296 10.6-17.028 14.73-18.898 40.567-4.17 57.596 14.731 17.029 40.568 18.9 57.596 4.17 7.67-6.635 13.492-19.12 15.574-33.4 1.931-13.235.26-25.16-4.254-30.379-4.513-5.217-16.064-8.587-29.431-8.587Z'
        style={{
          fill: 'url(#encompaasLogo_svg__linear-gradient-114)',
        }}
        className='encompaasLogo_svg__cls-100'
      />
    </g>
  </svg>
);
export default SvgEncompaasLogo;
