import { Typography, useTheme } from '@mui/material';
import { User } from '../types';
import { Box, BoxProps } from './Box';

export type UserTileProps = BoxProps & {
  user?: User;
  showAvatar?: boolean;
  avatarBackground?: BoxProps['background'];
};

//create super component for div with layout
export const UserTile = ({
  user,
  showAvatar = false,
  padding,
  avatarBackground,
  direction,
  alignItems,
  justifyContent,
  gap,
  ...props
}: UserTileProps) => {
  const theme = useTheme();
  return (
    <Box
      direction='row'
      alignItems='center'
      justifyContent='start'
      gap={gap ?? 'medium'}
      padding={padding ?? 'medium'}
      {...props}
    >
      {showAvatar && (
        <Box
          height={'3rem'}
          width={'3rem'}
          rounded='round'
          background={avatarBackground}
          style={{ fontSize: '1.125rem' }}
        >
          <Typography variant='h4' sx={{ color: 'currentColor' }}>
            {user?.initials ?? user?.name?.[0] ?? 'U'}
          </Typography>
        </Box>
      )}
      <Box
        background='none'
        gap='xsmall'
        justifyContent='center'
        alignItems='start'
      >
        <Typography variant='body1' sx={{ color: theme.palette.info.dark }}>
          {user?.name ?? ''}
        </Typography>
        {user?.username && (
          <Typography variant='body2'>{user.username}</Typography>
        )}
      </Box>
    </Box>
  );
};
