import { User } from '../../../types/userTypes';
import { UserChip } from '../../UserChip';

export const UserTemplate = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => void;
}) => (
  <UserChip
    user={{ name } as User}
    border={false}
    onClick={
      onClick &&
      ((u) => {
        onClick && onClick();
      })
    }
  />
);
