import {
  DisposalRequestResponseStatus,
  DisposalRequestResponseStatusCaption,
} from '../types';
import { Typography, useTheme } from '@mui/material';

export type FormatRequestApproverProps = {
  status: string | DisposalRequestResponseStatus;
  width?: string;
};

export const FormatRequestApprover = ({
  status,
  width,
}: FormatRequestApproverProps) => {
  const theme = useTheme();

  let style: any = {
    width: width,
    color: theme.palette.info.main,
  };

  if (status === DisposalRequestResponseStatus.Delegated) {
    style = { ...style };
  } else if (status === DisposalRequestResponseStatus.Approved) {
    style = { ...style, color: theme.palette.primary.main };
  } else if (status === DisposalRequestResponseStatus.Rejected) {
    style = { ...style, color: theme.palette.warning.main };
  } else if (status === DisposalRequestResponseStatus.Pending) {
    style = {
      ...style,
      color: theme.palette.info.dark,
      fontWeight: '800',
    };
  } else if (status === DisposalRequestResponseStatus.Waiting) {
    return <Typography style={style}></Typography>;
  }
  return (
    <Typography sx={style} variant='body2'>
      {
        DisposalRequestResponseStatusCaption[
          status as keyof typeof DisposalRequestResponseStatusCaption
        ]
      }
      {status === DisposalRequestResponseStatus.Pending && '...'}
    </Typography>
  );
};
