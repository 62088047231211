import { useGetIconQuery } from '../services/itemApi';
import { getIcon } from './hookUtils';

type useIconProps = {
  imageId: string;
  skip?: boolean;
  fallbackObj?: any;
};

export const useIcon = ({
  imageId,
  skip = false,
  fallbackObj,
}: useIconProps) => {
  const { data } = useGetIconQuery({ imageId }, { skip });

  const iconDetails = getIcon(data ?? fallbackObj);

  return { iconDetails };
};
