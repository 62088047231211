import { Typography } from '@mui/material';
import { Box } from './Box';
import { StatusChip } from './StatusChip';

export type ItemSummaryTitleProps = {
  icon?: React.ReactNode;
  displayName: string;
  businessType?: string;
  itemType?: string;
};

export const ItemSummaryTitle = ({
  icon,
  displayName,
  businessType,
  itemType,
}: ItemSummaryTitleProps) => {
  return (
    <Box
      background='none'
      padding='none'
      justifyContent='start'
      alignItems='center'
      gap='medium'
      direction='row'
    >
      <>
        {icon && icon}
        <Typography
          variant='h1'
          style={{
            margin: 0,
            maxWidth: '37.5rem',
          }}
        >
          {displayName}
        </Typography>
        {businessType && <StatusChip label={businessType} />}
        {itemType && <StatusChip label={itemType} />}
      </>
    </Box>
  );
};
