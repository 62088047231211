import { Typography } from '@mui/material';
import { Info48Filled } from '@fluentui/react-icons';
import { Box } from '../Box';
import { palette } from '../../themes';

export const ItemEmptyState = ({
  repositoryUrl,
}: {
  repositoryUrl?: string;
}) => {
  return (
    <Box
      rounded='all'
      height='auto'
      alignItems='center'
      justifyContent='center'
      style={{
        border: '1px solid #DCE1EF',
        backgroundColor: '#F4F6FB',
        padding: '3.188rem 3.938rem 2.938rem',
      }}
    >
      <Info48Filled
        style={{
          marginBottom: '2.25rem',
          width: '2.5rem',
          height: '2.5rem',
          color: '#9EA7BF',
        }}
      />
      <Typography
        variant='h2'
        key='no-item-previewable'
        sx={{
          width: '18.188rem',
          textAlign: 'center',
          marginBottom: '1rem',
          whiteSpace: 'break-spaces',
        }}
      >
        This item does not contain previewable content
      </Typography>
      <Typography
        variant='body1'
        key='no-item-desc'
        sx={{
          width: '18.188rem',
          textAlign: 'center',
        }}
      >
        <Typography
          component='a'
          variant='body1'
          href={repositoryUrl}
          target='_blank'
          sx={{
            color: palette.aqua[800],
            textDecoration: 'none',
          }}
        >
          Click here
        </Typography>{' '}
        to attempt to open the information in the source repository.
      </Typography>
    </Box>
  );
};
