import {
  ArrowCurveUpLeft20Filled,
  ChevronLeftFilled,
  ChevronRightFilled,
  DismissCircle16Regular,
  DismissFilled,
  Edit20Regular,
  Grid20Filled,
  HomeRegular,
  QuestionCircleRegular,
} from '@fluentui/react-icons';
import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { CollapseLeft24, ExpandLeft24, History24 } from '../icons';
import { sizes } from '../themes/sizes';

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;
type MuiIconButtonProps = React.ComponentProps<typeof MuiIconButton>;
type MuiTooltipProps = React.ComponentProps<typeof MuiTooltip>;

export type ButtonProps = MuiButtonProps & {
  shape?: 'default' | 'square' | 'round';
  border?: boolean;
  tooltip?: ReactNode;
  tooltipPlacement?: MuiTooltipProps['placement'];
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
};

//create super component for mui button component to obfuscate library
export const Button: React.FC<ButtonProps> = ({
  shape = 'default',
  variant = 'contained',
  size = 'medium',
  border,
  tooltip,
  tooltipPlacement,
  ...props
}: ButtonProps) => {
  const sx = {
    ...(border && { border: '0.125rem solid currentcolor !important' }),
    ...(shape === 'round' && {
      padding: 0,
      minWidth: 0,
      width: `${sizes.rounddimension[size]} !important`,
      height: `${sizes.rounddimension[size]} !important`,
      borderRadius: '50%',
    }),
    ...(shape === 'square' && {
      padding: 0,
      minWidth: 0,
      width: `${sizes.dimension[size]} !important`,
      '& span': { margin: '0 !important' },
    }),
    ...props.sx,
  };

  const _component = () => (
    <MuiButton variant={variant} size={size} {...props} sx={sx} />
  );

  return tooltip ? (
    <MuiTooltip title={tooltip} placement={tooltipPlacement} arrow>
      {_component()}
    </MuiTooltip>
  ) : (
    _component()
  );
};

//following will be needed to size custom icons
type SizedIconButtonProps = { icons: JSX.Element[] } & {
  indicator?: boolean;
} & MuiIconButtonProps;

const SizedIconButton = ({
  icons,
  size = 'medium',
  ...props
}: SizedIconButtonProps) => {
  const _icons = new Array(5).fill(icons?.[0]);
  _icons.forEach(
    (i, index) => icons?.[index] && (_icons[index] = icons[index])
  );
  const _indexes: Record<string, number> = {
    small: 0,
    medium: 1,
    large: 2,
    xlarge: 3,
    xxlarge: 4,
  };
  const _icon = _icons[_indexes[size] ?? ''];
  return (
    <MuiIconButton size={size} {...props}>
      {_icon}
    </MuiIconButton>
  );
};
//-------------

//prebuilt buttons
export const CloseButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <DismissFilled />
    </MuiIconButton>
  );
};

export const PreviousButton = (props: MuiButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <ChevronLeftFilled></ChevronLeftFilled>
    </MuiIconButton>
  );
};

export const NextButton = (props: MuiButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <ChevronRightFilled />
    </MuiIconButton>
  );
};

export const AboutButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <QuestionCircleRegular />
    </MuiIconButton>
  );
};

export const GridButton = (props: MuiButtonProps) => {
  const theme = useTheme();
  return (
    <MuiIconButton
      size='medium'
      color='info'
      sx={{
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info[600],
        borderRadius: '0.75rem',
      }}
      {...props}
    >
      <Grid20Filled />
    </MuiIconButton>
  );
};

export const MenuButton = (props: MuiIconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <h2>⋮</h2>
    </MuiIconButton>
  );
};

export const EditButton = (props: MuiButtonProps) => {
  return (
    <MuiIconButton color='primary' {...props}>
      <Edit20Regular />
    </MuiIconButton>
  );
};

export const DeleteButton = (props: MuiButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <DismissCircle16Regular />
    </MuiIconButton>
  );
};

export const CollapseButton = ({
  direction = 'left',
  color = 'primary',
  ...props
}: { direction?: 'left' | 'right' } & React.ComponentProps<
  typeof MuiIconButton
>) => {
  return (
    <MuiIconButton color={color} {...props} aria-label='collapse'>
      {direction === 'left' ? <CollapseLeft24 /> : <ExpandLeft24 />}
    </MuiIconButton>
  );
};

export const RefreshButton = ({
  indicator = false,
  color = 'primary',
  ...props
}: { indicator?: boolean } & MuiIconButtonProps) => {
  //todo: add an indicator overlay
  return (
    <MuiIconButton color={color} {...props} aria-label='refresh'>
      <History24 />
    </MuiIconButton>
  );
};

export const HomeButton = ({
  label,
  ...props
}: { label?: string } & MuiButtonProps) => (
  <MuiButton {...props} startIcon={<HomeRegular />}>
    {label && (
      <Typography
        variant='body2'
        sx={{ marginLeft: '-0.25rem', color: 'inherit' }}
      >
        {label}
      </Typography>
    )}
  </MuiButton>
);

export const ParentNavigationButton = ({
  label,
  ...props
}: { label?: string } & MuiButtonProps) => (
  <MuiButton {...props} startIcon={<ArrowCurveUpLeft20Filled />}>
    {label && label}
  </MuiButton>
);
