import { DisposalRequestResponseStatus, ApproverResponse } from '../types';
import { Box } from './Box';
import { ApprovalStatusChip } from './ApprovalStatusChip';
import { formatDate } from '../util/dateTime';
import { Fragment } from 'react';
import { Typography } from '@mui/material';
import { CommentText } from './CommentText';
import { InfoDialog } from './dialog/InfoDialog';
import { useState } from 'react';

export type DisposalRequestResponsesProps = {
  data: ApproverResponse[] | undefined;
};

export const DisposalRequestResponses = ({
  data,
}: DisposalRequestResponsesProps) => {
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [commentDialogText, setCommentDialogText] = useState('');

  const commentBoxOpenHandler = (text: string) => {
    setCommentDialogOpen(true);
    setCommentDialogText(text);
  };

  const infoDialogCloseHandler = () => {
    setCommentDialogOpen(false);
    setCommentDialogText('');
  };

  return (
    <Box
      background='none'
      style={{
        overflow: 'visible',
      }}
    >
      <InfoDialog
        open={commentDialogOpen}
        title='Comment'
        information={commentDialogText}
        onClose={infoDialogCloseHandler}
        size='large'
      />
      {data?.map((approval: ApproverResponse, index: number) => {
        const key = `${approval?.ApproverId}-${index}`;
        if (
          approval.Status === DisposalRequestResponseStatus.Pending ||
          approval.Status === DisposalRequestResponseStatus.Waiting
        )
          return <Fragment key={key}></Fragment>;
        return (
          <table
            className='dr-details'
            style={{ margin: '0 0 45px 0' }}
            key={key}
          >
            <tbody>
              <tr>
                <td>
                  <Typography variant='h5'>Decision</Typography>
                </td>
                <td>
                  <ApprovalStatusChip status={approval.Status} />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='h5'>Response By</Typography>
                </td>
                <td>
                  <Typography variant='body2'>
                    {approval.Approver?.DisplayName}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='h5'>Date Responded</Typography>
                </td>
                <td>
                  <Typography variant='body2'>
                    {formatDate(approval?.DateResponded!)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td valign='top'>
                  <Typography variant='h5'>Response Comments</Typography>
                </td>
                <td>
                  <Box
                    background='none'
                    style={{
                      width: '30%',
                    }}
                  >
                    <CommentText
                      text={approval.ResponseComments}
                      numberOfLines={15}
                      variant='body2'
                      onClick={() =>
                        commentBoxOpenHandler(approval.ResponseComments)
                      }
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        );
      })}
    </Box>
  );
};
