import { Divider, TextField, Typography, useTheme } from '@mui/material';

import { CheckmarkSquare24Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { ApprovalStatusChip, Button, DetailsRow, UserChip } from '../';
import {
  DisposalRequest,
  DisposalRequestResponseStatus,
  User,
} from '../../types';
import { CustomActionModal } from './CustomActionModal';

export type DisposalRequestApprovalDialogProps = {
  title?: string;
  disposalRequest?: DisposalRequest | undefined;
  open?: boolean;
  user?: User;
  onClose?: () => void;
  onAction?: (actionButtonTitle: string) => void;
};

export const DisposalRequestApprovalDialog = ({
  title,
  disposalRequest,
  open,
  user,
  onClose,
  onAction,
}: DisposalRequestApprovalDialogProps) => {
  const theme = useTheme();

  const [comment, setComment] = useState('');

  return (
    <CustomActionModal
      title={title}
      onClose={onClose}
      open={open}
      icon={<CheckmarkSquare24Regular style={{ marginRight: '0.75rem' }} />}
      actionButton={
        <Button variant='contained' onClick={() => onAction(comment)}>
          Approve
        </Button>
      }
    >
      <DetailsRow
        label='Request Name'
        data={disposalRequest?.Name}
        width='80%'
      />
      <DetailsRow
        label='Disposal Action'
        data={disposalRequest?.DisposalAction}
        width='80%'
      />
      <DetailsRow
        label='Response'
        data={
          <ApprovalStatusChip status={DisposalRequestResponseStatus.Approved} />
        }
        width='80%'
      />
      <DetailsRow
        label='Response By'
        data={<UserChip user={user} />}
        width='80%'
      />
      <Divider style={{ margin: '2rem 0 2rem 0' }} />
      <Typography variant='body1'>Comment</Typography>
      <TextField
        sx={{
          marginTop: '0.5rem',
        }}
        fullWidth
        maxRows={5}
        minRows={5}
        multiline
        onChange={(event) => setComment(event.target.value)}
        value={comment}
      />
    </CustomActionModal>
  );
};
export default DisposalRequestApprovalDialog;
