import { Link, Typography, useTheme } from '@mui/material';
import { Item, User } from '../../../types';
import { getFormattedDateTime } from '../../../util';
import { DetailsRow } from '../../DetailsRow';
import { UserChip } from '../../UserChip';

export type ItemTypeItemCollectionPanelProps = {
  item: Item;
};

export const ItemTypeItemCollectionPanel = ({
  item,
}: ItemTypeItemCollectionPanelProps) => {
  const itemType = item?.TypeDef?._Display;

  const theme = useTheme();

  return (
    <>
      <DetailsRow
        label='Name'
        data={item?.DisplayName}
        key={`${itemType}-name`}
      />
      <DetailsRow
        label='Date Latest Member Modified'
        data='Date Latest Member Modified Data'
        key={`${itemType}-date-latest-member-modified`}
      />
      <DetailsRow
        label='Created By'
        data={
          item?.CreatedBy?.DisplayName ? (
            <UserChip user={{ name: item?.CreatedBy?.DisplayName } as User} />
          ) : null
        }
        key={`${itemType}-created-by`}
      />
      <DetailsRow
        label='Date Created'
        data={getFormattedDateTime(item?.DateCreated)}
        key={`${itemType}-date-created`}
      />
      <DetailsRow
        label='Date Modified'
        data={getFormattedDateTime(item?.DateModified)}
        key={`${itemType}-date-modified`}
      />
      <DetailsRow
        label='Document Type'
        data={item?.StorageObject?.FileExtension}
        key={`${itemType}-document-type`}
      />
      {/* https://encompaas.visualstudio.com/EncompaaS/_workitems/edit/54146 */}
      {/* <DetailsRow
        label='Description'
        data={<Typography variant='body1'>{item?.Description}</Typography>}
        key={`${itemType}-description`}
      /> */}
      <DetailsRow
        label='Repository URL'
        data={
          <Link
            href={item?.RepositoryUrl}
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                maxWidth: '29.313rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item?.RepositoryUrl}
            </Typography>
          </Link>
        }
        key={`${itemType}-repository-url`}
      />
    </>
  );
};
