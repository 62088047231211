import { Box } from './Box';
import { DisposalRequestStatus } from '../types';
import {
  DisposalRequestProgressStatus,
  DisposalRequestProgressIndicatorStatus,
} from './DisposalRequestProgressIndicatorStatus';

export type DisposalRequestProgressIndicatorProps = {
  requestStatus?: DisposalRequestStatus;
  withWarning?: boolean;
  isBlocked?: boolean;
};

const getProgressStatus = (requestStatus, isBlocked) => {
  switch (requestStatus) {
    case DisposalRequestStatus.InApproval:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.IN_PROGRESS,
        indicator3: DisposalRequestProgressStatus.PENDING,
        indicator4: DisposalRequestProgressStatus.PENDING,
      };
    case DisposalRequestStatus.Approved:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.COMPLETE,
        indicator3: isBlocked
          ? DisposalRequestProgressStatus.BLOCKED
          : DisposalRequestProgressStatus.READY,
        indicator4: DisposalRequestProgressStatus.PENDING,
      };
    case DisposalRequestStatus.InProgress:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.COMPLETE,
        indicator3: DisposalRequestProgressStatus.IN_PROGRESS,
        indicator4: DisposalRequestProgressStatus.PENDING,
      };
    case DisposalRequestStatus.Complete:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.COMPLETE,
        indicator3: DisposalRequestProgressStatus.COMPLETE,
        indicator4: DisposalRequestProgressStatus.COMPLETE,
      };
    case DisposalRequestStatus.Rejected:
      return {
        indicator1: DisposalRequestProgressStatus.COMPLETE,
        indicator2: DisposalRequestProgressStatus.REJECTED,
        indicator3: DisposalRequestProgressStatus.PENDING,
        indicator4: DisposalRequestProgressStatus.PENDING,
      };
    default:
      return {
        indicator1: DisposalRequestProgressStatus.READY,
        indicator2: DisposalRequestProgressStatus.PENDING,
        indicator3: DisposalRequestProgressStatus.PENDING,
        indicator4: DisposalRequestProgressStatus.PENDING,
      };
  }
};

export const DisposalRequestProgressIndicator = ({
  requestStatus,
  withWarning,
  isBlocked,
  ...rest
}: DisposalRequestProgressIndicatorProps) => {
  if (
    ![
      DisposalRequestStatus.New,
      DisposalRequestStatus.InApproval,
      DisposalRequestStatus.Approved,
      DisposalRequestStatus.Rejected,
      DisposalRequestStatus.Complete,
      DisposalRequestStatus.InProgress,
    ].includes(requestStatus as DisposalRequestStatus)
  )
    return null;

  const { indicator1, indicator2, indicator3, indicator4 } = getProgressStatus(
    requestStatus,
    isBlocked
  );

  return (
    <Box gap='medium' direction='row' background='none' {...rest}>
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator1}
        sequenceNumber='1'
        label='Prepare Request'
        withWarning={
          requestStatus === DisposalRequestStatus.New ? withWarning : false
        }
      />
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator2}
        sequenceNumber='2'
        label='Request Approval'
        withWarning={
          requestStatus === DisposalRequestStatus.InApproval
            ? withWarning
            : false
        }
      />
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator3}
        sequenceNumber='3'
        label='Dispose Items'
        withWarning={
          requestStatus === DisposalRequestStatus.Approved ? withWarning : false
        }
        isBlocked={DisposalRequestStatus.InProgress ? isBlocked : false}
      />
      <DisposalRequestProgressIndicatorStatus
        progressStatus={indicator4}
        sequenceNumber='4'
        label='Disposal Complete'
        isLast={true}
      />
    </Box>
  );
};
