import {
  ColumnDirective,
  ColumnsDirective,
  Freeze,
  GridComponent,
  Inject,
  Page,
  Resize,
  Sort,
  Reorder,
  ColumnChooser,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { useRef, useState } from 'react';
import {
  DisposalRequest,
  DisposalRequestResponseStatus,
  DisposalRequestResponseStatusCaption,
  DisposalRequestStatus,
  User,
} from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import InfoDialog from '../dialog/InfoDialog';
import useData from './DataManagerHook';
import {
  CommentTemplate,
  PagerData,
  PagerTemplate,
  SummaryTemplate,
  UserTemplate,
} from './templates';
import { ColumnEdit20Regular } from '@fluentui/react-icons';
import { ChevronDownFilled } from '@fluentui/react-icons';
import { Grow, Menu, MenuItem, Typography, useTheme } from '@mui/material';

export type DRGridProps = {
  user?: User;
  data?: DisposalRequest[];
  filter?: DisposalRequestStatus | DisposalRequestResponseStatus;
  onOpen?: (request: DisposalRequest, target?: string) => void;
  onSelect?: (request: DisposalRequest) => void;
  key?: string;
  pageSize?: number;
  pageSizes?: number[];
  title?: string;
  adminMode?: boolean;
};

export const DRGrid = ({
  user,
  data,
  filter,
  onOpen,
  onSelect,
  pageSize = 30,
  pageSizes = [30, 60, 100],
  title,
  adminMode = false,
}: DRGridProps) => {
  const gridRef = useRef(null);

  const theme = useTheme();

  const { dataManager } = useData({
    status: filter,
    user,
    gridData: data,
    adminMode,
  });

  const key = Math.random();
  const filterSettings: any = {
    type: 'Menu',
  };

  const [comment, setComment] = useState<string | null>(null);
  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);

  const dateFormat = { type: 'date', format: 'dd MMM yyy' };

  const handleSelect = () => {
    if (gridRef?.current) {
      onSelect &&
        onSelect(gridRef.current.getSelectedRecords()?.[0] as DisposalRequest);
    }
  };

  const show = () => {
    if (gridRef?.current) {
      gridRef.current.openColumnChooser(0, 0);
    }
  };
  const gridData = DataUtil.parse.parseJson(JSON.stringify(data));

  return (
    //wrap with a box so that scrolling in the grid works

    <Box background='none'>
      <Box
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        background='none'
        height={6}
      >
        <Typography variant='h1'>{title}</Typography>

        <Button
          variant='text'
          startIcon={<ColumnEdit20Regular />}
          endIcon={<ChevronDownFilled />}
          onClick={show}
        >
          Change columns
        </Button>
      </Box>

      {(!!gridData || !!dataManager) && (
        <GridComponent
          key={key}
          dataSource={gridData ?? dataManager}
          allowResizing={true}
          enablePersistence={true}
          allowSorting={true}
          allowReordering={true}
          frozenColumns={1}
          enableStickyHeader={true}
          allowFiltering={true}
          rowSelected={handleSelect}
          rowDeselected={handleSelect}
          showColumnChooser={true}
          width={'100%'}
          height={'100%'}
          //need the id to maintain persisted data
          id={`DisposalApprovalGrid_${filter}`}
          data-testid={`DisposalApprovalGrid_${filter}`}
          ref={gridRef}
          // ref={(g) => (grid = g)}
          allowPaging={true}
          pageSettings={{ pageSize, pageSizes }}
          pagerTemplate={(pagerData: PagerData) => {
            const pagerDataWithTotalPages = {
              ...pagerData,
              totalPages: Math.ceil(
                pagerData.totalRecordsCount / pagerData.pageSize
              ),
            };
            return (
              <PagerTemplate
                key={key}
                pagerData={pagerDataWithTotalPages}
                grid={gridRef?.current}
                pageSizes={pageSizes}
              />
            );
          }}
          filterSettings={filterSettings}
        >
          <Inject
            services={[
              Resize,
              Sort,
              Freeze,
              Page,
              Reorder,
              ColumnChooser,
              Filter,
            ]}
          />
          <ColumnsDirective>
            <ColumnDirective
              field='Name'
              key='Name'
              headerText='Disposal Request'
              template={(request: DisposalRequest) => (
                <SummaryTemplate
                  title={request.Name}
                  subTitle={`Destroy ${request.ItemCount ?? ''} Items`}
                  information={`Requested by ${request.RequestedBy?.DisplayName}`}
                  onClick={() => onOpen && onOpen(request)}
                  //open context menu - future use
                  onContextMenu={() =>
                    console.log('right click request :', request.Name)
                  }
                />
              )}
              width={'18rem'}
              allowReordering={false}
              showInColumnChooser={false}
              allowFiltering={false}
            />

            <ColumnDirective
              field='DateRequested'
              key={`DateRequested_${filter}`}
              headerText='Date Requested'
              type='date'
              format={dateFormat}
            />

            {filter !== DisposalRequestResponseStatus.Pending && [
              <ColumnDirective
                field='Response.DateResponded'
                key={`Response.DateResponded_${filter}`}
                headerText={`Date ${DisposalRequestResponseStatusCaption[filter]}`}
                type='date'
                format={dateFormat}
                allowFiltering={false}
              />,
              <ColumnDirective
                field='Response.ResponseComments'
                key='Response.ResponseComments'
                headerText={`${
                  filter === DisposalRequestResponseStatus.Delegated
                    ? 'Delegation'
                    : 'Response'
                } Comments`}
                minWidth={'25rem'}
                width={'30rem'}
                template={(request: DisposalRequest) => (
                  <CommentTemplate
                    comment={request.Response?.ResponseComments}
                    onClick={setComment}
                    isLink={false}
                  />
                )}
                allowFiltering={false}
              />,
            ]}

            {filter === DisposalRequestResponseStatus.Delegated && (
              <ColumnDirective
                field='DelegatedTo.DisplayName'
                key='DelegatedTo.DisplayName'
                headerText='Delegated To'
                template={(request: DisposalRequest) => (
                  <UserTemplate
                    name={request.DelegatedTo?.Approver?.DisplayName ?? ''}
                  />
                )}
                allowFiltering={false}
              />
            )}
            {filter === DisposalRequestResponseStatus.Pending && [
              <ColumnDirective
                field='RequestComments'
                key='RequestComments'
                headerText='Request Comments'
                minWidth={'25rem'}
                width={'30rem'}
                template={(request: DisposalRequest) => (
                  <CommentTemplate
                    comment={request.RequestComments}
                    onClick={setComment}
                  />
                )}
                allowFiltering={false}
              />,
              <ColumnDirective
                field='ItemCount'
                key='ItemCount'
                headerText='Items'
                type='number'
                width={'5rem'}
                allowFiltering={false}
              />,
            ]}

            {filter !== DisposalRequestResponseStatus.Delegated && (
              <ColumnDirective
                field='RequestedBy.DisplayName'
                key='RequestedBy.DisplayName'
                headerText='Requested By'
                template={(request: DisposalRequest) => (
                  <UserTemplate name={request.RequestedBy?.DisplayName ?? ''} />
                )}
                allowFiltering={false}
              />
            )}
          </ColumnsDirective>
        </GridComponent>
      )}
      <InfoDialog
        title='Comment'
        open={!!comment}
        onClose={() => setComment(null)}
        information={comment as string}
      />
      {/* following needs more work - not for beta but left for future use */}
      <Menu
        open={Boolean(contextAnchor)}
        anchorEl={contextAnchor}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setContextAnchor(null);
        }}
        TransitionComponent={Grow}
        MenuListProps={{
          className: 'MuiMenu-list-withArrow',
        }}
      >
        <MenuItem
          key={0}
          onClick={() => {
            setContextAnchor(null);
          }}
        >
          Open in new tab
        </MenuItem>
        ,
        <MenuItem
          key={0}
          onClick={() => {
            setContextAnchor(null);
          }}
        >
          Open in new window
        </MenuItem>
        ,
      </Menu>
    </Box>
  );
};
export default DRGrid;
