import {
  Divider,
  Grow,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { Children, SyntheticEvent, useState } from 'react';
import { EncompaasLogo } from '../images';
import { User } from '../types';
import { AppLogo } from './AppLogo';
import { Banner } from './Banner';
import { Box, BoxProps } from './Box';
import { AboutButton, Button, ButtonProps } from './Button';
import { UserTile } from './UserTile';

export type AppBarProps = {
  title?: string;
  actions?: React.ReactNode;
  preactions?: React.ReactNode;
  height?: string | number;
  showLogo?: boolean;
  background?: BoxProps['background'];
};

export const AppBar = ({
  title,
  actions,
  preactions,
  height,
  showLogo = true,
  background = 'default',
}: AppBarProps) => {
  return (
    <Box
      background={background}
      direction='row'
      alignItems='center'
      height={height ?? 6}
      gap='small'
      style={{ padding: '0 1.5rem 0 0.75rem' }}
    >
      {preactions && (
        <Box
          direction='row'
          background='none'
          alignItems='center'
          justifyContent='start'
          style={{ paddingLeft: '1rem', flex: 0 }}
        >
          {/* use children.map to avoid key warning */}
          {Children.map(preactions, (a, i) => a)}
        </Box>
      )}

      {showLogo && <AppLogo title={title}></AppLogo>}

      {actions && (
        <Box
          direction='row'
          background='none'
          alignItems='center'
          justifyContent='end'
          gap='small'
          style={{ flex: 1, overflow: 'unset' }} // Change flex to 1
        >
          {Children.map(actions, (a, i) => a)}
        </Box>
      )}
    </Box>
  );
};

export type AppBarWithActionsProps = AppBarProps & {
  user?: User;
  helpOptions?: string[];
  onHelpOption?: (option: string) => void;
  onLogout?: () => void;
  color?: ButtonProps['color'];
};

export const AppBarWithActions = ({
  user,
  helpOptions,
  onHelpOption,
  onLogout,
  actions,
  color = 'secondary',
  ...props
}: AppBarWithActionsProps) => {
  const [profileAnchor, setProfileAnchor] = useState<Element | null>(null);
  const [helpAnchor, setHelpAnchor] = useState<Element | null>(null);
  const theme = useTheme();

  const _actions = [...[actions]];
  if (helpOptions)
    _actions.push(
      <AboutButton onClick={(e) => setHelpAnchor(e.currentTarget)} />
    );
  if (user)
    _actions.push(
      <Button
        shape='round'
        variant='contained'
        size='large'
        border
        color={color}
        onClick={(e: SyntheticEvent) => setProfileAnchor(e.currentTarget)}
      >
        <Typography variant='h4' sx={{ color: 'currentColor' }}>
          {user?.initials ?? user?.name?.[0] ?? 'U'}
        </Typography>
      </Button>
    );

  return (
    <div>
      <AppBar actions={_actions} {...props} />
      <Menu
        open={Boolean(profileAnchor)}
        anchorEl={profileAnchor}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setProfileAnchor(null);
        }}
        TransitionComponent={Grow}
        MenuListProps={{ className: 'MuiMenu-list-withArrow' }}
      >
        <UserTile user={user} background='light' avatarBackground='tertiary' />
        {onLogout && <Divider variant='menu' />}
        {onLogout && (
          <MenuItem
            onClick={() => {
              setProfileAnchor(null);
              onLogout();
            }}
          >
            Sign Out
          </MenuItem>
        )}
      </Menu>
      <Menu
        open={Boolean(helpAnchor)}
        anchorEl={helpAnchor}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setHelpAnchor(null);
        }}
        TransitionComponent={Grow}
        MenuListProps={{ className: 'MuiMenu-list-withArrow' }}
      >
        {helpOptions?.map((option, index) => [
          index !== 0 ? <Divider variant='menu' /> : null,
          <MenuItem
            key={index}
            onClick={() => {
              setHelpAnchor(null);
              onHelpOption && onHelpOption(option);
            }}
          >
            {option}
          </MenuItem>,
        ])}
      </Menu>
    </div>
  );
};
