import dayjs from "dayjs";

//
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${day}/${month}/${year} ${hour}:${minute}`;
};


export const getFormattedDateTime = (dateTimeStr: string) => {
  if (!dateTimeStr) return null;
  return dayjs(dateTimeStr).format("DD/MM/YYYY HH:mm");
}