export const sizes = {
  dimension: {
    xsmall: '0.875rem',
    small: '1.625rem',
    medium: '2.5rem',
    large: '3.0rem',
    xlarge: '3.875rem',
    xxlarge: '4.625rem',
  },
  icon: {
    small: '1.125rem',
    medium: '1.5rem',
    large: '1.875rem',
    xlarge: '2.25rem',
    xxlarge: '3rem',
  },
  rounddimension: {
    xsmall: '1rem',
    small: '1.725rem',
    medium: '2.5rem',
    large: '3rem',
    xlarge: '4.050rem',
    xxlarge: '4.825rem',
  },
  padding: {
    none: 0,
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
  },
  gap: {
    none: 0,
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
  },
  font: {
    xsmall: '0.625rem',
    small: '0.75rem',
    medium: '0.875rem',
    large: '1rem',
    xlarge: '1.25rem',
    xxlarge: '1.5rem',
  },
};
