import {
  MsalProvider,
  MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfiguration, loginRequest } from './config';
import { AuthErrorComponent, RedirectOnError, RedirectOnLogout } from './AuthComponents';

// Todo: create singleton to make sure there is always one instance
export const publicClientApplication = new PublicClientApplication(
  msalConfiguration
);

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <RedirectOnLogout>
    <MsalProvider instance={publicClientApplication}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={AuthErrorComponent}
      >
        <RedirectOnError>
        {children}
        </RedirectOnError>
      </MsalAuthenticationTemplate>
    </MsalProvider>
    </RedirectOnLogout>
  );
};

export const AuthProviderWithLogoutComponent = ({
  children,
}: AuthProviderProps) => {
  return (
    <MsalProvider instance={publicClientApplication}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
    </MsalProvider>
  );
};

// This auth provider is to be used with the AuthenticatedChildren and Unauthenticated children
//    View the HOME application for an example of its use
export const AuthProviderBasic = ({ children }: AuthProviderProps) => {
  return (
    <MsalProvider instance={publicClientApplication}>{children}</MsalProvider>
  );
};

export const AuthenticatedChildren = ({ children }: AuthProviderProps) => {
  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

export const UnauthenticatedChildren = ({ children }: AuthProviderProps) => {
  return <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>;
};
