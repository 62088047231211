import { Typography } from '@mui/material';
import { Box } from './Box';

export const ItemPropertiesPanel = () => {
  return (
    <Box padding='large' background='none' style={{ paddingLeft: '7rem' }}>
      <Typography variant='h3' key='properties'>
        Content
      </Typography>
    </Box>
  );
};
