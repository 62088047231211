import { MsalAuthenticationResult } from '@azure/msal-react';
import { useGetCurrentUserQuery } from '../services';
import { config } from '../config';

export const AuthErrorComponent: React.FunctionComponent<
  MsalAuthenticationResult
> = ({ error }) => {
  return (
    <div>An Error Occurred: {error ? error.errorCode : 'unknown error'}</div>
  );
};

export const RedirectOnError = ({ children }: any) => {
  const userQuery = useGetCurrentUserQuery();

  if (userQuery.isError && (userQuery.error as any).data) {
    // Thread through error message to landing app
    window.location.href =
      config.REACT_APP_ENC_LANDING_APP_URL +
      'error/' +
      (userQuery.error as any).data?.FriendlyMessage;
    return <></>;
  }
  return <>{children}</>;
}

export const RedirectOnLogout = ({ children }: any) => {
  const { pathname } = window.location;
  if (pathname == '/logout') {
    // Redirect to homepage
    window.location.href = config.REACT_APP_ENC_LANDING_APP_URL;
    return <></>;
  }

  return <>{children}</>;
};
