import { DataStateChangeEventArgs } from '@syncfusion/ej2-react-treegrid';
import { useRef, useState } from 'react';
import { Item } from '../../types';

import { DataResult } from '@syncfusion/ej2-react-grids';
import { config } from '../../config';
import { acquireToken } from '../../util/data-utils';

export type ItemsGridProps = {
  disposalId?: string;
  data?: Item[];
  onOpen?: (request: Item, target?: string) => void;
  onSelect?: (request: Item) => void;
  key?: string;
  pageSize?: number;
  pageSizes?: number[];
  adminMode?: boolean;
};

const useItemsGrid = ({ pageSize, disposalId, data, adminMode }) => {
  const key = Math.random();
  const gridRef = useRef<any>(null);

  const BASE_URL: string = `${config.API_BASE_URL}/Enc.Item?`;
  const execute = (state: DataStateChangeEventArgs): Promise<DataResult> => {
    if (state.requestType === 'expand') {
      return getChildData(state);
    } else {
      return getData(state);
    }
  };

  const getData = async (
    state: DataStateChangeEventArgs
  ): Promise<DataResult> => {
    const token = !!data ? '' : await acquireToken();
    const pageQuery = `$skip=${state.skip}&$top=${state.take}`;

    /// filter query for fetching only the root level records
    const treegridQuery = `&$filter=DisposalRequests/ID eq ${disposalId}&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems, CreatedBy, MemberOf, Members($count=true), DisposalRequests, DisposalStates($select=*)`;

    try {
      const response = await fetch(
        `${BASE_URL}?${pageQuery}&${treegridQuery}&$inlinecount=allpages&$format=json`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      let data = await response.json();
      (data?.value ?? []).map((x) => {
        //Is Parent
        if (x.Members || x.AttachedItems) {
          x.HasMember = true;
        } else {
          x.HasMember = false;
        }

        //ParentId Mapping
        if (x.MemberOf !== null || x.AttachmentOfId !== null) {
          x.ParentId = x.MemberOf.value[0].ID;
        } else {
          x.ParentId = null;
        }

        return x;
      });

      return {
        count: data['@odata.count'],
        result: data.value ?? [],
      };
    } catch (error) {
      console.error(error);
    }
    return {
      count: 0,
      result: [],
    };
  };
  const getChildData = async (
    state: DataStateChangeEventArgs
  ): Promise<DataResult> => {
    const token = !!data ? '' : await acquireToken();

    const treegridQuery = `$select=*&$expand=Classifications,TypeDef,StorageObject,AttachedItems($count=true), CreatedBy, MemberOf, Members($count=true), DisposalStates($select=*), DisposalRequests&$filter=(MemberOf eq ${
      (state.data as any).ID == null ? '-1' : (state.data as any).ID
    }) or (AttachmentOfId eq ${
      (state.data as any).ID == null ? '-1' : (state.data as any).ID
    })`;

    try {
      const response = await fetch(
        `${BASE_URL}?&${treegridQuery}&$inlinecount=allpages&$format=json`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      let data = await response.json();
      (data?.value ?? []).map((x) => {
        //Is Parent
        if (x.Members || x.AttachedItems) {
          x.HasMember = true;
        } else {
          x.HasMember = false;
        }

        //ParentId Mapping
        if (x.MemberOf !== null || x.AttachmentOfId !== null) {
          x.ParentId = x.MemberOf?.value[0].ID ?? x.ID;
        } else {
          x.ParentId = null;
        }

        return x;
      });

      return data.value;
    } catch (error) {
      console.error(error);
    }
  };

  const getDataSource = () => {
    if (
      gridRef.current &&
      gridRef.current.dataSource instanceof Array &&
      !(gridRef.current.dataSource as object[]).length
    ) {
      const admin = () => {
        if (gridRef.current) {
          /** show by HeaderText */
          gridRef.current.hideColumns([
            'Extension',
            'Created By',
            'Date Created',
            'Date Modified',
          ]);
          gridRef.current.showColumns([
            'Disposal Status',
            'Business Type',
            'Status',
          ]);
        }
      };
      const normal = () => {
        if (gridRef.current) {
          /** hide by HeaderText */
          gridRef.current.hideColumns([
            'Disposal Status',
            'Business Type',
            'Status',
          ]);
          gridRef.current.showColumns([
            'Extension',
            'Created By',
            'Date Created',
            'Date Modified',
          ]);
        }
      };
      if (adminMode) {
        admin();
      } else {
        normal();
      }
      const state = { skip: 0, take: pageSize }; /// take value should always be equal to the pageSize of  TreeGrid
      dataStateChange(state);
    }
  };

  const dataStateChange = (state: any): void => {
    if (state.requestType === 'expand') {
      execute(state).then((childData: any) => {
        state.childData = childData;
        state.childDataBind();
      });
    } else {
      execute(state).then((treedata) => {
        if (gridRef.current) {
          gridRef.current.dataSource = treedata;
        }
      });
    }
  };

  const [comment, setComment] = useState<string | null>(null);

  const dateFormat = { type: 'date', format: 'dd MMM, yyy' };

  // const handleSelect = () => {
  //   if (gridRef?.current) {
  //     onSelect &&
  //       onSelect(gridRef.current.getSelectedRecords()?.[0] as Item);
  //   }
  // };

  return {
    comment,
    setComment,
    dateFormat,
    dataStateChange,
    getDataSource,
    gridRef,
    key,
  };
};

export default useItemsGrid;
