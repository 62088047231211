import {
  Warning24Regular,
  CheckmarkCircle24Regular,
  Info24Regular,
} from '@fluentui/react-icons';
import {
  Alert as MuiAlert,
  Snackbar,
  SnackbarOrigin,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '../Box';

export type AlertType = 'info' | 'warning' | 'success';

export type AlertProps = {
  title?: string;
  message: string;
  open?: boolean;
  position?: SnackbarOrigin;
  alertType: AlertType;
  onClose: () => void;
  icon?: React.ReactNode;
  autoHideDuration?: number | null;
};

export const Alert: React.FC<AlertProps> = ({
  message,
  title,
  open = false,
  position = { horizontal: 'center', vertical: 'bottom' },
  alertType = 'info',
  icon,
  autoHideDuration,
  onClose,
  ...props
}: AlertProps) => {
  const theme = useTheme();

  let color = theme.palette.text.primary;
  let lightColor = theme.palette.text.primary;

  if (alertType === 'warning') {
    color = theme.palette.warning[903];
    lightColor = theme.palette.warning[100];
    icon = (
      <Warning24Regular
        color={color}
        style={{
          margin: '0 0.5rem 0 0.5rem',
        }}
      />
    );
  } else if (alertType === 'success') {
    color = theme.palette.primary[900];
    lightColor = theme.palette.primary[50];
    icon = (
      <CheckmarkCircle24Regular
        color={color}
        style={{
          margin: '0 0.5rem 0 0.5rem',
        }}
      />
    );
  } else if (alertType === 'info') {
    color = theme.palette.info[800];
    lightColor = theme.palette.info[100];
    icon = (
      <Info24Regular
        color={color}
        style={{
          margin: '0 0.5rem 0 0.5rem',
        }}
      />
    );
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={position}
      sx={{
        alignContent: 'center',
      }}
      autoHideDuration={autoHideDuration ?? null}
      onClose={onClose} // add it here so autoHideDuration can access it
      {...props}
    >
      <MuiAlert color={alertType} icon={icon} onClose={onClose}>
        <Box
          background='none'
          style={{
            borderRight: `2px solid ${lightColor}`,
            padding: '0.2rem 1rem 0.2rem 0.2rem',
          }}
        >
          {!!title && (
            <Typography
              variant='h4'
              sx={{
                fontFamily: 'Avenir black!important',
                color: { color },
              }}
            >
              {title}
            </Typography>
          )}
          <Typography
            variant='body1'
            sx={{
              color: { color },
            }}
          >
            {message}
          </Typography>
        </Box>
      </MuiAlert>
    </Snackbar>
  );
};
