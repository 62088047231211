import {
  ColumnDirective,
  ColumnsDirective,
  Freeze,
  TreeGridComponent,
  Inject,
  Page,
  Resize,
  Sort,
  ColumnMenu,
  PageSettingsModel,
} from '@syncfusion/ej2-react-treegrid';
import { Item } from '../../types';
import { Box } from '../Box';
import InfoDialog from '../dialog/InfoDialog';
import {
  PagerData,
  PagerTemplate,
  ItemSummaryTemplate,
  LinkTemplate,
  RetentionClassTemplate,
  CommentTemplate,
} from './templates';
import useItemsGrid from './useItemsGrid';
import { PageEventArgs } from '@syncfusion/ej2-react-grids';
import { BusinessTypeTemplate } from './templates/BusinesssTypeTemplate';
import { DisposalStatusTemplate } from './templates/DisposalStatusTemplate';
import { ManagementStatusTemplate } from './templates/ManagementStatusTemplate';

export type ItemsGridProps = {
  disposalId?: string;
  data?: Item[];
  onOpen?: (target?: string) => void;
  onSelect?: (request: Item) => void;
  key?: string;
  pageSize?: number;
  pageSizes?: number[];
  adminMode?: boolean;
  testId?: string;
};

export const ItemsGrid = ({
  disposalId,
  data,
  onOpen,
  onSelect,
  pageSize = 30,
  pageSizes = [30, 60, 100],
  adminMode = false,
  testId = 'ItemsGrid',
}: ItemsGridProps) => {
  const {
    getDataSource,
    dataStateChange,
    gridRef,
    comment,
    setComment,
    dateFormat,
    key,
  } = useItemsGrid({ pageSize, disposalId, data, adminMode });
  const pageSettings: PageSettingsModel = {
    pageSize: pageSize,
    pageSizeMode: 'Root',
  };

  return (
    //wrap with a box so that scrolling in the grid works
    <Box background='none'>
      <TreeGridComponent
        immediateRender
        enableCollapseAll
        dataSource={!!data ? data : []}
        dataBound={!!data ? undefined : getDataSource}
        dataStateChange={!!data ? undefined : dataStateChange}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
        ref={gridRef}
        rowHeight={90}
        treeColumnIndex={0}
        allowResizing={true}
        enablePersistence={true}
        parentIdMapping='ParentId'
        allowSorting={true}
        frozenColumns={1}
        data-testid={testId}
        hasChildMapping='HasMember'
        idMapping='ID'
        width={'100%'}
        height={'100%'}
        childMapping={!!data ? 'Items' : undefined}
        actionBegin={(args: PageEventArgs) => {
          console.log(args.requestType);
        }}
        //need the id to maintain persisted data
        id={`ItemGridl10`}
        allowPaging={true}
        pageSettings={pageSettings}
        pagerTemplate={(pagerData: PagerData) => {
          const pagerDataWithTotalPages = {
            ...pagerData,
            totalPages: Math.ceil(
              pagerData.totalRecordsCount / pagerData.pageSize
            ),
          };
          return (
            <PagerTemplate
              key={key}
              pagerData={pagerDataWithTotalPages}
              grid={gridRef?.current}
              pageSizes={pageSizes}
            />
          );
        }}
      >
        <Inject services={[Resize, Sort, Freeze, Page, ColumnMenu]} />
        <ColumnsDirective>
          <ColumnDirective
            field='DisplayName'
            key='DisplayName'
            headerText='Item'
            showCheckbox={false}
            allowResizing={true}
            template={(item: Item) => (
              <ItemSummaryTemplate
                title={item.DisplayName.substring(0, 20)}
                imageId={!!item._ImageId ? item._ImageId : undefined}
                tags={[
                  item.BusinessType?.Caption.toUpperCase() ?? '',
                  item.TypeDef?.Caption.toUpperCase() ?? '',
                ].filter((x) => x !== '')}
                onClick={() => onOpen!(!!item ? item.ID : undefined)}
              />
            )}
            minWidth={'18rem'}
          />
          <ColumnDirective
            field='StorageObject.FileExtension'
            key='StorageObject.FileExtension'
            headerText='Extension'
          />
          <ColumnDirective
            field='CreatedBy.DisplayName'
            key='CreatedBy.DisplayName'
            headerText='Created By'
          />
          <ColumnDirective
            field='DateCreated'
            key='DateCreated'
            headerText='Date Created'
            type='date'
            format={dateFormat}
          />
          <ColumnDirective
            field='DateModified'
            key='DateModified'
            headerText='Date Modified'
            type='date'
            format={dateFormat}
          />
          {/* TODO: Await API backend */}
          {/* {adminMode && (
            <ColumnDirective
              field='DisposalException'
              key='DisposalException'
              headerText='Disposal Exception'
              template={(item: Item) => (
                <DisposalExceptionTemplate
                  tags={item.DisposalStates?.value ?? []}
                />
              )}
            />
          )} */}
          <ColumnDirective
            field='DisposalStates'
            key='DisposalStates'
            headerText='Disposal Status'
            template={(item: Item) => (
              <DisposalStatusTemplate tags={item.DisposalStates?.value ?? []} />
            )}
          />
          <ColumnDirective
            field='RetentionClasses'
            key='RetentionClasses'
            headerText='Retention Classes'
            template={(item: Item) => (
              <RetentionClassTemplate
                title={item.DisplayName}
                tags={item.Classifications?.value ?? []}
              />
            )}
          />
          <ColumnDirective
            field='BusinessType'
            key='BusinessType'
            headerText='Business Type'
            template={(item: Item) => {
              return (
                (item.BusinessType?.Caption ?? '') !== '' && (
                  <BusinessTypeTemplate
                    title={item.BusinessType?.Caption ?? ''}
                    imageId={
                      !!item.BusinessType
                        ? item.BusinessType._ImageId
                        : undefined
                    }
                    tags={[
                      item.BusinessType?.Caption.toUpperCase() ?? '',
                      item.TypeDef?.Caption.toUpperCase() ?? '',
                    ].filter((x) => x !== '')}
                  />
                )
              );
            }}
          />
          <ColumnDirective
            field='Status'
            key='Status'
            headerText='Status'
            template={(item: Item) => {
              return <ManagementStatusTemplate title={item.Status} />;
            }}
          />
          <ColumnDirective
            field='RepositoryURL'
            key='RepositoryURL'
            headerText='Repository URL'
            template={(item: Item) => (
              <CommentTemplate
                comment={item.RepositoryUrl}
                isLink={true}
                onClick={() => onOpen}
                //open context menu - future use
              />
            )}
          />
        </ColumnsDirective>
      </TreeGridComponent>
      <InfoDialog
        title='Comment'
        open={!!comment}
        onClose={() => setComment(null)}
        information={comment as string}
      />
    </Box>
  );
};
export default ItemsGrid;
