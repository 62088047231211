import { DisposalRequestResponseStatus, ApproverResponse } from '../types';
import { Box } from './Box';
import { Typography, useTheme } from '@mui/material';
import { FormatRequestApprover } from './FormatRequestApprover';

export type DisposalRequestApproversProps = {
  data: ApproverResponse[] | undefined;
};

export const DisposalRequestApprovers = ({
  data,
}: DisposalRequestApproversProps) => {
  const theme = useTheme();

  return (
    <Box
      background='none'
      style={{
        overflow: 'visible',
      }}
      gap='small'
    >
      {data
        ?.slice()
        .sort((a: ApproverResponse, b: ApproverResponse) => {
          return a.Order - b.Order;
        })
        .map((approval: ApproverResponse, index: number) => {
          let pendingRequest =
            approval.Status === DisposalRequestResponseStatus.Pending;

          return (
            <Box
              background='none'
              direction='row'
              // justifyContent='space-between'
              className='dr-details-item'
              width='28.8rem'
              key={approval.ID}
            >
              <Typography
                variant='body2'
                style={{
                  width: '2.5rem',
                  fontWeight: pendingRequest ? '800' : '400',
                  color: pendingRequest
                    ? theme.palette.info.dark
                    : theme.palette.info.main,
                }}
              >
                {(index + 1).toString().padStart(2, '0')}
              </Typography>
              <Typography
                variant='body2'
                style={{
                  width: '13.563rem',
                  fontWeight: pendingRequest ? '800' : '400',
                  color: pendingRequest
                    ? theme.palette.info.dark
                    : theme.palette.info.main,
                }}
              >
                {approval.Approver?.DisplayName}
              </Typography>
              <FormatRequestApprover status={approval.Status} />
            </Box>
          );
        })}
    </Box>
  );
};
