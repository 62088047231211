import { SVGProps } from 'react';

const RetentionClass24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M22.5514 6.98C22.256 6.67991 21.8444 6.50662 21.4113 6.5H2.57835C2.13841 6.50351 1.71903 6.67686 1.41712 6.98C1.11658 7.28815 0.967103 7.70204 1.00541 8.12L1.96606 20C1.96606 20.8284 2.67502 21.5 3.54955 21.5H20.4401C21.3007 21.5056 22.0063 20.855 22.0236 20.04L22.9948 8.12C23.029 7.70073 22.8758 7.28713 22.5725 6.98H22.5514ZM20.9468 19.98C20.9468 20.2561 20.7105 20.48 20.419 20.48H3.52844C3.38183 20.4775 3.24263 20.4185 3.14301 20.3166C3.04339 20.2146 2.99196 20.0786 3.00061 19.94L2.05052 8.04C2.03871 7.90061 2.08906 7.7629 2.18935 7.66029C2.28965 7.55768 2.43072 7.49955 2.57835 7.5L21.4113 7.5C21.5589 7.49955 21.7 7.55769 21.8003 7.66029C21.9006 7.7629 21.951 7.90061 21.9391 8.04L20.9468 19.98ZM16.2175 11.5C15.926 11.5 15.6896 11.7239 15.6896 12V13C15.6896 13.2761 15.4533 13.5 15.1618 13.5H8.82785C8.53634 13.5 8.30002 13.2761 8.30002 13V12C8.30002 11.7239 8.06371 11.5 7.77219 11.5C7.48068 11.5 7.24436 11.7239 7.24436 12V13C7.24436 13.8284 7.95332 14.5 8.82785 14.5H15.1618C16.0364 14.5 16.7453 13.8284 16.7453 13V12C16.7453 11.7239 16.509 11.5 16.2175 11.5Z'
      fill='#0A0B33'
      stroke='#0A0B33'
      strokeWidth={0.5}
    />
    <line
      x1={4.5}
      y1={3}
      x2={19.5}
      y2={3}
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
    />
  </svg>
);

export { RetentionClass24 };
