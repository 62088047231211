import { InfoPanel } from './InfoPanel';
import { DetailsRow } from './DetailsRow';
import { UserChip } from './UserChip';
import { RequestStatusChip } from './RequestStatusChip';
import { DisposalRequestApprovers } from './DisposalRequestApprovers';
import { DisposalRequestResponses } from './DisposalRequestResponses';
import {
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
  User,
} from '../types';
import { Box } from './Box';
import { Divider, useTheme } from '@mui/material';
import { formatDate } from '../util/dateTime';
import { Add12Regular } from '@fluentui/react-icons';
import { DisposalRequest } from '../types';
import { CommentText } from './CommentText';
import { InfoDialog } from './dialog/InfoDialog';
import { useState } from 'react';
import { Button } from './Button';
import { RetentionClassChip } from './RetentionClassChip';
import { DisposalRequestProgressIndicator } from './DisposalRequestProgressIndicator';

export type DisposalRequestDetailsPanelProps = {
  request: DisposalRequest;
  adminMode?: boolean;
};

export const DisposalRequestDetailsPanel = ({
  request,
  adminMode = false,
}: DisposalRequestDetailsPanelProps) => {
  const theme = useTheme();

  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [commentDialogText, setCommentDialogText] = useState('');

  const commentBoxOpenHandler = (text: string) => {
    setCommentDialogOpen(true);
    setCommentDialogText(text);
  };

  const infoDialogCloseHandler = () => {
    setCommentDialogOpen(false);
    setCommentDialogText('');
  };

  return (
    <Box background='none' style={{ overflow: 'auto' }}>
      <InfoDialog
        open={commentDialogOpen}
        title='Comment'
        information={commentDialogText}
        onClose={infoDialogCloseHandler}
        size='large'
      />
      {adminMode && (
        <>
          <InfoPanel title='' style={{ padding: '2.5rem 0rem 2.5rem 7rem' }}>
            <DisposalRequestProgressIndicator
              requestStatus={request.Status as DisposalRequestStatus}
            />
          </InfoPanel>
          <Divider
            style={{
              margin: '0 0 1rem 7rem',
              width: '43.625rem',
            }}
          />
        </>
      )}
      <InfoPanel title='Request details' inset={'7rem'}>
        <Box
          background='none'
          style={{
            overflow: 'visible',
          }}
          width='46rem'
        >
          <DetailsRow
            label='Name'
            data={request.Name}
            onEdit={adminMode ? () => console.log('edit name') : null}
          />

          <DetailsRow label='Disposal Action' data={request.DisposalAction} />

          <DetailsRow
            label='Requested By'
            data={
              <UserChip
                border={true}
                user={{ name: request.RequestedBy?.DisplayName } as User}
              />
            }
          />

          <DetailsRow
            label='Status'
            data={
              <RequestStatusChip
                title={
                  DisposalRequestStatusCaption[
                    request.Status as keyof typeof DisposalRequestStatusCaption
                  ]
                }
                border={false}
                variant={request.Status}
              />
            }
          />

          <DetailsRow
            label='Approvers'
            data={
              (request?.ApproverResponses?.value?.length ?? 0) > 0 ? (
                <DisposalRequestApprovers
                  data={request?.ApproverResponses?.value}
                />
              ) : (
                adminMode && (
                  <Button
                    variant='text'
                    size='small'
                    sx={{
                      fontWeight: '400',
                      fontFamily: 'Avenir Book',
                      marginTop: '-0.625rem',
                    }}
                    startIcon={
                      <Add12Regular color={theme.palette.primary.main} />
                    }
                  >
                    Add approvers
                  </Button>
                )
              )
            }
            onEdit={
              request?.ApproverResponses?.value && adminMode
                ? () => console.log('edit approvers')
                : null
            }
          />

          <DetailsRow
            label='Date Requested'
            data={formatDate(request?.DateRequested!)}
          />

          <DetailsRow
            label='Date Created'
            data={formatDate(request?.DateCreatedInEnc!)}
          />

          <DetailsRow
            label='Retention Class'
            data={
              <RetentionClassChip classification={request.RetentionClass} />
            }
          />

          {request?.RequestComments ? (
            <DetailsRow
              label='Comment'
              data={
                <CommentText
                  text={request?.RequestComments}
                  onClick={() =>
                    commentBoxOpenHandler(request?.RequestComments)
                  }
                />
              }
              onEdit={!!adminMode ? () => console.log('edit comment') : null}
            />
          ) : adminMode ? (
            <DetailsRow
              label='Comment'
              data={
                <Button
                  variant='text'
                  sx={{
                    fontWeight: '400',
                    fontFamily: 'Avenir Book',
                  }}
                  startIcon={
                    <Add12Regular color={theme.palette.primary.main} />
                  }
                >
                  Add comment
                </Button>
              }
            />
          ) : (
            <></>
          )}

          <Divider style={{ margin: '1.25rem 0 1.25rem 0' }} />
        </Box>
      </InfoPanel>
      {request.ApproverResponses?.value && (
        <InfoPanel title='Responses' inset={'7rem'}>
          <DisposalRequestResponses data={request.ApproverResponses?.value} />
        </InfoPanel>
      )}
    </Box>
  );
};
