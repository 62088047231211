import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Box } from './Box';

export type InfoPanelProps = {
  title?: string;
  children?: ReactNode;
  inset?: string | number;
  style?: React.CSSProperties;
};

export const InfoPanel = ({
  title,
  children,
  inset = 0,
  style,
  ...rest
}: InfoPanelProps) => {
  return (
    <Box
      padding='large'
      background='none'
      style={{
        paddingLeft: inset,
        overflow: 'visible',
        ...style,
      }}
      {...rest}
    >
      <Typography
        variant='h2'
        sx={{
          margin: '2rem 0 2rem 0',
        }}
      >
        {title}
      </Typography>
      <Box
        background='none'
        style={{
          overflow: 'visible',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
