import { User } from '../types';
import { useMsalUser } from '../auth';
import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery } from '../services';

export function useUser() {
  const msalUser = useMsalUser();
  const [user, setUser] = useState<User>();

  const { data: apiUser } = useGetCurrentUserQuery(undefined, {
    skip: !msalUser?.localId,
  });

  useEffect(() => {
    setUser({ ...msalUser, ...apiUser });
  }, [msalUser, apiUser]);

  return user;
}
