import { Typography } from '@mui/material';
import { EncompaasLogo } from '../images';
import { Banner } from './Banner';
import { Box } from './Box';

export interface AppLogoProps {
  title?: string;
  colour?: 'none' | 'default' | 'light' | 'dark' | 'alt' | 'alt-dark';
  className?: string;
}

export const AppLogo = ({
  colour = 'none',
  title,
  className,
}: AppLogoProps) => {
  let _className = `ecs-banner ecs-fill-${colour} `;

  //can apply custom className
  _className = _className + (className ?? '');

  return (
    <>
      <Box direction='row' height={3.75} background={colour}>
        <EncompaasLogo height={'3.75rem'} />
        <Box
          gap='xsmall'
          justifyContent='center'
          alignItems='start'
          background={colour}
          style={title ? { paddingTop: '0.25rem' } : {}}
        >
          <Banner
            height={title ? '0.7rem' : '1.25rem'}
            colour='alt-dark'
            style={{ marginLeft: 1.5 }}
          />
          {title && (
            <Typography variant='h2' fontSize='1.25rem'>
              {title}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
