import React, { useState } from 'react';
import { CardMedia } from '@mui/material';
import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';

const ImageWithFallback: React.FunctionComponent<{
  src: string;
  alt?: string;
  className?: string;
}> = ({ src, className }) => {
  const [isUndefined, updateIsUndefined] = useState(false);

  const onError = () => {
    updateIsUndefined(true);
  };

  if (isUndefined) {
    return (
      <div style={{ height: '1.5rem', width: '1.5rem' }}>
        <DocumentHeaderFooter24Filled />
      </div>
    );
  }

  return (
    <img
      style={{ height: '1.5rem', width: '1.5rem' }}
      src={src}
      onError={onError}
    />
  );
};

export default ImageWithFallback;
