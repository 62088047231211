import { Edit20Regular } from '@fluentui/react-icons';
import { Box } from './Box';
import { Typography, useTheme } from '@mui/material';
import { EditButton } from './Button';

export const DetailsRow = ({
  label,
  data,
  width = 'inherit',
  size = 'large',
  onEdit,
}: {
  label: string;
  data: string | null | undefined | React.ReactNode;
  Component?: any;
  width?: string;
  size?: 'small' | 'large';
  onEdit?: () => void;
}) => {
  const theme = useTheme();

  const labelWidth = size === 'small' ? '8rem' : '14rem';
  const contentWidth = size === 'small' ? '25rem' : '29rem';

  return (
    <Box
      background='none'
      width={width}
      direction='row'
      justifyContent='center'
      style={{
        overflow: 'visible',
        margin: '1.25rem 0',
      }}
    >
      <Typography variant='h5' sx={{ width: labelWidth }}>
        {label}
      </Typography>
      {typeof data === 'string' ? (
        <Typography
          variant='body2'
          sx={{ width: contentWidth, textAlign: 'left' }}
        >
          {data}
        </Typography>
      ) : (
        <Box
          background='none'
          style={{ width: '100%', maxWidth: contentWidth, display: 'block' }}
        >
          {data}
        </Box>
      )}

      <Box background='none'>{!!onEdit && <EditButton onClick={onEdit} />}</Box>
    </Box>
  );
};
