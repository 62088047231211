import { Typography, useTheme } from '@mui/material';

export type CommentTextProps = {
  text: string;
  title?: string;
  variant?: string | any; // check type
  numberOfLines?: number;
  onClick?: () => void;
};
export const CommentText = ({
  text,
  title = 'Comment',
  variant = 'body2',
  numberOfLines = 15,
  onClick,
}: CommentTextProps) => {
  const theme = useTheme();
  return (
    <Typography
      variant={variant}
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: numberOfLines,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        },
      }}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};
