import { RetentionClass24 } from '../icons/RetentionClass24';
import { ItemClassificationsValue } from '../types';
import { RequestStatusChip } from './RequestStatusChip';

export type RetentionClassProps = {
  classification: ItemClassificationsValue;
};

export const RetentionClassChip = ({ classification }: RetentionClassProps) => {
  return (
    <RequestStatusChip
      title={classification?.DisplayName}
      icon={
        <RetentionClass24
          color={classification?.RootParentColor ?? undefined}
          style={{ alignSelf: 'flex-start' }}
        />
      }
      subTitle={classification?.FullPath}
      style={{ textAlign: 'left', padding: '0.5rem 0.75rem' }}
      border={true}
    />
  );
};
