import {
  Box,
  DisposalRequestApprovalDialog,
  DisposalRequestDetailsPanel,
  DisposalRequestHeaderActionButtons,
  DisposalRequestSuccessAlert,
  DisposalRequestWarningAlert,
  HomeButton,
  ItemsGrid,
  TabPanel,
} from '@encompaas/common/components';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useDisposalRequest, useUser } from '@encompaas/common/hooks';
import { DisposalRequest64 } from '@encompaas/common/icons';
import { useApproveDisposalRequestMutation } from '@encompaas/common/services';
import { DisposalRequestResponseStatus, User } from '@encompaas/common/types';
import { Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';

// try to find a better way for this
const isUserInPending = (user: User, data: any) => {
  const found = data?.filter((approval: any) => {
    return (
      approval.Status === DisposalRequestResponseStatus.Pending &&
      approval.Approver?.Mail?.toLowerCase() === user.username?.toLowerCase()
    );
  });

  return found?.length! > 0 ?? false;
};

const DisposalRequestPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const user = useUser();

  const [queryParams, setQueryParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState('details');

  const [approvalDialogOpen, setApprovalDialogOpen] = useState<boolean>(false);

  const [approvalErrorMessage, setApprovalErrorMessage] = useState('');

  const [successAlertOpen, setSuccessAlertOpen] = useState<boolean>(false);
  const [warningAlertOpen, setWarningAlertOpen] = useState<boolean>(false);

  const [approveDisposalRequest, { error }] =
    useApproveDisposalRequestMutation();

  useEffect(() => {
    const _selectedTab = queryParams.get('tab') ?? 'details';
    if (_selectedTab !== selectedTab) {
      setSelectedTab(_selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const { data, isSuccess } = useDisposalRequest({ id });

  const handleSuccessAlertClose = () => {
    setSuccessAlertOpen(false);
  };
  const handleWarningAlertClose = () => {
    setWarningAlertOpen(false);
  };

  const handleHome = () => {
    //if history param is set then we can go back, otherwise go home
    !!queryParams.get('history') ? navigate(-1) : navigate('/');
  };

  const handleSelectTab = (tab: string) => {
    //this will reset the history param so that the home button will go home rather than back
    setQueryParams({ tab });
  };

  const handleApproveAction = async (comment: string) => {
    try {
      const response = await approveDisposalRequest({
        comment: comment,
        requestId: id,
      });

      if ('error' in response) {
        const _response = response as {
          error: {
            data: {
              FriendlyMessage: string;
            };
          };
        };
        setApprovalErrorMessage(_response.error.data.FriendlyMessage);
        setWarningAlertOpen(true);
      } else {
        setSuccessAlertOpen(true);
      }
    } catch (err) {
      setWarningAlertOpen(true);
    } finally {
      setApprovalDialogOpen(false);
    }
  };

  const handleSelectItem = (target?: string) =>
    navigate(`/item/${target}?requestId=${id}`);

  const Grid = useMemo(
    () => <ItemsGrid onOpen={handleSelectItem} disposalId={id!} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.REQUEST_PAGE}>
      <DisposalRequestWarningAlert
        onClose={handleWarningAlertClose}
        open={warningAlertOpen}
        message={approvalErrorMessage}
      />
      <DisposalRequestSuccessAlert
        onClose={handleSuccessAlertClose}
        open={successAlertOpen}
      />

      <Box background='light' rounded='top' blur>
        <Box background='none' height={3.25} justifyContent='center'>
          <HomeButton
            label='Home'
            onClick={() => handleHome()}
            style={{ marginLeft: '1.5rem', alignSelf: 'start' }}
            data-testid={TEST_CONSTANTS.HOME_BUTTON}
          />
        </Box>
        <Divider style={{ width: '100%' }} />
        <Box
          background='none'
          padding='large'
          direction='row'
          gap='large'
          height={4}
        >
          <Box shrink>
            <DisposalRequest64 />
          </Box>
          <Box background='none' justifyContent='center' gap='xsmall'>
            <Typography variant='h1'>{data?.Name ?? 'Request'}</Typography>
            <Typography variant='h4'>Disposal request</Typography>
          </Box>

          <DisposalRequestHeaderActionButtons
            status={
              !!user && isUserInPending(user, data?.ApproverResponses?.value)
                ? DisposalRequestResponseStatus.Pending
                : undefined
            }
            onApprove={() => {
              setApprovalDialogOpen(true);
            }}
            onReject={() => {
              console.log('onReject called');
            }}
            onDelegate={() => {
              console.log('onDelegate called');
            }}
          />
        </Box>

        <DisposalRequestApprovalDialog
          title='Approve Disposal Request?'
          disposalRequest={data}
          open={approvalDialogOpen}
          user={user}
          onClose={() => {
            setApprovalDialogOpen(false);
          }}
          onAction={handleApproveAction}
        />
        <TabPanel
          selected={selectedTab}
          onSelect={handleSelectTab}
          inset={'7rem'}
          tabs={[
            {
              title: 'Details',
              value: 'details',
              children: isSuccess && !!data && (
                <DisposalRequestDetailsPanel request={data} />
              ),
            },
            {
              title: 'Items',
              value: 'items',
              children: (
                <Box padding='large' background='none'>
                  {Grid}
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </PageWithAppBar>
  );
};

export default DisposalRequestPage;
