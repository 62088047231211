import { Divider } from '@mui/material';
import { Item } from '../../types';
import { InfoPanel } from '../InfoPanel';
import {
  ItemTypeAudioPanel,
  ItemTypeItemPanel,
  ItemTypeConversationPanel,
  ItemTypeEmailPanel,
  ItemTypeEventPanel,
  ItemTypeImagePanel,
  ItemTypeItemCollectionPanel,
  ItemTypeTaskPanel,
  ItemTypeVideoPanel,
} from './type';

export enum ITEM_TYPE {
  AUDIO = 'Audio',
  CONVERSATION = 'Conversation',
  DOCUMENT = 'Document',
  EMAIL = 'Email',
  EVENT = 'Event',
  IMAGE = 'Image',
  ITEM_COLLECTION = 'ItemCollection',
  ITEM = 'Item',
  TASK = 'Task',
  VIDEO = 'Video',
}

export type ItemTypeGroupProps = {
  item: Item;
};

export const ItemTypePanel = ({ item }: ItemTypeGroupProps) => {
  const itemType = item?.TypeDef?.Name;
  const itemTypeDisplayName = item?.TypeDef?._Display;

  const renderComponent = () => {
    switch (itemType) {
      case ITEM_TYPE.AUDIO:
        return <ItemTypeAudioPanel item={item} />;
      case ITEM_TYPE.CONVERSATION:
        return <ItemTypeConversationPanel item={item} />;
      case ITEM_TYPE.DOCUMENT:
      case ITEM_TYPE.ITEM:
        return <ItemTypeItemPanel item={item} />;
      case ITEM_TYPE.EMAIL:
        return <ItemTypeEmailPanel item={item} />;
      case ITEM_TYPE.EVENT:
        return <ItemTypeEventPanel item={item} />;
      case ITEM_TYPE.IMAGE:
        return <ItemTypeImagePanel item={item} />;
      case ITEM_TYPE.ITEM_COLLECTION:
        return <ItemTypeItemCollectionPanel item={item} />;
      case ITEM_TYPE.TASK:
        return <ItemTypeTaskPanel item={item} />;
      case ITEM_TYPE.VIDEO:
        return <ItemTypeVideoPanel item={item} />;
      default:
        return null;
    }
  };

  return (
    <>
      {itemType ? (
        <>
          <InfoPanel
            title={`${itemTypeDisplayName} Properties`}
            inset='3.5rem'
            style={{
              paddingRight: '5.25rem',
              paddingTop: '0.988rem',
              paddingBottom: '1.031rem',
            }}
          >
            {renderComponent()}
          </InfoPanel>
          <Divider
            style={{
              width: '88%',
              borderColor: '#DCE1EF',
              margin: '0 auto',
            }}
          />
        </>
      ) : null}
    </>
  );
};
