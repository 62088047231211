import { Item } from '../types';
import {
  useGetItemDetailsFullDetailsQuery,
  useGetItemDetailsMemberOfQuery,
} from '../services';

export const useItemDetails = ({ id }: { id: string }) => {
  const { data: itemDetailsWithFullDetails, isLoading: isFetchingFullDetails } =
    useGetItemDetailsFullDetailsQuery(
      {
        itemId: id,
      },
      { skip: !id }
    );

  const { data: itemDetailsWithMemberOf, isLoading: isFetchingMemberOf } =
    useGetItemDetailsMemberOfQuery(
      {
        itemId: id,
      },
      { skip: !id }
    );

  const itemDetails = {
    ...itemDetailsWithFullDetails,
    ...itemDetailsWithMemberOf,
  } as Item;

  return {
    itemDetails,
    isFetching: isFetchingFullDetails || isFetchingMemberOf,
  };
};
